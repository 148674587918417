var Tt = Object.defineProperty;
var ye = (s) => {
  throw TypeError(s);
};
var At = (s, e, t) => e in s ? Tt(s, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : s[e] = t;
var b = (s, e, t) => At(s, typeof e != "symbol" ? e + "" : e, t), Ct = (s, e, t) => e.has(s) || ye("Cannot " + t);
var we = (s, e, t) => e.has(s) ? ye("Cannot add the same private member more than once") : e instanceof WeakSet ? e.add(s) : e.set(s, t);
var $ = (s, e, t) => (Ct(s, e, "access private method"), t);
var Oe = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function ue(s) {
  return s && s.__esModule && Object.prototype.hasOwnProperty.call(s, "default") ? s.default : s;
}
var Ie = { exports: {} };
(function(s) {
  (function() {
    var e = this;
    (function() {
      (function() {
        this.Rails = {
          linkClickSelector: "a[data-confirm], a[data-method], a[data-remote]:not([disabled]), a[data-disable-with], a[data-disable]",
          buttonClickSelector: {
            selector: "button[data-remote]:not([form]), button[data-confirm]:not([form])",
            exclude: "form button"
          },
          inputChangeSelector: "select[data-remote], input[data-remote], textarea[data-remote]",
          formSubmitSelector: "form",
          formInputClickSelector: "form input[type=submit], form input[type=image], form button[type=submit], form button:not([type]), input[type=submit][form], input[type=image][form], button[type=submit][form], button[form]:not([type])",
          formDisableSelector: "input[data-disable-with]:enabled, button[data-disable-with]:enabled, textarea[data-disable-with]:enabled, input[data-disable]:enabled, button[data-disable]:enabled, textarea[data-disable]:enabled",
          formEnableSelector: "input[data-disable-with]:disabled, button[data-disable-with]:disabled, textarea[data-disable-with]:disabled, input[data-disable]:disabled, button[data-disable]:disabled, textarea[data-disable]:disabled",
          fileInputSelector: "input[name][type=file]:not([disabled])",
          linkDisableSelector: "a[data-disable-with], a[data-disable]",
          buttonDisableSelector: "button[data-remote][data-disable-with], button[data-remote][data-disable]"
        };
      }).call(this);
    }).call(e);
    var t = e.Rails;
    (function() {
      (function() {
        var r, i;
        i = Element.prototype.matches || Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector, t.matches = function(n, o) {
          return o.exclude != null ? i.call(n, o.selector) && !i.call(n, o.exclude) : i.call(n, o);
        }, r = "_ujsData", t.getData = function(n, o) {
          var c;
          return (c = n[r]) != null ? c[o] : void 0;
        }, t.setData = function(n, o, c) {
          return n[r] == null && (n[r] = {}), n[r][o] = c;
        }, t.$ = function(n) {
          return Array.prototype.slice.call(document.querySelectorAll(n));
        };
      }).call(this), (function() {
        var r, i, n;
        r = t.$, n = t.csrfToken = function() {
          var o;
          return o = document.querySelector("meta[name=csrf-token]"), o && o.content;
        }, i = t.csrfParam = function() {
          var o;
          return o = document.querySelector("meta[name=csrf-param]"), o && o.content;
        }, t.CSRFProtection = function(o) {
          var c;
          if (c = n(), c != null)
            return o.setRequestHeader("X-CSRF-Token", c);
        }, t.refreshCSRFTokens = function() {
          var o, c;
          if (c = n(), o = i(), c != null && o != null)
            return r('form input[name="' + o + '"]').forEach(function(a) {
              return a.value = c;
            });
        };
      }).call(this), (function() {
        var r, i, n;
        n = t.matches, r = window.CustomEvent, typeof r != "function" && (r = function(o, c) {
          var a;
          return a = document.createEvent("CustomEvent"), a.initCustomEvent(o, c.bubbles, c.cancelable, c.detail), a;
        }, r.prototype = window.Event.prototype), i = t.fire = function(o, c, a) {
          var l;
          return l = new r(c, {
            bubbles: !0,
            cancelable: !0,
            detail: a
          }), o.dispatchEvent(l), !l.defaultPrevented;
        }, t.stopEverything = function(o) {
          return i(o.target, "ujs:everythingStopped"), o.preventDefault(), o.stopPropagation(), o.stopImmediatePropagation();
        }, t.delegate = function(o, c, a, l) {
          return o.addEventListener(a, function(h) {
            var u;
            for (u = h.target; !(!(u instanceof Element) || n(u, c)); )
              u = u.parentNode;
            if (u instanceof Element && l.call(u, h) === !1)
              return h.preventDefault(), h.stopPropagation();
          });
        };
      }).call(this), (function() {
        var r, i, n, o, c;
        i = t.CSRFProtection, t.fire, r = {
          "*": "*/*",
          text: "text/plain",
          html: "text/html",
          xml: "application/xml, text/xml",
          json: "application/json, text/javascript",
          script: "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript"
        }, t.ajax = function(a) {
          var l;
          if (a = o(a), l = n(a, function() {
            var h;
            return h = c(l.response, l.getResponseHeader("Content-Type")), Math.floor(l.status / 100) === 2 ? typeof a.success == "function" && a.success(h, l.statusText, l) : typeof a.error == "function" && a.error(h, l.statusText, l), typeof a.complete == "function" ? a.complete(l, l.statusText) : void 0;
          }), !(typeof a.beforeSend == "function" && a.beforeSend(l, a)))
            return !1;
          if (l.readyState === XMLHttpRequest.OPENED)
            return l.send(a.data);
        }, o = function(a) {
          return a.url = a.url || location.href, a.type = a.type.toUpperCase(), a.type === "GET" && a.data && (a.url.indexOf("?") < 0 ? a.url += "?" + a.data : a.url += "&" + a.data), r[a.dataType] == null && (a.dataType = "*"), a.accept = r[a.dataType], a.dataType !== "*" && (a.accept += ", */*; q=0.01"), a;
        }, n = function(a, l) {
          var h;
          return h = new XMLHttpRequest(), h.open(a.type, a.url, !0), h.setRequestHeader("Accept", a.accept), typeof a.data == "string" && h.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8"), a.crossDomain || h.setRequestHeader("X-Requested-With", "XMLHttpRequest"), i(h), h.withCredentials = !!a.withCredentials, h.onreadystatechange = function() {
            if (h.readyState === XMLHttpRequest.DONE)
              return l(h);
          }, h;
        }, c = function(a, l) {
          var h, u;
          if (typeof a == "string" && typeof l == "string") {
            if (l.match(/\bjson\b/))
              try {
                a = JSON.parse(a);
              } catch {
              }
            else if (l.match(/\b(?:java|ecma)script\b/))
              u = document.createElement("script"), u.text = a, document.head.appendChild(u).parentNode.removeChild(u);
            else if (l.match(/\b(xml|html|svg)\b/)) {
              h = new DOMParser(), l = l.replace(/;.+/, "");
              try {
                a = h.parseFromString(a, l);
              } catch {
              }
            }
          }
          return a;
        }, t.href = function(a) {
          return a.href;
        }, t.isCrossDomain = function(a) {
          var l, h;
          l = document.createElement("a"), l.href = location.href, h = document.createElement("a");
          try {
            return h.href = a, !((!h.protocol || h.protocol === ":") && !h.host || l.protocol + "//" + l.host == h.protocol + "//" + h.host);
          } catch {
            return !0;
          }
        };
      }).call(this), (function() {
        var r, i;
        r = t.matches, i = function(n) {
          return Array.prototype.slice.call(n);
        }, t.serializeElement = function(n, o) {
          var c, a;
          return c = [n], r(n, "form") && (c = i(n.elements)), a = [], c.forEach(function(l) {
            if (!(!l.name || l.disabled)) {
              if (r(l, "select"))
                return i(l.options).forEach(function(h) {
                  if (h.selected)
                    return a.push({
                      name: l.name,
                      value: h.value
                    });
                });
              if (l.checked || ["radio", "checkbox", "submit"].indexOf(l.type) === -1)
                return a.push({
                  name: l.name,
                  value: l.value
                });
            }
          }), o && a.push(o), a.map(function(l) {
            return l.name != null ? encodeURIComponent(l.name) + "=" + encodeURIComponent(l.value) : l;
          }).join("&");
        }, t.formElements = function(n, o) {
          return r(n, "form") ? i(n.elements).filter(function(c) {
            return r(c, o);
          }) : i(n.querySelectorAll(o));
        };
      }).call(this), (function() {
        var r, i, n;
        i = t.fire, n = t.stopEverything, t.handleConfirm = function(o) {
          if (!r(this))
            return n(o);
        }, r = function(o) {
          var c, a, l;
          if (l = o.getAttribute("data-confirm"), !l)
            return !0;
          if (c = !1, i(o, "confirm")) {
            try {
              c = confirm(l);
            } catch {
            }
            a = i(o, "confirm:complete", [c]);
          }
          return c && a;
        };
      }).call(this), (function() {
        var r, i, n, o, c, a, l, h, u, m, g;
        u = t.matches, h = t.getData, m = t.setData, g = t.stopEverything, l = t.formElements, t.handleDisabledElement = function(d) {
          var p;
          if (p = this, p.disabled)
            return g(d);
        }, t.enableElement = function(d) {
          var p;
          if (p = d instanceof Event ? d.target : d, u(p, t.linkDisableSelector))
            return a(p);
          if (u(p, t.buttonDisableSelector) || u(p, t.formEnableSelector))
            return o(p);
          if (u(p, t.formSubmitSelector))
            return c(p);
        }, t.disableElement = function(d) {
          var p;
          if (p = d instanceof Event ? d.target : d, u(p, t.linkDisableSelector))
            return n(p);
          if (u(p, t.buttonDisableSelector) || u(p, t.formDisableSelector))
            return r(p);
          if (u(p, t.formSubmitSelector))
            return i(p);
        }, n = function(d) {
          var p;
          return p = d.getAttribute("data-disable-with"), p != null && (m(d, "ujs:enable-with", d.innerHTML), d.innerHTML = p), d.addEventListener("click", g), m(d, "ujs:disabled", !0);
        }, a = function(d) {
          var p;
          return p = h(d, "ujs:enable-with"), p != null && (d.innerHTML = p, m(d, "ujs:enable-with", null)), d.removeEventListener("click", g), m(d, "ujs:disabled", null);
        }, i = function(d) {
          return l(d, t.formDisableSelector).forEach(r);
        }, r = function(d) {
          var p;
          return p = d.getAttribute("data-disable-with"), p != null && (u(d, "button") ? (m(d, "ujs:enable-with", d.innerHTML), d.innerHTML = p) : (m(d, "ujs:enable-with", d.value), d.value = p)), d.disabled = !0, m(d, "ujs:disabled", !0);
        }, c = function(d) {
          return l(d, t.formEnableSelector).forEach(o);
        }, o = function(d) {
          var p;
          return p = h(d, "ujs:enable-with"), p != null && (u(d, "button") ? d.innerHTML = p : d.value = p, m(d, "ujs:enable-with", null)), d.disabled = !1, m(d, "ujs:disabled", null);
        };
      }).call(this), (function() {
        var r;
        r = t.stopEverything, t.handleMethod = function(i) {
          var n, o, c, a, l, h, u;
          if (h = this, u = h.getAttribute("data-method"), !!u)
            return l = t.href(h), o = t.csrfToken(), n = t.csrfParam(), c = document.createElement("form"), a = "<input name='_method' value='" + u + "' type='hidden' />", n != null && o != null && !t.isCrossDomain(l) && (a += "<input name='" + n + "' value='" + o + "' type='hidden' />"), a += '<input type="submit" />', c.method = "post", c.action = l, c.target = h.target, c.innerHTML = a, c.style.display = "none", document.body.appendChild(c), c.querySelector('[type="submit"]').click(), r(i);
        };
      }).call(this), (function() {
        var r, i, n, o, c, a, l, h, u, m = [].slice;
        a = t.matches, n = t.getData, h = t.setData, i = t.fire, u = t.stopEverything, r = t.ajax, o = t.isCrossDomain, l = t.serializeElement, c = function(g) {
          var d;
          return d = g.getAttribute("data-remote"), d != null && d !== "false";
        }, t.handleRemote = function(g) {
          var d, p, P, f, V, k, re;
          return f = this, c(f) ? i(f, "ajax:before") ? (re = f.getAttribute("data-with-credentials"), P = f.getAttribute("data-type") || "script", a(f, t.formSubmitSelector) ? (d = n(f, "ujs:submit-button"), V = n(f, "ujs:submit-button-formmethod") || f.method, k = n(f, "ujs:submit-button-formaction") || f.getAttribute("action") || location.href, V.toUpperCase() === "GET" && (k = k.replace(/\?.*$/, "")), f.enctype === "multipart/form-data" ? (p = new FormData(f), d != null && p.append(d.name, d.value)) : p = l(f, d), h(f, "ujs:submit-button", null), h(f, "ujs:submit-button-formmethod", null), h(f, "ujs:submit-button-formaction", null)) : a(f, t.buttonClickSelector) || a(f, t.inputChangeSelector) ? (V = f.getAttribute("data-method"), k = f.getAttribute("data-url"), p = l(f, f.getAttribute("data-params"))) : (V = f.getAttribute("data-method"), k = t.href(f), p = f.getAttribute("data-params")), r({
            type: V || "GET",
            url: k,
            data: p,
            dataType: P,
            beforeSend: function(C, St) {
              return i(f, "ajax:beforeSend", [C, St]) ? i(f, "ajax:send", [C]) : (i(f, "ajax:stopped"), !1);
            },
            success: function() {
              var C;
              return C = 1 <= arguments.length ? m.call(arguments, 0) : [], i(f, "ajax:success", C);
            },
            error: function() {
              var C;
              return C = 1 <= arguments.length ? m.call(arguments, 0) : [], i(f, "ajax:error", C);
            },
            complete: function() {
              var C;
              return C = 1 <= arguments.length ? m.call(arguments, 0) : [], i(f, "ajax:complete", C);
            },
            crossDomain: o(k),
            withCredentials: re != null && re !== "false"
          }), u(g)) : (i(f, "ajax:stopped"), !1) : !0;
        }, t.formSubmitButtonClick = function(g) {
          var d, p;
          if (d = this, p = d.form, !!p)
            return d.name && h(p, "ujs:submit-button", {
              name: d.name,
              value: d.value
            }), h(p, "ujs:formnovalidate-button", d.formNoValidate), h(p, "ujs:submit-button-formaction", d.getAttribute("formaction")), h(p, "ujs:submit-button-formmethod", d.getAttribute("formmethod"));
        }, t.handleMetaClick = function(g) {
          var d, p, P, f;
          if (p = this, f = (p.getAttribute("data-method") || "GET").toUpperCase(), d = p.getAttribute("data-params"), P = g.metaKey || g.ctrlKey, P && f === "GET" && !d)
            return g.stopImmediatePropagation();
        };
      }).call(this), (function() {
        var r, i, n, o, c, a, l, h, u, m, g, d, p, P;
        a = t.fire, n = t.delegate, h = t.getData, r = t.$, P = t.refreshCSRFTokens, i = t.CSRFProtection, c = t.enableElement, o = t.disableElement, m = t.handleDisabledElement, u = t.handleConfirm, p = t.handleRemote, l = t.formSubmitButtonClick, g = t.handleMetaClick, d = t.handleMethod, typeof jQuery < "u" && jQuery !== null && jQuery.ajax != null && !jQuery.rails && (jQuery.rails = t, jQuery.ajaxPrefilter(function(f, V, k) {
          if (!f.crossDomain)
            return i(k);
        })), t.start = function() {
          if (window._rails_loaded)
            throw new Error("rails-ujs has already been loaded!");
          return window.addEventListener("pageshow", function() {
            return r(t.formEnableSelector).forEach(function(f) {
              if (h(f, "ujs:disabled"))
                return c(f);
            }), r(t.linkDisableSelector).forEach(function(f) {
              if (h(f, "ujs:disabled"))
                return c(f);
            });
          }), n(document, t.linkDisableSelector, "ajax:complete", c), n(document, t.linkDisableSelector, "ajax:stopped", c), n(document, t.buttonDisableSelector, "ajax:complete", c), n(document, t.buttonDisableSelector, "ajax:stopped", c), n(document, t.linkClickSelector, "click", m), n(document, t.linkClickSelector, "click", u), n(document, t.linkClickSelector, "click", g), n(document, t.linkClickSelector, "click", o), n(document, t.linkClickSelector, "click", p), n(document, t.linkClickSelector, "click", d), n(document, t.buttonClickSelector, "click", m), n(document, t.buttonClickSelector, "click", u), n(document, t.buttonClickSelector, "click", o), n(document, t.buttonClickSelector, "click", p), n(document, t.inputChangeSelector, "change", m), n(document, t.inputChangeSelector, "change", u), n(document, t.inputChangeSelector, "change", p), n(document, t.formSubmitSelector, "submit", m), n(document, t.formSubmitSelector, "submit", u), n(document, t.formSubmitSelector, "submit", p), n(document, t.formSubmitSelector, "submit", function(f) {
            return setTimeout(function() {
              return o(f);
            }, 13);
          }), n(document, t.formSubmitSelector, "ajax:send", o), n(document, t.formSubmitSelector, "ajax:complete", c), n(document, t.formInputClickSelector, "click", m), n(document, t.formInputClickSelector, "click", u), n(document, t.formInputClickSelector, "click", l), document.addEventListener("DOMContentLoaded", P), window._rails_loaded = !0;
        }, window.Rails === t && a(document, "rails:attachBindings") && t.start();
      }).call(this);
    }).call(this), s.exports && (s.exports = t);
  }).call(Oe);
})(Ie);
var Lt = Ie.exports;
const kt = /* @__PURE__ */ ue(Lt);
var xe = { exports: {} };
(function(s) {
  (function() {
    var e = this;
    (function() {
      (function() {
        this.Turbolinks = { supported: function() {
          return window.history.pushState != null && window.requestAnimationFrame != null && window.addEventListener != null;
        }(), visit: function(r, i) {
          return t.controller.visit(r, i);
        }, clearCache: function() {
          return t.controller.clearCache();
        }, setProgressBarDelay: function(r) {
          return t.controller.setProgressBarDelay(r);
        } };
      }).call(this);
    }).call(e);
    var t = e.Turbolinks;
    (function() {
      (function() {
        var r, i, n, o = [].slice;
        t.copyObject = function(c) {
          var a, l, h;
          l = {};
          for (a in c) h = c[a], l[a] = h;
          return l;
        }, t.closest = function(c, a) {
          return r.call(c, a);
        }, r = function() {
          var c, a;
          return c = document.documentElement, (a = c.closest) != null ? a : function(l) {
            var h;
            for (h = this; h; ) {
              if (h.nodeType === Node.ELEMENT_NODE && i.call(h, l)) return h;
              h = h.parentNode;
            }
          };
        }(), t.defer = function(c) {
          return setTimeout(c, 1);
        }, t.throttle = function(c) {
          var a;
          return a = null, function() {
            var l;
            return l = 1 <= arguments.length ? o.call(arguments, 0) : [], a ?? (a = requestAnimationFrame(/* @__PURE__ */ function(h) {
              return function() {
                return a = null, c.apply(h, l);
              };
            }(this)));
          };
        }, t.dispatch = function(c, a) {
          var l, h, u, m, g, d;
          return g = a ?? {}, d = g.target, l = g.cancelable, h = g.data, u = document.createEvent("Events"), u.initEvent(c, !0, l === !0), u.data = h ?? {}, u.cancelable && !n && (m = u.preventDefault, u.preventDefault = function() {
            return this.defaultPrevented || Object.defineProperty(this, "defaultPrevented", { get: function() {
              return !0;
            } }), m.call(this);
          }), (d ?? document).dispatchEvent(u), u;
        }, n = function() {
          var c;
          return c = document.createEvent("Events"), c.initEvent("test", !0, !0), c.preventDefault(), c.defaultPrevented;
        }(), t.match = function(c, a) {
          return i.call(c, a);
        }, i = function() {
          var c, a, l, h;
          return c = document.documentElement, (a = (l = (h = c.matchesSelector) != null ? h : c.webkitMatchesSelector) != null ? l : c.msMatchesSelector) != null ? a : c.mozMatchesSelector;
        }(), t.uuid = function() {
          var c, a, l;
          for (l = "", c = a = 1; 36 >= a; c = ++a) l += c === 9 || c === 14 || c === 19 || c === 24 ? "-" : c === 15 ? "4" : c === 20 ? (Math.floor(4 * Math.random()) + 8).toString(16) : Math.floor(15 * Math.random()).toString(16);
          return l;
        };
      }).call(this), (function() {
        t.Location = function() {
          function r(a) {
            var l, h;
            a == null && (a = ""), h = document.createElement("a"), h.href = a.toString(), this.absoluteURL = h.href, l = h.hash.length, 2 > l ? this.requestURL = this.absoluteURL : (this.requestURL = this.absoluteURL.slice(0, -l), this.anchor = h.hash.slice(1));
          }
          var i, n, o, c;
          return r.wrap = function(a) {
            return a instanceof this ? a : new this(a);
          }, r.prototype.getOrigin = function() {
            return this.absoluteURL.split("/", 3).join("/");
          }, r.prototype.getPath = function() {
            var a, l;
            return (a = (l = this.requestURL.match(/\/\/[^\/]*(\/[^?;]*)/)) != null ? l[1] : void 0) != null ? a : "/";
          }, r.prototype.getPathComponents = function() {
            return this.getPath().split("/").slice(1);
          }, r.prototype.getLastPathComponent = function() {
            return this.getPathComponents().slice(-1)[0];
          }, r.prototype.getExtension = function() {
            var a, l;
            return (a = (l = this.getLastPathComponent().match(/\.[^.]*$/)) != null ? l[0] : void 0) != null ? a : "";
          }, r.prototype.isHTML = function() {
            return this.getExtension().match(/^(?:|\.(?:htm|html|xhtml))$/);
          }, r.prototype.isPrefixedBy = function(a) {
            var l;
            return l = n(a), this.isEqualTo(a) || c(this.absoluteURL, l);
          }, r.prototype.isEqualTo = function(a) {
            return this.absoluteURL === (a != null ? a.absoluteURL : void 0);
          }, r.prototype.toCacheKey = function() {
            return this.requestURL;
          }, r.prototype.toJSON = function() {
            return this.absoluteURL;
          }, r.prototype.toString = function() {
            return this.absoluteURL;
          }, r.prototype.valueOf = function() {
            return this.absoluteURL;
          }, n = function(a) {
            return i(a.getOrigin() + a.getPath());
          }, i = function(a) {
            return o(a, "/") ? a : a + "/";
          }, c = function(a, l) {
            return a.slice(0, l.length) === l;
          }, o = function(a, l) {
            return a.slice(-l.length) === l;
          }, r;
        }();
      }).call(this), (function() {
        var r = function(i, n) {
          return function() {
            return i.apply(n, arguments);
          };
        };
        t.HttpRequest = function() {
          function i(n, o, c) {
            this.delegate = n, this.requestCanceled = r(this.requestCanceled, this), this.requestTimedOut = r(this.requestTimedOut, this), this.requestFailed = r(this.requestFailed, this), this.requestLoaded = r(this.requestLoaded, this), this.requestProgressed = r(this.requestProgressed, this), this.url = t.Location.wrap(o).requestURL, this.referrer = t.Location.wrap(c).absoluteURL, this.createXHR();
          }
          return i.NETWORK_FAILURE = 0, i.TIMEOUT_FAILURE = -1, i.timeout = 60, i.prototype.send = function() {
            var n;
            return this.xhr && !this.sent ? (this.notifyApplicationBeforeRequestStart(), this.setProgress(0), this.xhr.send(), this.sent = !0, typeof (n = this.delegate).requestStarted == "function" ? n.requestStarted() : void 0) : void 0;
          }, i.prototype.cancel = function() {
            return this.xhr && this.sent ? this.xhr.abort() : void 0;
          }, i.prototype.requestProgressed = function(n) {
            return n.lengthComputable ? this.setProgress(n.loaded / n.total) : void 0;
          }, i.prototype.requestLoaded = function() {
            return this.endRequest(/* @__PURE__ */ function(n) {
              return function() {
                var o;
                return 200 <= (o = n.xhr.status) && 300 > o ? n.delegate.requestCompletedWithResponse(n.xhr.responseText, n.xhr.getResponseHeader("Turbolinks-Location")) : (n.failed = !0, n.delegate.requestFailedWithStatusCode(n.xhr.status, n.xhr.responseText));
              };
            }(this));
          }, i.prototype.requestFailed = function() {
            return this.endRequest(/* @__PURE__ */ function(n) {
              return function() {
                return n.failed = !0, n.delegate.requestFailedWithStatusCode(n.constructor.NETWORK_FAILURE);
              };
            }(this));
          }, i.prototype.requestTimedOut = function() {
            return this.endRequest(/* @__PURE__ */ function(n) {
              return function() {
                return n.failed = !0, n.delegate.requestFailedWithStatusCode(n.constructor.TIMEOUT_FAILURE);
              };
            }(this));
          }, i.prototype.requestCanceled = function() {
            return this.endRequest();
          }, i.prototype.notifyApplicationBeforeRequestStart = function() {
            return t.dispatch("turbolinks:request-start", { data: { url: this.url, xhr: this.xhr } });
          }, i.prototype.notifyApplicationAfterRequestEnd = function() {
            return t.dispatch("turbolinks:request-end", { data: { url: this.url, xhr: this.xhr } });
          }, i.prototype.createXHR = function() {
            return this.xhr = new XMLHttpRequest(), this.xhr.open("GET", this.url, !0), this.xhr.timeout = 1e3 * this.constructor.timeout, this.xhr.setRequestHeader("Accept", "text/html, application/xhtml+xml"), this.xhr.setRequestHeader("Turbolinks-Referrer", this.referrer), this.xhr.onprogress = this.requestProgressed, this.xhr.onload = this.requestLoaded, this.xhr.onerror = this.requestFailed, this.xhr.ontimeout = this.requestTimedOut, this.xhr.onabort = this.requestCanceled;
          }, i.prototype.endRequest = function(n) {
            return this.xhr ? (this.notifyApplicationAfterRequestEnd(), n != null && n.call(this), this.destroy()) : void 0;
          }, i.prototype.setProgress = function(n) {
            var o;
            return this.progress = n, typeof (o = this.delegate).requestProgressed == "function" ? o.requestProgressed(this.progress) : void 0;
          }, i.prototype.destroy = function() {
            var n;
            return this.setProgress(1), typeof (n = this.delegate).requestFinished == "function" && n.requestFinished(), this.delegate = null, this.xhr = null;
          }, i;
        }();
      }).call(this), (function() {
        var r = function(i, n) {
          return function() {
            return i.apply(n, arguments);
          };
        };
        t.ProgressBar = function() {
          function i() {
            this.trickle = r(this.trickle, this), this.stylesheetElement = this.createStylesheetElement(), this.progressElement = this.createProgressElement();
          }
          var n;
          return n = 300, i.defaultCSS = `.turbolinks-progress-bar {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 3px;
  background: #0076ff;
  z-index: 9999;
  transition: width ` + n + "ms ease-out, opacity " + n / 2 + "ms " + n / 2 + `ms ease-in;
  transform: translate3d(0, 0, 0);
}`, i.prototype.show = function() {
            return this.visible ? void 0 : (this.visible = !0, this.installStylesheetElement(), this.installProgressElement(), this.startTrickling());
          }, i.prototype.hide = function() {
            return this.visible && !this.hiding ? (this.hiding = !0, this.fadeProgressElement(/* @__PURE__ */ function(o) {
              return function() {
                return o.uninstallProgressElement(), o.stopTrickling(), o.visible = !1, o.hiding = !1;
              };
            }(this))) : void 0;
          }, i.prototype.setValue = function(o) {
            return this.value = o, this.refresh();
          }, i.prototype.installStylesheetElement = function() {
            return document.head.insertBefore(this.stylesheetElement, document.head.firstChild);
          }, i.prototype.installProgressElement = function() {
            return this.progressElement.style.width = 0, this.progressElement.style.opacity = 1, document.documentElement.insertBefore(this.progressElement, document.body), this.refresh();
          }, i.prototype.fadeProgressElement = function(o) {
            return this.progressElement.style.opacity = 0, setTimeout(o, 1.5 * n);
          }, i.prototype.uninstallProgressElement = function() {
            return this.progressElement.parentNode ? document.documentElement.removeChild(this.progressElement) : void 0;
          }, i.prototype.startTrickling = function() {
            return this.trickleInterval != null ? this.trickleInterval : this.trickleInterval = setInterval(this.trickle, n);
          }, i.prototype.stopTrickling = function() {
            return clearInterval(this.trickleInterval), this.trickleInterval = null;
          }, i.prototype.trickle = function() {
            return this.setValue(this.value + Math.random() / 100);
          }, i.prototype.refresh = function() {
            return requestAnimationFrame(/* @__PURE__ */ function(o) {
              return function() {
                return o.progressElement.style.width = 10 + 90 * o.value + "%";
              };
            }(this));
          }, i.prototype.createStylesheetElement = function() {
            var o;
            return o = document.createElement("style"), o.type = "text/css", o.textContent = this.constructor.defaultCSS, o;
          }, i.prototype.createProgressElement = function() {
            var o;
            return o = document.createElement("div"), o.className = "turbolinks-progress-bar", o;
          }, i;
        }();
      }).call(this), (function() {
        var r = function(i, n) {
          return function() {
            return i.apply(n, arguments);
          };
        };
        t.BrowserAdapter = function() {
          function i(a) {
            this.controller = a, this.showProgressBar = r(this.showProgressBar, this), this.progressBar = new t.ProgressBar();
          }
          var n, o, c;
          return c = t.HttpRequest, n = c.NETWORK_FAILURE, o = c.TIMEOUT_FAILURE, i.prototype.visitProposedToLocationWithAction = function(a, l) {
            return this.controller.startVisitToLocationWithAction(a, l);
          }, i.prototype.visitStarted = function(a) {
            return a.issueRequest(), a.changeHistory(), a.loadCachedSnapshot();
          }, i.prototype.visitRequestStarted = function(a) {
            return this.progressBar.setValue(0), a.hasCachedSnapshot() || a.action !== "restore" ? this.showProgressBarAfterDelay() : this.showProgressBar();
          }, i.prototype.visitRequestProgressed = function(a) {
            return this.progressBar.setValue(a.progress);
          }, i.prototype.visitRequestCompleted = function(a) {
            return a.loadResponse();
          }, i.prototype.visitRequestFailedWithStatusCode = function(a, l) {
            switch (l) {
              case n:
              case o:
                return this.reload();
              default:
                return a.loadResponse();
            }
          }, i.prototype.visitRequestFinished = function(a) {
            return this.hideProgressBar();
          }, i.prototype.visitCompleted = function(a) {
            return a.followRedirect();
          }, i.prototype.pageInvalidated = function() {
            return this.reload();
          }, i.prototype.showProgressBarAfterDelay = function() {
            return this.progressBarTimeout = setTimeout(this.showProgressBar, this.controller.progressBarDelay);
          }, i.prototype.showProgressBar = function() {
            return this.progressBar.show();
          }, i.prototype.hideProgressBar = function() {
            return this.progressBar.hide(), clearTimeout(this.progressBarTimeout);
          }, i.prototype.reload = function() {
            return window.location.reload();
          }, i;
        }();
      }).call(this), (function() {
        var r = function(i, n) {
          return function() {
            return i.apply(n, arguments);
          };
        };
        t.History = function() {
          function i(n) {
            this.delegate = n, this.onPageLoad = r(this.onPageLoad, this), this.onPopState = r(this.onPopState, this);
          }
          return i.prototype.start = function() {
            return this.started ? void 0 : (addEventListener("popstate", this.onPopState, !1), addEventListener("load", this.onPageLoad, !1), this.started = !0);
          }, i.prototype.stop = function() {
            return this.started ? (removeEventListener("popstate", this.onPopState, !1), removeEventListener("load", this.onPageLoad, !1), this.started = !1) : void 0;
          }, i.prototype.push = function(n, o) {
            return n = t.Location.wrap(n), this.update("push", n, o);
          }, i.prototype.replace = function(n, o) {
            return n = t.Location.wrap(n), this.update("replace", n, o);
          }, i.prototype.onPopState = function(n) {
            var o, c, a, l;
            return this.shouldHandlePopState() && (l = (c = n.state) != null ? c.turbolinks : void 0) ? (o = t.Location.wrap(window.location), a = l.restorationIdentifier, this.delegate.historyPoppedToLocationWithRestorationIdentifier(o, a)) : void 0;
          }, i.prototype.onPageLoad = function(n) {
            return t.defer(/* @__PURE__ */ function(o) {
              return function() {
                return o.pageLoaded = !0;
              };
            }(this));
          }, i.prototype.shouldHandlePopState = function() {
            return this.pageIsLoaded();
          }, i.prototype.pageIsLoaded = function() {
            return this.pageLoaded || document.readyState === "complete";
          }, i.prototype.update = function(n, o, c) {
            var a;
            return a = { turbolinks: { restorationIdentifier: c } }, history[n + "State"](a, null, o);
          }, i;
        }();
      }).call(this), (function() {
        t.Snapshot = function() {
          function r(i) {
            var n, o;
            o = i.head, n = i.body, this.head = o ?? document.createElement("head"), this.body = n ?? document.createElement("body");
          }
          return r.wrap = function(i) {
            return i instanceof this ? i : this.fromHTML(i);
          }, r.fromHTML = function(i) {
            var n;
            return n = document.createElement("html"), n.innerHTML = i, this.fromElement(n);
          }, r.fromElement = function(i) {
            return new this({ head: i.querySelector("head"), body: i.querySelector("body") });
          }, r.prototype.clone = function() {
            return new r({ head: this.head.cloneNode(!0), body: this.body.cloneNode(!0) });
          }, r.prototype.getRootLocation = function() {
            var i, n;
            return n = (i = this.getSetting("root")) != null ? i : "/", new t.Location(n);
          }, r.prototype.getCacheControlValue = function() {
            return this.getSetting("cache-control");
          }, r.prototype.getElementForAnchor = function(i) {
            try {
              return this.body.querySelector("[id='" + i + "'], a[name='" + i + "']");
            } catch {
            }
          }, r.prototype.hasAnchor = function(i) {
            return this.getElementForAnchor(i) != null;
          }, r.prototype.isPreviewable = function() {
            return this.getCacheControlValue() !== "no-preview";
          }, r.prototype.isCacheable = function() {
            return this.getCacheControlValue() !== "no-cache";
          }, r.prototype.isVisitable = function() {
            return this.getSetting("visit-control") !== "reload";
          }, r.prototype.getSetting = function(i) {
            var n, o;
            return o = this.head.querySelectorAll("meta[name='turbolinks-" + i + "']"), n = o[o.length - 1], n != null ? n.getAttribute("content") : void 0;
          }, r;
        }();
      }).call(this), (function() {
        var r = [].slice;
        t.Renderer = function() {
          function i() {
          }
          var n;
          return i.render = function() {
            var o, c, a, l;
            return a = arguments[0], c = arguments[1], o = 3 <= arguments.length ? r.call(arguments, 2) : [], l = function(h, u, m) {
              m.prototype = h.prototype;
              var g = new m(), d = h.apply(g, u);
              return Object(d) === d ? d : g;
            }(this, o, function() {
            }), l.delegate = a, l.render(c), l;
          }, i.prototype.renderView = function(o) {
            return this.delegate.viewWillRender(this.newBody), o(), this.delegate.viewRendered(this.newBody);
          }, i.prototype.invalidateView = function() {
            return this.delegate.viewInvalidated();
          }, i.prototype.createScriptElement = function(o) {
            var c;
            return o.getAttribute("data-turbolinks-eval") === "false" ? o : (c = document.createElement("script"), c.textContent = o.textContent, c.async = !1, n(c, o), c);
          }, n = function(o, c) {
            var a, l, h, u, m, g, d;
            for (u = c.attributes, g = [], a = 0, l = u.length; l > a; a++) m = u[a], h = m.name, d = m.value, g.push(o.setAttribute(h, d));
            return g;
          }, i;
        }();
      }).call(this), (function() {
        t.HeadDetails = function() {
          function r(a) {
            var l, h, u, m, g, d, p;
            for (this.element = a, this.elements = {}, p = this.element.childNodes, m = 0, d = p.length; d > m; m++) u = p[m], u.nodeType === Node.ELEMENT_NODE && (g = u.outerHTML, h = (l = this.elements)[g] != null ? l[g] : l[g] = { type: c(u), tracked: o(u), elements: [] }, h.elements.push(u));
          }
          var i, n, o, c;
          return r.prototype.hasElementWithKey = function(a) {
            return a in this.elements;
          }, r.prototype.getTrackedElementSignature = function() {
            var a, l;
            return (function() {
              var h, u;
              h = this.elements, u = [];
              for (a in h) l = h[a].tracked, l && u.push(a);
              return u;
            }).call(this).join("");
          }, r.prototype.getScriptElementsNotInDetails = function(a) {
            return this.getElementsMatchingTypeNotInDetails("script", a);
          }, r.prototype.getStylesheetElementsNotInDetails = function(a) {
            return this.getElementsMatchingTypeNotInDetails("stylesheet", a);
          }, r.prototype.getElementsMatchingTypeNotInDetails = function(a, l) {
            var h, u, m, g, d, p;
            m = this.elements, d = [];
            for (u in m) g = m[u], p = g.type, h = g.elements, p !== a || l.hasElementWithKey(u) || d.push(h[0]);
            return d;
          }, r.prototype.getProvisionalElements = function() {
            var a, l, h, u, m, g, d;
            h = [], u = this.elements;
            for (l in u) m = u[l], d = m.type, g = m.tracked, a = m.elements, d != null || g ? a.length > 1 && h.push.apply(h, a.slice(1)) : h.push.apply(h, a);
            return h;
          }, c = function(a) {
            return i(a) ? "script" : n(a) ? "stylesheet" : void 0;
          }, o = function(a) {
            return a.getAttribute("data-turbolinks-track") === "reload";
          }, i = function(a) {
            var l;
            return l = a.tagName.toLowerCase(), l === "script";
          }, n = function(a) {
            var l;
            return l = a.tagName.toLowerCase(), l === "style" || l === "link" && a.getAttribute("rel") === "stylesheet";
          }, r;
        }();
      }).call(this), (function() {
        var r = function(n, o) {
          function c() {
            this.constructor = n;
          }
          for (var a in o) i.call(o, a) && (n[a] = o[a]);
          return c.prototype = o.prototype, n.prototype = new c(), n.__super__ = o.prototype, n;
        }, i = {}.hasOwnProperty;
        t.SnapshotRenderer = function(n) {
          function o(c, a, l) {
            this.currentSnapshot = c, this.newSnapshot = a, this.isPreview = l, this.currentHeadDetails = new t.HeadDetails(this.currentSnapshot.head), this.newHeadDetails = new t.HeadDetails(this.newSnapshot.head), this.newBody = this.newSnapshot.body;
          }
          return r(o, n), o.prototype.render = function(c) {
            return this.shouldRender() ? (this.mergeHead(), this.renderView(/* @__PURE__ */ function(a) {
              return function() {
                return a.replaceBody(), a.isPreview || a.focusFirstAutofocusableElement(), c();
              };
            }(this))) : this.invalidateView();
          }, o.prototype.mergeHead = function() {
            return this.copyNewHeadStylesheetElements(), this.copyNewHeadScriptElements(), this.removeCurrentHeadProvisionalElements(), this.copyNewHeadProvisionalElements();
          }, o.prototype.replaceBody = function() {
            return this.activateBodyScriptElements(), this.importBodyPermanentElements(), this.assignNewBody();
          }, o.prototype.shouldRender = function() {
            return this.newSnapshot.isVisitable() && this.trackedElementsAreIdentical();
          }, o.prototype.trackedElementsAreIdentical = function() {
            return this.currentHeadDetails.getTrackedElementSignature() === this.newHeadDetails.getTrackedElementSignature();
          }, o.prototype.copyNewHeadStylesheetElements = function() {
            var c, a, l, h, u;
            for (h = this.getNewHeadStylesheetElements(), u = [], a = 0, l = h.length; l > a; a++) c = h[a], u.push(document.head.appendChild(c));
            return u;
          }, o.prototype.copyNewHeadScriptElements = function() {
            var c, a, l, h, u;
            for (h = this.getNewHeadScriptElements(), u = [], a = 0, l = h.length; l > a; a++) c = h[a], u.push(document.head.appendChild(this.createScriptElement(c)));
            return u;
          }, o.prototype.removeCurrentHeadProvisionalElements = function() {
            var c, a, l, h, u;
            for (h = this.getCurrentHeadProvisionalElements(), u = [], a = 0, l = h.length; l > a; a++) c = h[a], u.push(document.head.removeChild(c));
            return u;
          }, o.prototype.copyNewHeadProvisionalElements = function() {
            var c, a, l, h, u;
            for (h = this.getNewHeadProvisionalElements(), u = [], a = 0, l = h.length; l > a; a++) c = h[a], u.push(document.head.appendChild(c));
            return u;
          }, o.prototype.importBodyPermanentElements = function() {
            var c, a, l, h, u, m;
            for (h = this.getNewBodyPermanentElements(), m = [], a = 0, l = h.length; l > a; a++) u = h[a], (c = this.findCurrentBodyPermanentElement(u)) ? m.push(u.parentNode.replaceChild(c, u)) : m.push(void 0);
            return m;
          }, o.prototype.activateBodyScriptElements = function() {
            var c, a, l, h, u, m;
            for (h = this.getNewBodyScriptElements(), m = [], a = 0, l = h.length; l > a; a++) u = h[a], c = this.createScriptElement(u), m.push(u.parentNode.replaceChild(c, u));
            return m;
          }, o.prototype.assignNewBody = function() {
            return document.body = this.newBody;
          }, o.prototype.focusFirstAutofocusableElement = function() {
            var c;
            return (c = this.findFirstAutofocusableElement()) != null ? c.focus() : void 0;
          }, o.prototype.getNewHeadStylesheetElements = function() {
            return this.newHeadDetails.getStylesheetElementsNotInDetails(this.currentHeadDetails);
          }, o.prototype.getNewHeadScriptElements = function() {
            return this.newHeadDetails.getScriptElementsNotInDetails(this.currentHeadDetails);
          }, o.prototype.getCurrentHeadProvisionalElements = function() {
            return this.currentHeadDetails.getProvisionalElements();
          }, o.prototype.getNewHeadProvisionalElements = function() {
            return this.newHeadDetails.getProvisionalElements();
          }, o.prototype.getNewBodyPermanentElements = function() {
            return this.newBody.querySelectorAll("[id][data-turbolinks-permanent]");
          }, o.prototype.findCurrentBodyPermanentElement = function(c) {
            return document.body.querySelector("#" + c.id + "[data-turbolinks-permanent]");
          }, o.prototype.getNewBodyScriptElements = function() {
            return this.newBody.querySelectorAll("script");
          }, o.prototype.findFirstAutofocusableElement = function() {
            return document.body.querySelector("[autofocus]");
          }, o;
        }(t.Renderer);
      }).call(this), (function() {
        var r = function(n, o) {
          function c() {
            this.constructor = n;
          }
          for (var a in o) i.call(o, a) && (n[a] = o[a]);
          return c.prototype = o.prototype, n.prototype = new c(), n.__super__ = o.prototype, n;
        }, i = {}.hasOwnProperty;
        t.ErrorRenderer = function(n) {
          function o(c) {
            this.html = c;
          }
          return r(o, n), o.prototype.render = function(c) {
            return this.renderView(/* @__PURE__ */ function(a) {
              return function() {
                return a.replaceDocumentHTML(), a.activateBodyScriptElements(), c();
              };
            }(this));
          }, o.prototype.replaceDocumentHTML = function() {
            return document.documentElement.innerHTML = this.html;
          }, o.prototype.activateBodyScriptElements = function() {
            var c, a, l, h, u, m;
            for (h = this.getScriptElements(), m = [], a = 0, l = h.length; l > a; a++) u = h[a], c = this.createScriptElement(u), m.push(u.parentNode.replaceChild(c, u));
            return m;
          }, o.prototype.getScriptElements = function() {
            return document.documentElement.querySelectorAll("script");
          }, o;
        }(t.Renderer);
      }).call(this), (function() {
        t.View = function() {
          function r(i) {
            this.delegate = i, this.element = document.documentElement;
          }
          return r.prototype.getRootLocation = function() {
            return this.getSnapshot().getRootLocation();
          }, r.prototype.getElementForAnchor = function(i) {
            return this.getSnapshot().getElementForAnchor(i);
          }, r.prototype.getSnapshot = function() {
            return t.Snapshot.fromElement(this.element);
          }, r.prototype.render = function(i, n) {
            var o, c, a;
            return a = i.snapshot, o = i.error, c = i.isPreview, this.markAsPreview(c), a != null ? this.renderSnapshot(a, c, n) : this.renderError(o, n);
          }, r.prototype.markAsPreview = function(i) {
            return i ? this.element.setAttribute("data-turbolinks-preview", "") : this.element.removeAttribute("data-turbolinks-preview");
          }, r.prototype.renderSnapshot = function(i, n, o) {
            return t.SnapshotRenderer.render(this.delegate, o, this.getSnapshot(), t.Snapshot.wrap(i), n);
          }, r.prototype.renderError = function(i, n) {
            return t.ErrorRenderer.render(this.delegate, n, i);
          }, r;
        }();
      }).call(this), (function() {
        var r = function(i, n) {
          return function() {
            return i.apply(n, arguments);
          };
        };
        t.ScrollManager = function() {
          function i(n) {
            this.delegate = n, this.onScroll = r(this.onScroll, this), this.onScroll = t.throttle(this.onScroll);
          }
          return i.prototype.start = function() {
            return this.started ? void 0 : (addEventListener("scroll", this.onScroll, !1), this.onScroll(), this.started = !0);
          }, i.prototype.stop = function() {
            return this.started ? (removeEventListener("scroll", this.onScroll, !1), this.started = !1) : void 0;
          }, i.prototype.scrollToElement = function(n) {
            return n.scrollIntoView();
          }, i.prototype.scrollToPosition = function(n) {
            var o, c;
            return o = n.x, c = n.y, window.scrollTo(o, c);
          }, i.prototype.onScroll = function(n) {
            return this.updatePosition({ x: window.pageXOffset, y: window.pageYOffset });
          }, i.prototype.updatePosition = function(n) {
            var o;
            return this.position = n, (o = this.delegate) != null ? o.scrollPositionChanged(this.position) : void 0;
          }, i;
        }();
      }).call(this), (function() {
        t.SnapshotCache = function() {
          function r(n) {
            this.size = n, this.keys = [], this.snapshots = {};
          }
          var i;
          return r.prototype.has = function(n) {
            var o;
            return o = i(n), o in this.snapshots;
          }, r.prototype.get = function(n) {
            var o;
            if (this.has(n)) return o = this.read(n), this.touch(n), o;
          }, r.prototype.put = function(n, o) {
            return this.write(n, o), this.touch(n), o;
          }, r.prototype.read = function(n) {
            var o;
            return o = i(n), this.snapshots[o];
          }, r.prototype.write = function(n, o) {
            var c;
            return c = i(n), this.snapshots[c] = o;
          }, r.prototype.touch = function(n) {
            var o, c;
            return c = i(n), o = this.keys.indexOf(c), o > -1 && this.keys.splice(o, 1), this.keys.unshift(c), this.trim();
          }, r.prototype.trim = function() {
            var n, o, c, a, l;
            for (a = this.keys.splice(this.size), l = [], n = 0, c = a.length; c > n; n++) o = a[n], l.push(delete this.snapshots[o]);
            return l;
          }, i = function(n) {
            return t.Location.wrap(n).toCacheKey();
          }, r;
        }();
      }).call(this), (function() {
        var r = function(i, n) {
          return function() {
            return i.apply(n, arguments);
          };
        };
        t.Visit = function() {
          function i(o, c, a) {
            this.controller = o, this.action = a, this.performScroll = r(this.performScroll, this), this.identifier = t.uuid(), this.location = t.Location.wrap(c), this.adapter = this.controller.adapter, this.state = "initialized", this.timingMetrics = {};
          }
          var n;
          return i.prototype.start = function() {
            return this.state === "initialized" ? (this.recordTimingMetric("visitStart"), this.state = "started", this.adapter.visitStarted(this)) : void 0;
          }, i.prototype.cancel = function() {
            var o;
            return this.state === "started" ? ((o = this.request) != null && o.cancel(), this.cancelRender(), this.state = "canceled") : void 0;
          }, i.prototype.complete = function() {
            var o;
            return this.state === "started" ? (this.recordTimingMetric("visitEnd"), this.state = "completed", typeof (o = this.adapter).visitCompleted == "function" && o.visitCompleted(this), this.controller.visitCompleted(this)) : void 0;
          }, i.prototype.fail = function() {
            var o;
            return this.state === "started" ? (this.state = "failed", typeof (o = this.adapter).visitFailed == "function" ? o.visitFailed(this) : void 0) : void 0;
          }, i.prototype.changeHistory = function() {
            var o, c;
            return this.historyChanged ? void 0 : (o = this.location.isEqualTo(this.referrer) ? "replace" : this.action, c = n(o), this.controller[c](this.location, this.restorationIdentifier), this.historyChanged = !0);
          }, i.prototype.issueRequest = function() {
            return this.shouldIssueRequest() && this.request == null ? (this.progress = 0, this.request = new t.HttpRequest(this, this.location, this.referrer), this.request.send()) : void 0;
          }, i.prototype.getCachedSnapshot = function() {
            var o;
            return !(o = this.controller.getCachedSnapshotForLocation(this.location)) || this.location.anchor != null && !o.hasAnchor(this.location.anchor) || this.action !== "restore" && !o.isPreviewable() ? void 0 : o;
          }, i.prototype.hasCachedSnapshot = function() {
            return this.getCachedSnapshot() != null;
          }, i.prototype.loadCachedSnapshot = function() {
            var o, c;
            return (c = this.getCachedSnapshot()) ? (o = this.shouldIssueRequest(), this.render(function() {
              var a;
              return this.cacheSnapshot(), this.controller.render({ snapshot: c, isPreview: o }, this.performScroll), typeof (a = this.adapter).visitRendered == "function" && a.visitRendered(this), o ? void 0 : this.complete();
            })) : void 0;
          }, i.prototype.loadResponse = function() {
            return this.response != null ? this.render(function() {
              var o, c;
              return this.cacheSnapshot(), this.request.failed ? (this.controller.render({ error: this.response }, this.performScroll), typeof (o = this.adapter).visitRendered == "function" && o.visitRendered(this), this.fail()) : (this.controller.render({ snapshot: this.response }, this.performScroll), typeof (c = this.adapter).visitRendered == "function" && c.visitRendered(this), this.complete());
            }) : void 0;
          }, i.prototype.followRedirect = function() {
            return this.redirectedToLocation && !this.followedRedirect ? (this.location = this.redirectedToLocation, this.controller.replaceHistoryWithLocationAndRestorationIdentifier(this.redirectedToLocation, this.restorationIdentifier), this.followedRedirect = !0) : void 0;
          }, i.prototype.requestStarted = function() {
            var o;
            return this.recordTimingMetric("requestStart"), typeof (o = this.adapter).visitRequestStarted == "function" ? o.visitRequestStarted(this) : void 0;
          }, i.prototype.requestProgressed = function(o) {
            var c;
            return this.progress = o, typeof (c = this.adapter).visitRequestProgressed == "function" ? c.visitRequestProgressed(this) : void 0;
          }, i.prototype.requestCompletedWithResponse = function(o, c) {
            return this.response = o, c != null && (this.redirectedToLocation = t.Location.wrap(c)), this.adapter.visitRequestCompleted(this);
          }, i.prototype.requestFailedWithStatusCode = function(o, c) {
            return this.response = c, this.adapter.visitRequestFailedWithStatusCode(this, o);
          }, i.prototype.requestFinished = function() {
            var o;
            return this.recordTimingMetric("requestEnd"), typeof (o = this.adapter).visitRequestFinished == "function" ? o.visitRequestFinished(this) : void 0;
          }, i.prototype.performScroll = function() {
            return this.scrolled ? void 0 : (this.action === "restore" ? this.scrollToRestoredPosition() || this.scrollToTop() : this.scrollToAnchor() || this.scrollToTop(), this.scrolled = !0);
          }, i.prototype.scrollToRestoredPosition = function() {
            var o, c;
            return o = (c = this.restorationData) != null ? c.scrollPosition : void 0, o != null ? (this.controller.scrollToPosition(o), !0) : void 0;
          }, i.prototype.scrollToAnchor = function() {
            return this.location.anchor != null ? (this.controller.scrollToAnchor(this.location.anchor), !0) : void 0;
          }, i.prototype.scrollToTop = function() {
            return this.controller.scrollToPosition({ x: 0, y: 0 });
          }, i.prototype.recordTimingMetric = function(o) {
            var c;
            return (c = this.timingMetrics)[o] != null ? c[o] : c[o] = (/* @__PURE__ */ new Date()).getTime();
          }, i.prototype.getTimingMetrics = function() {
            return t.copyObject(this.timingMetrics);
          }, n = function(o) {
            switch (o) {
              case "replace":
                return "replaceHistoryWithLocationAndRestorationIdentifier";
              case "advance":
              case "restore":
                return "pushHistoryWithLocationAndRestorationIdentifier";
            }
          }, i.prototype.shouldIssueRequest = function() {
            return this.action === "restore" ? !this.hasCachedSnapshot() : !0;
          }, i.prototype.cacheSnapshot = function() {
            return this.snapshotCached ? void 0 : (this.controller.cacheSnapshot(), this.snapshotCached = !0);
          }, i.prototype.render = function(o) {
            return this.cancelRender(), this.frame = requestAnimationFrame(/* @__PURE__ */ function(c) {
              return function() {
                return c.frame = null, o.call(c);
              };
            }(this));
          }, i.prototype.cancelRender = function() {
            return this.frame ? cancelAnimationFrame(this.frame) : void 0;
          }, i;
        }();
      }).call(this), (function() {
        var r = function(i, n) {
          return function() {
            return i.apply(n, arguments);
          };
        };
        t.Controller = function() {
          function i() {
            this.clickBubbled = r(this.clickBubbled, this), this.clickCaptured = r(this.clickCaptured, this), this.pageLoaded = r(this.pageLoaded, this), this.history = new t.History(this), this.view = new t.View(this), this.scrollManager = new t.ScrollManager(this), this.restorationData = {}, this.clearCache(), this.setProgressBarDelay(500);
          }
          return i.prototype.start = function() {
            return t.supported && !this.started ? (addEventListener("click", this.clickCaptured, !0), addEventListener("DOMContentLoaded", this.pageLoaded, !1), this.scrollManager.start(), this.startHistory(), this.started = !0, this.enabled = !0) : void 0;
          }, i.prototype.disable = function() {
            return this.enabled = !1;
          }, i.prototype.stop = function() {
            return this.started ? (removeEventListener("click", this.clickCaptured, !0), removeEventListener("DOMContentLoaded", this.pageLoaded, !1), this.scrollManager.stop(), this.stopHistory(), this.started = !1) : void 0;
          }, i.prototype.clearCache = function() {
            return this.cache = new t.SnapshotCache(10);
          }, i.prototype.visit = function(n, o) {
            var c, a;
            return o == null && (o = {}), n = t.Location.wrap(n), this.applicationAllowsVisitingLocation(n) ? this.locationIsVisitable(n) ? (c = (a = o.action) != null ? a : "advance", this.adapter.visitProposedToLocationWithAction(n, c)) : window.location = n : void 0;
          }, i.prototype.startVisitToLocationWithAction = function(n, o, c) {
            var a;
            return t.supported ? (a = this.getRestorationDataForIdentifier(c), this.startVisit(n, o, { restorationData: a })) : window.location = n;
          }, i.prototype.setProgressBarDelay = function(n) {
            return this.progressBarDelay = n;
          }, i.prototype.startHistory = function() {
            return this.location = t.Location.wrap(window.location), this.restorationIdentifier = t.uuid(), this.history.start(), this.history.replace(this.location, this.restorationIdentifier);
          }, i.prototype.stopHistory = function() {
            return this.history.stop();
          }, i.prototype.pushHistoryWithLocationAndRestorationIdentifier = function(n, o) {
            return this.restorationIdentifier = o, this.location = t.Location.wrap(n), this.history.push(this.location, this.restorationIdentifier);
          }, i.prototype.replaceHistoryWithLocationAndRestorationIdentifier = function(n, o) {
            return this.restorationIdentifier = o, this.location = t.Location.wrap(n), this.history.replace(this.location, this.restorationIdentifier);
          }, i.prototype.historyPoppedToLocationWithRestorationIdentifier = function(n, o) {
            var c;
            return this.restorationIdentifier = o, this.enabled ? (c = this.getRestorationDataForIdentifier(this.restorationIdentifier), this.startVisit(n, "restore", { restorationIdentifier: this.restorationIdentifier, restorationData: c, historyChanged: !0 }), this.location = t.Location.wrap(n)) : this.adapter.pageInvalidated();
          }, i.prototype.getCachedSnapshotForLocation = function(n) {
            var o;
            return o = this.cache.get(n), o ? o.clone() : void 0;
          }, i.prototype.shouldCacheSnapshot = function() {
            return this.view.getSnapshot().isCacheable();
          }, i.prototype.cacheSnapshot = function() {
            var n;
            return this.shouldCacheSnapshot() ? (this.notifyApplicationBeforeCachingSnapshot(), n = this.view.getSnapshot(), this.cache.put(this.lastRenderedLocation, n.clone())) : void 0;
          }, i.prototype.scrollToAnchor = function(n) {
            var o;
            return (o = this.view.getElementForAnchor(n)) ? this.scrollToElement(o) : this.scrollToPosition({ x: 0, y: 0 });
          }, i.prototype.scrollToElement = function(n) {
            return this.scrollManager.scrollToElement(n);
          }, i.prototype.scrollToPosition = function(n) {
            return this.scrollManager.scrollToPosition(n);
          }, i.prototype.scrollPositionChanged = function(n) {
            var o;
            return o = this.getCurrentRestorationData(), o.scrollPosition = n;
          }, i.prototype.render = function(n, o) {
            return this.view.render(n, o);
          }, i.prototype.viewInvalidated = function() {
            return this.adapter.pageInvalidated();
          }, i.prototype.viewWillRender = function(n) {
            return this.notifyApplicationBeforeRender(n);
          }, i.prototype.viewRendered = function() {
            return this.lastRenderedLocation = this.currentVisit.location, this.notifyApplicationAfterRender();
          }, i.prototype.pageLoaded = function() {
            return this.lastRenderedLocation = this.location, this.notifyApplicationAfterPageLoad();
          }, i.prototype.clickCaptured = function() {
            return removeEventListener("click", this.clickBubbled, !1), addEventListener("click", this.clickBubbled, !1);
          }, i.prototype.clickBubbled = function(n) {
            var o, c, a;
            return this.enabled && this.clickEventIsSignificant(n) && (c = this.getVisitableLinkForNode(n.target)) && (a = this.getVisitableLocationForLink(c)) && this.applicationAllowsFollowingLinkToLocation(c, a) ? (n.preventDefault(), o = this.getActionForLink(c), this.visit(a, { action: o })) : void 0;
          }, i.prototype.applicationAllowsFollowingLinkToLocation = function(n, o) {
            var c;
            return c = this.notifyApplicationAfterClickingLinkToLocation(n, o), !c.defaultPrevented;
          }, i.prototype.applicationAllowsVisitingLocation = function(n) {
            var o;
            return o = this.notifyApplicationBeforeVisitingLocation(n), !o.defaultPrevented;
          }, i.prototype.notifyApplicationAfterClickingLinkToLocation = function(n, o) {
            return t.dispatch("turbolinks:click", { target: n, data: { url: o.absoluteURL }, cancelable: !0 });
          }, i.prototype.notifyApplicationBeforeVisitingLocation = function(n) {
            return t.dispatch("turbolinks:before-visit", { data: { url: n.absoluteURL }, cancelable: !0 });
          }, i.prototype.notifyApplicationAfterVisitingLocation = function(n) {
            return t.dispatch("turbolinks:visit", { data: { url: n.absoluteURL } });
          }, i.prototype.notifyApplicationBeforeCachingSnapshot = function() {
            return t.dispatch("turbolinks:before-cache");
          }, i.prototype.notifyApplicationBeforeRender = function(n) {
            return t.dispatch("turbolinks:before-render", { data: { newBody: n } });
          }, i.prototype.notifyApplicationAfterRender = function() {
            return t.dispatch("turbolinks:render");
          }, i.prototype.notifyApplicationAfterPageLoad = function(n) {
            return n == null && (n = {}), t.dispatch("turbolinks:load", { data: { url: this.location.absoluteURL, timing: n } });
          }, i.prototype.startVisit = function(n, o, c) {
            var a;
            return (a = this.currentVisit) != null && a.cancel(), this.currentVisit = this.createVisit(n, o, c), this.currentVisit.start(), this.notifyApplicationAfterVisitingLocation(n);
          }, i.prototype.createVisit = function(n, o, c) {
            var a, l, h, u, m;
            return l = c ?? {}, u = l.restorationIdentifier, h = l.restorationData, a = l.historyChanged, m = new t.Visit(this, n, o), m.restorationIdentifier = u ?? t.uuid(), m.restorationData = t.copyObject(h), m.historyChanged = a, m.referrer = this.location, m;
          }, i.prototype.visitCompleted = function(n) {
            return this.notifyApplicationAfterPageLoad(n.getTimingMetrics());
          }, i.prototype.clickEventIsSignificant = function(n) {
            return !(n.defaultPrevented || n.target.isContentEditable || n.which > 1 || n.altKey || n.ctrlKey || n.metaKey || n.shiftKey);
          }, i.prototype.getVisitableLinkForNode = function(n) {
            return this.nodeIsVisitable(n) ? t.closest(n, "a[href]:not([target]):not([download])") : void 0;
          }, i.prototype.getVisitableLocationForLink = function(n) {
            var o;
            return o = new t.Location(n.getAttribute("href")), this.locationIsVisitable(o) ? o : void 0;
          }, i.prototype.getActionForLink = function(n) {
            var o;
            return (o = n.getAttribute("data-turbolinks-action")) != null ? o : "advance";
          }, i.prototype.nodeIsVisitable = function(n) {
            var o;
            return (o = t.closest(n, "[data-turbolinks]")) ? o.getAttribute("data-turbolinks") !== "false" : !0;
          }, i.prototype.locationIsVisitable = function(n) {
            return n.isPrefixedBy(this.view.getRootLocation()) && n.isHTML();
          }, i.prototype.getCurrentRestorationData = function() {
            return this.getRestorationDataForIdentifier(this.restorationIdentifier);
          }, i.prototype.getRestorationDataForIdentifier = function(n) {
            var o;
            return (o = this.restorationData)[n] != null ? o[n] : o[n] = {};
          }, i;
        }();
      }).call(this), (function() {
        (function() {
          var r, i;
          if ((r = i = document.currentScript) && !i.hasAttribute("data-turbolinks-suppress-warning")) {
            for (; r = r.parentNode; ) if (r === document.body) return console.warn(`You are loading Turbolinks from a <script> element inside the <body> element. This is probably not what you meant to do!

Load your application’s JavaScript bundle inside the <head> element instead. <script> elements in <body> are evaluated with each page change.

For more information, see: https://github.com/turbolinks/turbolinks#working-with-script-elements

——
Suppress this warning by adding a \`data-turbolinks-suppress-warning\` attribute to: %s`, i.outerHTML);
          }
        })();
      }).call(this), (function() {
        var r, i, n;
        t.start = function() {
          return i() ? (t.controller == null && (t.controller = r()), t.controller.start()) : void 0;
        }, i = function() {
          return window.Turbolinks == null && (window.Turbolinks = t), n();
        }, r = function() {
          var o;
          return o = new t.Controller(), o.adapter = new t.BrowserAdapter(o), o;
        }, n = function() {
          return window.Turbolinks === t;
        }, n() && t.start();
      }).call(this);
    }).call(this), s.exports && (s.exports = t);
  }).call(Oe);
})(xe);
var Rt = xe.exports;
const Mt = /* @__PURE__ */ ue(Rt);
class Pt {
  constructor(e, t, r) {
    this.eventTarget = e, this.eventName = t, this.eventOptions = r, this.unorderedBindings = /* @__PURE__ */ new Set();
  }
  connect() {
    this.eventTarget.addEventListener(this.eventName, this, this.eventOptions);
  }
  disconnect() {
    this.eventTarget.removeEventListener(this.eventName, this, this.eventOptions);
  }
  bindingConnected(e) {
    this.unorderedBindings.add(e);
  }
  bindingDisconnected(e) {
    this.unorderedBindings.delete(e);
  }
  handleEvent(e) {
    const t = Ft(e);
    for (const r of this.bindings) {
      if (t.immediatePropagationStopped)
        break;
      r.handleEvent(t);
    }
  }
  hasBindings() {
    return this.unorderedBindings.size > 0;
  }
  get bindings() {
    return Array.from(this.unorderedBindings).sort((e, t) => {
      const r = e.index, i = t.index;
      return r < i ? -1 : r > i ? 1 : 0;
    });
  }
}
function Ft(s) {
  if ("immediatePropagationStopped" in s)
    return s;
  {
    const { stopImmediatePropagation: e } = s;
    return Object.assign(s, {
      immediatePropagationStopped: !1,
      stopImmediatePropagation() {
        this.immediatePropagationStopped = !0, e.call(this);
      }
    });
  }
}
class Ot {
  constructor(e) {
    this.application = e, this.eventListenerMaps = /* @__PURE__ */ new Map(), this.started = !1;
  }
  start() {
    this.started || (this.started = !0, this.eventListeners.forEach((e) => e.connect()));
  }
  stop() {
    this.started && (this.started = !1, this.eventListeners.forEach((e) => e.disconnect()));
  }
  get eventListeners() {
    return Array.from(this.eventListenerMaps.values()).reduce((e, t) => e.concat(Array.from(t.values())), []);
  }
  bindingConnected(e) {
    this.fetchEventListenerForBinding(e).bindingConnected(e);
  }
  bindingDisconnected(e, t = !1) {
    this.fetchEventListenerForBinding(e).bindingDisconnected(e), t && this.clearEventListenersForBinding(e);
  }
  handleError(e, t, r = {}) {
    this.application.handleError(e, `Error ${t}`, r);
  }
  clearEventListenersForBinding(e) {
    const t = this.fetchEventListenerForBinding(e);
    t.hasBindings() || (t.disconnect(), this.removeMappedEventListenerFor(e));
  }
  removeMappedEventListenerFor(e) {
    const { eventTarget: t, eventName: r, eventOptions: i } = e, n = this.fetchEventListenerMapForEventTarget(t), o = this.cacheKey(r, i);
    n.delete(o), n.size == 0 && this.eventListenerMaps.delete(t);
  }
  fetchEventListenerForBinding(e) {
    const { eventTarget: t, eventName: r, eventOptions: i } = e;
    return this.fetchEventListener(t, r, i);
  }
  fetchEventListener(e, t, r) {
    const i = this.fetchEventListenerMapForEventTarget(e), n = this.cacheKey(t, r);
    let o = i.get(n);
    return o || (o = this.createEventListener(e, t, r), i.set(n, o)), o;
  }
  createEventListener(e, t, r) {
    const i = new Pt(e, t, r);
    return this.started && i.connect(), i;
  }
  fetchEventListenerMapForEventTarget(e) {
    let t = this.eventListenerMaps.get(e);
    return t || (t = /* @__PURE__ */ new Map(), this.eventListenerMaps.set(e, t)), t;
  }
  cacheKey(e, t) {
    const r = [e];
    return Object.keys(t).sort().forEach((i) => {
      r.push(`${t[i] ? "" : "!"}${i}`);
    }), r.join(":");
  }
}
const It = {
  stop({ event: s, value: e }) {
    return e && s.stopPropagation(), !0;
  },
  prevent({ event: s, value: e }) {
    return e && s.preventDefault(), !0;
  },
  self({ event: s, value: e, element: t }) {
    return e ? t === s.target : !0;
  }
}, xt = /^(?:(.+?)(?:\.(.+?))?(?:@(window|document))?->)?(.+?)(?:#([^:]+?))(?::(.+))?$/;
function Bt(s) {
  const t = s.trim().match(xt) || [];
  let r = t[1], i = t[2];
  return i && !["keydown", "keyup", "keypress"].includes(r) && (r += `.${i}`, i = ""), {
    eventTarget: Dt(t[3]),
    eventName: r,
    eventOptions: t[6] ? qt(t[6]) : {},
    identifier: t[4],
    methodName: t[5],
    keyFilter: i
  };
}
function Dt(s) {
  if (s == "window")
    return window;
  if (s == "document")
    return document;
}
function qt(s) {
  return s.split(":").reduce((e, t) => Object.assign(e, { [t.replace(/^!/, "")]: !/^!/.test(t) }), {});
}
function Ht(s) {
  if (s == window)
    return "window";
  if (s == document)
    return "document";
}
function de(s) {
  return s.replace(/(?:[_-])([a-z0-9])/g, (e, t) => t.toUpperCase());
}
function se(s) {
  return de(s.replace(/--/g, "-").replace(/__/g, "_"));
}
function z(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
function Be(s) {
  return s.replace(/([A-Z])/g, (e, t) => `-${t.toLowerCase()}`);
}
function Nt(s) {
  return s.match(/[^\s]+/g) || [];
}
class Vt {
  constructor(e, t, r, i) {
    this.element = e, this.index = t, this.eventTarget = r.eventTarget || e, this.eventName = r.eventName || jt(e) || Q("missing event name"), this.eventOptions = r.eventOptions || {}, this.identifier = r.identifier || Q("missing identifier"), this.methodName = r.methodName || Q("missing method name"), this.keyFilter = r.keyFilter || "", this.schema = i;
  }
  static forToken(e, t) {
    return new this(e.element, e.index, Bt(e.content), t);
  }
  toString() {
    const e = this.keyFilter ? `.${this.keyFilter}` : "", t = this.eventTargetName ? `@${this.eventTargetName}` : "";
    return `${this.eventName}${e}${t}->${this.identifier}#${this.methodName}`;
  }
  isFilterTarget(e) {
    if (!this.keyFilter)
      return !1;
    const t = this.keyFilter.split("+"), r = ["meta", "ctrl", "alt", "shift"], [i, n, o, c] = r.map((l) => t.includes(l));
    if (e.metaKey !== i || e.ctrlKey !== n || e.altKey !== o || e.shiftKey !== c)
      return !0;
    const a = t.filter((l) => !r.includes(l))[0];
    return a ? (Object.prototype.hasOwnProperty.call(this.keyMappings, a) || Q(`contains unknown key filter: ${this.keyFilter}`), this.keyMappings[a].toLowerCase() !== e.key.toLowerCase()) : !1;
  }
  get params() {
    const e = {}, t = new RegExp(`^data-${this.identifier}-(.+)-param$`, "i");
    for (const { name: r, value: i } of Array.from(this.element.attributes)) {
      const n = r.match(t), o = n && n[1];
      o && (e[de(o)] = $t(i));
    }
    return e;
  }
  get eventTargetName() {
    return Ht(this.eventTarget);
  }
  get keyMappings() {
    return this.schema.keyMappings;
  }
}
const Ee = {
  a: () => "click",
  button: () => "click",
  form: () => "submit",
  details: () => "toggle",
  input: (s) => s.getAttribute("type") == "submit" ? "click" : "input",
  select: () => "change",
  textarea: () => "input"
};
function jt(s) {
  const e = s.tagName.toLowerCase();
  if (e in Ee)
    return Ee[e](s);
}
function Q(s) {
  throw new Error(s);
}
function $t(s) {
  try {
    return JSON.parse(s);
  } catch {
    return s;
  }
}
class Ut {
  constructor(e, t) {
    this.context = e, this.action = t;
  }
  get index() {
    return this.action.index;
  }
  get eventTarget() {
    return this.action.eventTarget;
  }
  get eventOptions() {
    return this.action.eventOptions;
  }
  get identifier() {
    return this.context.identifier;
  }
  handleEvent(e) {
    this.willBeInvokedByEvent(e) && this.applyEventModifiers(e) && this.invokeWithEvent(e);
  }
  get eventName() {
    return this.action.eventName;
  }
  get method() {
    const e = this.controller[this.methodName];
    if (typeof e == "function")
      return e;
    throw new Error(`Action "${this.action}" references undefined method "${this.methodName}"`);
  }
  applyEventModifiers(e) {
    const { element: t } = this.action, { actionDescriptorFilters: r } = this.context.application;
    let i = !0;
    for (const [n, o] of Object.entries(this.eventOptions))
      if (n in r) {
        const c = r[n];
        i = i && c({ name: n, value: o, event: e, element: t });
      } else
        continue;
    return i;
  }
  invokeWithEvent(e) {
    const { target: t, currentTarget: r } = e;
    try {
      const { params: i } = this.action, n = Object.assign(e, { params: i });
      this.method.call(this.controller, n), this.context.logDebugActivity(this.methodName, { event: e, target: t, currentTarget: r, action: this.methodName });
    } catch (i) {
      const { identifier: n, controller: o, element: c, index: a } = this, l = { identifier: n, controller: o, element: c, index: a, event: e };
      this.context.handleError(i, `invoking action "${this.action}"`, l);
    }
  }
  willBeInvokedByEvent(e) {
    const t = e.target;
    return e instanceof KeyboardEvent && this.action.isFilterTarget(e) ? !1 : this.element === t ? !0 : t instanceof Element && this.element.contains(t) ? this.scope.containsElement(t) : this.scope.containsElement(this.action.element);
  }
  get controller() {
    return this.context.controller;
  }
  get methodName() {
    return this.action.methodName;
  }
  get element() {
    return this.scope.element;
  }
  get scope() {
    return this.context.scope;
  }
}
class De {
  constructor(e, t) {
    this.mutationObserverInit = { attributes: !0, childList: !0, subtree: !0 }, this.element = e, this.started = !1, this.delegate = t, this.elements = /* @__PURE__ */ new Set(), this.mutationObserver = new MutationObserver((r) => this.processMutations(r));
  }
  start() {
    this.started || (this.started = !0, this.mutationObserver.observe(this.element, this.mutationObserverInit), this.refresh());
  }
  pause(e) {
    this.started && (this.mutationObserver.disconnect(), this.started = !1), e(), this.started || (this.mutationObserver.observe(this.element, this.mutationObserverInit), this.started = !0);
  }
  stop() {
    this.started && (this.mutationObserver.takeRecords(), this.mutationObserver.disconnect(), this.started = !1);
  }
  refresh() {
    if (this.started) {
      const e = new Set(this.matchElementsInTree());
      for (const t of Array.from(this.elements))
        e.has(t) || this.removeElement(t);
      for (const t of Array.from(e))
        this.addElement(t);
    }
  }
  processMutations(e) {
    if (this.started)
      for (const t of e)
        this.processMutation(t);
  }
  processMutation(e) {
    e.type == "attributes" ? this.processAttributeChange(e.target, e.attributeName) : e.type == "childList" && (this.processRemovedNodes(e.removedNodes), this.processAddedNodes(e.addedNodes));
  }
  processAttributeChange(e, t) {
    const r = e;
    this.elements.has(r) ? this.delegate.elementAttributeChanged && this.matchElement(r) ? this.delegate.elementAttributeChanged(r, t) : this.removeElement(r) : this.matchElement(r) && this.addElement(r);
  }
  processRemovedNodes(e) {
    for (const t of Array.from(e)) {
      const r = this.elementFromNode(t);
      r && this.processTree(r, this.removeElement);
    }
  }
  processAddedNodes(e) {
    for (const t of Array.from(e)) {
      const r = this.elementFromNode(t);
      r && this.elementIsActive(r) && this.processTree(r, this.addElement);
    }
  }
  matchElement(e) {
    return this.delegate.matchElement(e);
  }
  matchElementsInTree(e = this.element) {
    return this.delegate.matchElementsInTree(e);
  }
  processTree(e, t) {
    for (const r of this.matchElementsInTree(e))
      t.call(this, r);
  }
  elementFromNode(e) {
    if (e.nodeType == Node.ELEMENT_NODE)
      return e;
  }
  elementIsActive(e) {
    return e.isConnected != this.element.isConnected ? !1 : this.element.contains(e);
  }
  addElement(e) {
    this.elements.has(e) || this.elementIsActive(e) && (this.elements.add(e), this.delegate.elementMatched && this.delegate.elementMatched(e));
  }
  removeElement(e) {
    this.elements.has(e) && (this.elements.delete(e), this.delegate.elementUnmatched && this.delegate.elementUnmatched(e));
  }
}
class Wt {
  constructor(e, t, r) {
    this.attributeName = t, this.delegate = r, this.elementObserver = new De(e, this);
  }
  get element() {
    return this.elementObserver.element;
  }
  get selector() {
    return `[${this.attributeName}]`;
  }
  start() {
    this.elementObserver.start();
  }
  pause(e) {
    this.elementObserver.pause(e);
  }
  stop() {
    this.elementObserver.stop();
  }
  refresh() {
    this.elementObserver.refresh();
  }
  get started() {
    return this.elementObserver.started;
  }
  matchElement(e) {
    return e.hasAttribute(this.attributeName);
  }
  matchElementsInTree(e) {
    const t = this.matchElement(e) ? [e] : [], r = Array.from(e.querySelectorAll(this.selector));
    return t.concat(r);
  }
  elementMatched(e) {
    this.delegate.elementMatchedAttribute && this.delegate.elementMatchedAttribute(e, this.attributeName);
  }
  elementUnmatched(e) {
    this.delegate.elementUnmatchedAttribute && this.delegate.elementUnmatchedAttribute(e, this.attributeName);
  }
  elementAttributeChanged(e, t) {
    this.delegate.elementAttributeValueChanged && this.attributeName == t && this.delegate.elementAttributeValueChanged(e, t);
  }
}
function _t(s, e, t) {
  qe(s, e).add(t);
}
function Kt(s, e, t) {
  qe(s, e).delete(t), zt(s, e);
}
function qe(s, e) {
  let t = s.get(e);
  return t || (t = /* @__PURE__ */ new Set(), s.set(e, t)), t;
}
function zt(s, e) {
  const t = s.get(e);
  t != null && t.size == 0 && s.delete(e);
}
class H {
  constructor() {
    this.valuesByKey = /* @__PURE__ */ new Map();
  }
  get keys() {
    return Array.from(this.valuesByKey.keys());
  }
  get values() {
    return Array.from(this.valuesByKey.values()).reduce((t, r) => t.concat(Array.from(r)), []);
  }
  get size() {
    return Array.from(this.valuesByKey.values()).reduce((t, r) => t + r.size, 0);
  }
  add(e, t) {
    _t(this.valuesByKey, e, t);
  }
  delete(e, t) {
    Kt(this.valuesByKey, e, t);
  }
  has(e, t) {
    const r = this.valuesByKey.get(e);
    return r != null && r.has(t);
  }
  hasKey(e) {
    return this.valuesByKey.has(e);
  }
  hasValue(e) {
    return Array.from(this.valuesByKey.values()).some((r) => r.has(e));
  }
  getValuesForKey(e) {
    const t = this.valuesByKey.get(e);
    return t ? Array.from(t) : [];
  }
  getKeysForValue(e) {
    return Array.from(this.valuesByKey).filter(([t, r]) => r.has(e)).map(([t, r]) => t);
  }
}
class Jt {
  constructor(e, t, r, i = {}) {
    this.selector = t, this.details = i, this.elementObserver = new De(e, this), this.delegate = r, this.matchesByElement = new H();
  }
  get started() {
    return this.elementObserver.started;
  }
  start() {
    this.elementObserver.start();
  }
  pause(e) {
    this.elementObserver.pause(e);
  }
  stop() {
    this.elementObserver.stop();
  }
  refresh() {
    this.elementObserver.refresh();
  }
  get element() {
    return this.elementObserver.element;
  }
  matchElement(e) {
    const t = e.matches(this.selector);
    return this.delegate.selectorMatchElement ? t && this.delegate.selectorMatchElement(e, this.details) : t;
  }
  matchElementsInTree(e) {
    const t = this.matchElement(e) ? [e] : [], r = Array.from(e.querySelectorAll(this.selector)).filter((i) => this.matchElement(i));
    return t.concat(r);
  }
  elementMatched(e) {
    this.selectorMatched(e);
  }
  elementUnmatched(e) {
    this.selectorUnmatched(e);
  }
  elementAttributeChanged(e, t) {
    const r = this.matchElement(e), i = this.matchesByElement.has(this.selector, e);
    !r && i && this.selectorUnmatched(e);
  }
  selectorMatched(e) {
    this.delegate.selectorMatched && (this.delegate.selectorMatched(e, this.selector, this.details), this.matchesByElement.add(this.selector, e));
  }
  selectorUnmatched(e) {
    this.delegate.selectorUnmatched(e, this.selector, this.details), this.matchesByElement.delete(this.selector, e);
  }
}
class Xt {
  constructor(e, t) {
    this.element = e, this.delegate = t, this.started = !1, this.stringMap = /* @__PURE__ */ new Map(), this.mutationObserver = new MutationObserver((r) => this.processMutations(r));
  }
  start() {
    this.started || (this.started = !0, this.mutationObserver.observe(this.element, { attributes: !0, attributeOldValue: !0 }), this.refresh());
  }
  stop() {
    this.started && (this.mutationObserver.takeRecords(), this.mutationObserver.disconnect(), this.started = !1);
  }
  refresh() {
    if (this.started)
      for (const e of this.knownAttributeNames)
        this.refreshAttribute(e, null);
  }
  processMutations(e) {
    if (this.started)
      for (const t of e)
        this.processMutation(t);
  }
  processMutation(e) {
    const t = e.attributeName;
    t && this.refreshAttribute(t, e.oldValue);
  }
  refreshAttribute(e, t) {
    const r = this.delegate.getStringMapKeyForAttribute(e);
    if (r != null) {
      this.stringMap.has(e) || this.stringMapKeyAdded(r, e);
      const i = this.element.getAttribute(e);
      if (this.stringMap.get(e) != i && this.stringMapValueChanged(i, r, t), i == null) {
        const n = this.stringMap.get(e);
        this.stringMap.delete(e), n && this.stringMapKeyRemoved(r, e, n);
      } else
        this.stringMap.set(e, i);
    }
  }
  stringMapKeyAdded(e, t) {
    this.delegate.stringMapKeyAdded && this.delegate.stringMapKeyAdded(e, t);
  }
  stringMapValueChanged(e, t, r) {
    this.delegate.stringMapValueChanged && this.delegate.stringMapValueChanged(e, t, r);
  }
  stringMapKeyRemoved(e, t, r) {
    this.delegate.stringMapKeyRemoved && this.delegate.stringMapKeyRemoved(e, t, r);
  }
  get knownAttributeNames() {
    return Array.from(new Set(this.currentAttributeNames.concat(this.recordedAttributeNames)));
  }
  get currentAttributeNames() {
    return Array.from(this.element.attributes).map((e) => e.name);
  }
  get recordedAttributeNames() {
    return Array.from(this.stringMap.keys());
  }
}
class He {
  constructor(e, t, r) {
    this.attributeObserver = new Wt(e, t, this), this.delegate = r, this.tokensByElement = new H();
  }
  get started() {
    return this.attributeObserver.started;
  }
  start() {
    this.attributeObserver.start();
  }
  pause(e) {
    this.attributeObserver.pause(e);
  }
  stop() {
    this.attributeObserver.stop();
  }
  refresh() {
    this.attributeObserver.refresh();
  }
  get element() {
    return this.attributeObserver.element;
  }
  get attributeName() {
    return this.attributeObserver.attributeName;
  }
  elementMatchedAttribute(e) {
    this.tokensMatched(this.readTokensForElement(e));
  }
  elementAttributeValueChanged(e) {
    const [t, r] = this.refreshTokensForElement(e);
    this.tokensUnmatched(t), this.tokensMatched(r);
  }
  elementUnmatchedAttribute(e) {
    this.tokensUnmatched(this.tokensByElement.getValuesForKey(e));
  }
  tokensMatched(e) {
    e.forEach((t) => this.tokenMatched(t));
  }
  tokensUnmatched(e) {
    e.forEach((t) => this.tokenUnmatched(t));
  }
  tokenMatched(e) {
    this.delegate.tokenMatched(e), this.tokensByElement.add(e.element, e);
  }
  tokenUnmatched(e) {
    this.delegate.tokenUnmatched(e), this.tokensByElement.delete(e.element, e);
  }
  refreshTokensForElement(e) {
    const t = this.tokensByElement.getValuesForKey(e), r = this.readTokensForElement(e), i = Qt(t, r).findIndex(([n, o]) => !Yt(n, o));
    return i == -1 ? [[], []] : [t.slice(i), r.slice(i)];
  }
  readTokensForElement(e) {
    const t = this.attributeName, r = e.getAttribute(t) || "";
    return Gt(r, e, t);
  }
}
function Gt(s, e, t) {
  return s.trim().split(/\s+/).filter((r) => r.length).map((r, i) => ({ element: e, attributeName: t, content: r, index: i }));
}
function Qt(s, e) {
  const t = Math.max(s.length, e.length);
  return Array.from({ length: t }, (r, i) => [s[i], e[i]]);
}
function Yt(s, e) {
  return s && e && s.index == e.index && s.content == e.content;
}
class Ne {
  constructor(e, t, r) {
    this.tokenListObserver = new He(e, t, this), this.delegate = r, this.parseResultsByToken = /* @__PURE__ */ new WeakMap(), this.valuesByTokenByElement = /* @__PURE__ */ new WeakMap();
  }
  get started() {
    return this.tokenListObserver.started;
  }
  start() {
    this.tokenListObserver.start();
  }
  stop() {
    this.tokenListObserver.stop();
  }
  refresh() {
    this.tokenListObserver.refresh();
  }
  get element() {
    return this.tokenListObserver.element;
  }
  get attributeName() {
    return this.tokenListObserver.attributeName;
  }
  tokenMatched(e) {
    const { element: t } = e, { value: r } = this.fetchParseResultForToken(e);
    r && (this.fetchValuesByTokenForElement(t).set(e, r), this.delegate.elementMatchedValue(t, r));
  }
  tokenUnmatched(e) {
    const { element: t } = e, { value: r } = this.fetchParseResultForToken(e);
    r && (this.fetchValuesByTokenForElement(t).delete(e), this.delegate.elementUnmatchedValue(t, r));
  }
  fetchParseResultForToken(e) {
    let t = this.parseResultsByToken.get(e);
    return t || (t = this.parseToken(e), this.parseResultsByToken.set(e, t)), t;
  }
  fetchValuesByTokenForElement(e) {
    let t = this.valuesByTokenByElement.get(e);
    return t || (t = /* @__PURE__ */ new Map(), this.valuesByTokenByElement.set(e, t)), t;
  }
  parseToken(e) {
    try {
      return { value: this.delegate.parseValueForToken(e) };
    } catch (t) {
      return { error: t };
    }
  }
}
class Zt {
  constructor(e, t) {
    this.context = e, this.delegate = t, this.bindingsByAction = /* @__PURE__ */ new Map();
  }
  start() {
    this.valueListObserver || (this.valueListObserver = new Ne(this.element, this.actionAttribute, this), this.valueListObserver.start());
  }
  stop() {
    this.valueListObserver && (this.valueListObserver.stop(), delete this.valueListObserver, this.disconnectAllActions());
  }
  get element() {
    return this.context.element;
  }
  get identifier() {
    return this.context.identifier;
  }
  get actionAttribute() {
    return this.schema.actionAttribute;
  }
  get schema() {
    return this.context.schema;
  }
  get bindings() {
    return Array.from(this.bindingsByAction.values());
  }
  connectAction(e) {
    const t = new Ut(this.context, e);
    this.bindingsByAction.set(e, t), this.delegate.bindingConnected(t);
  }
  disconnectAction(e) {
    const t = this.bindingsByAction.get(e);
    t && (this.bindingsByAction.delete(e), this.delegate.bindingDisconnected(t));
  }
  disconnectAllActions() {
    this.bindings.forEach((e) => this.delegate.bindingDisconnected(e, !0)), this.bindingsByAction.clear();
  }
  parseValueForToken(e) {
    const t = Vt.forToken(e, this.schema);
    if (t.identifier == this.identifier)
      return t;
  }
  elementMatchedValue(e, t) {
    this.connectAction(t);
  }
  elementUnmatchedValue(e, t) {
    this.disconnectAction(t);
  }
}
class er {
  constructor(e, t) {
    this.context = e, this.receiver = t, this.stringMapObserver = new Xt(this.element, this), this.valueDescriptorMap = this.controller.valueDescriptorMap;
  }
  start() {
    this.stringMapObserver.start(), this.invokeChangedCallbacksForDefaultValues();
  }
  stop() {
    this.stringMapObserver.stop();
  }
  get element() {
    return this.context.element;
  }
  get controller() {
    return this.context.controller;
  }
  getStringMapKeyForAttribute(e) {
    if (e in this.valueDescriptorMap)
      return this.valueDescriptorMap[e].name;
  }
  stringMapKeyAdded(e, t) {
    const r = this.valueDescriptorMap[t];
    this.hasValue(e) || this.invokeChangedCallback(e, r.writer(this.receiver[e]), r.writer(r.defaultValue));
  }
  stringMapValueChanged(e, t, r) {
    const i = this.valueDescriptorNameMap[t];
    e !== null && (r === null && (r = i.writer(i.defaultValue)), this.invokeChangedCallback(t, e, r));
  }
  stringMapKeyRemoved(e, t, r) {
    const i = this.valueDescriptorNameMap[e];
    this.hasValue(e) ? this.invokeChangedCallback(e, i.writer(this.receiver[e]), r) : this.invokeChangedCallback(e, i.writer(i.defaultValue), r);
  }
  invokeChangedCallbacksForDefaultValues() {
    for (const { key: e, name: t, defaultValue: r, writer: i } of this.valueDescriptors)
      r != null && !this.controller.data.has(e) && this.invokeChangedCallback(t, i(r), void 0);
  }
  invokeChangedCallback(e, t, r) {
    const i = `${e}Changed`, n = this.receiver[i];
    if (typeof n == "function") {
      const o = this.valueDescriptorNameMap[e];
      try {
        const c = o.reader(t);
        let a = r;
        r && (a = o.reader(r)), n.call(this.receiver, c, a);
      } catch (c) {
        throw c instanceof TypeError && (c.message = `Stimulus Value "${this.context.identifier}.${o.name}" - ${c.message}`), c;
      }
    }
  }
  get valueDescriptors() {
    const { valueDescriptorMap: e } = this;
    return Object.keys(e).map((t) => e[t]);
  }
  get valueDescriptorNameMap() {
    const e = {};
    return Object.keys(this.valueDescriptorMap).forEach((t) => {
      const r = this.valueDescriptorMap[t];
      e[r.name] = r;
    }), e;
  }
  hasValue(e) {
    const t = this.valueDescriptorNameMap[e], r = `has${z(t.name)}`;
    return this.receiver[r];
  }
}
class tr {
  constructor(e, t) {
    this.context = e, this.delegate = t, this.targetsByName = new H();
  }
  start() {
    this.tokenListObserver || (this.tokenListObserver = new He(this.element, this.attributeName, this), this.tokenListObserver.start());
  }
  stop() {
    this.tokenListObserver && (this.disconnectAllTargets(), this.tokenListObserver.stop(), delete this.tokenListObserver);
  }
  tokenMatched({ element: e, content: t }) {
    this.scope.containsElement(e) && this.connectTarget(e, t);
  }
  tokenUnmatched({ element: e, content: t }) {
    this.disconnectTarget(e, t);
  }
  connectTarget(e, t) {
    var r;
    this.targetsByName.has(t, e) || (this.targetsByName.add(t, e), (r = this.tokenListObserver) === null || r === void 0 || r.pause(() => this.delegate.targetConnected(e, t)));
  }
  disconnectTarget(e, t) {
    var r;
    this.targetsByName.has(t, e) && (this.targetsByName.delete(t, e), (r = this.tokenListObserver) === null || r === void 0 || r.pause(() => this.delegate.targetDisconnected(e, t)));
  }
  disconnectAllTargets() {
    for (const e of this.targetsByName.keys)
      for (const t of this.targetsByName.getValuesForKey(e))
        this.disconnectTarget(t, e);
  }
  get attributeName() {
    return `data-${this.context.identifier}-target`;
  }
  get element() {
    return this.context.element;
  }
  get scope() {
    return this.context.scope;
  }
}
function J(s, e) {
  const t = Ve(s);
  return Array.from(t.reduce((r, i) => (sr(i, e).forEach((n) => r.add(n)), r), /* @__PURE__ */ new Set()));
}
function rr(s, e) {
  return Ve(s).reduce((r, i) => (r.push(...ir(i, e)), r), []);
}
function Ve(s) {
  const e = [];
  for (; s; )
    e.push(s), s = Object.getPrototypeOf(s);
  return e.reverse();
}
function sr(s, e) {
  const t = s[e];
  return Array.isArray(t) ? t : [];
}
function ir(s, e) {
  const t = s[e];
  return t ? Object.keys(t).map((r) => [r, t[r]]) : [];
}
class nr {
  constructor(e, t) {
    this.context = e, this.delegate = t, this.outletsByName = new H(), this.outletElementsByName = new H(), this.selectorObserverMap = /* @__PURE__ */ new Map();
  }
  start() {
    this.selectorObserverMap.size === 0 && (this.outletDefinitions.forEach((e) => {
      const t = this.selector(e), r = { outletName: e };
      t && this.selectorObserverMap.set(e, new Jt(document.body, t, this, r));
    }), this.selectorObserverMap.forEach((e) => e.start())), this.dependentContexts.forEach((e) => e.refresh());
  }
  stop() {
    this.selectorObserverMap.size > 0 && (this.disconnectAllOutlets(), this.selectorObserverMap.forEach((e) => e.stop()), this.selectorObserverMap.clear());
  }
  refresh() {
    this.selectorObserverMap.forEach((e) => e.refresh());
  }
  selectorMatched(e, t, { outletName: r }) {
    const i = this.getOutlet(e, r);
    i && this.connectOutlet(i, e, r);
  }
  selectorUnmatched(e, t, { outletName: r }) {
    const i = this.getOutletFromMap(e, r);
    i && this.disconnectOutlet(i, e, r);
  }
  selectorMatchElement(e, { outletName: t }) {
    return this.hasOutlet(e, t) && e.matches(`[${this.context.application.schema.controllerAttribute}~=${t}]`);
  }
  connectOutlet(e, t, r) {
    var i;
    this.outletElementsByName.has(r, t) || (this.outletsByName.add(r, e), this.outletElementsByName.add(r, t), (i = this.selectorObserverMap.get(r)) === null || i === void 0 || i.pause(() => this.delegate.outletConnected(e, t, r)));
  }
  disconnectOutlet(e, t, r) {
    var i;
    this.outletElementsByName.has(r, t) && (this.outletsByName.delete(r, e), this.outletElementsByName.delete(r, t), (i = this.selectorObserverMap.get(r)) === null || i === void 0 || i.pause(() => this.delegate.outletDisconnected(e, t, r)));
  }
  disconnectAllOutlets() {
    for (const e of this.outletElementsByName.keys)
      for (const t of this.outletElementsByName.getValuesForKey(e))
        for (const r of this.outletsByName.getValuesForKey(e))
          this.disconnectOutlet(r, t, e);
  }
  selector(e) {
    return this.scope.outlets.getSelectorForOutletName(e);
  }
  get outletDependencies() {
    const e = new H();
    return this.router.modules.forEach((t) => {
      const r = t.definition.controllerConstructor;
      J(r, "outlets").forEach((n) => e.add(n, t.identifier));
    }), e;
  }
  get outletDefinitions() {
    return this.outletDependencies.getKeysForValue(this.identifier);
  }
  get dependentControllerIdentifiers() {
    return this.outletDependencies.getValuesForKey(this.identifier);
  }
  get dependentContexts() {
    const e = this.dependentControllerIdentifiers;
    return this.router.contexts.filter((t) => e.includes(t.identifier));
  }
  hasOutlet(e, t) {
    return !!this.getOutlet(e, t) || !!this.getOutletFromMap(e, t);
  }
  getOutlet(e, t) {
    return this.application.getControllerForElementAndIdentifier(e, t);
  }
  getOutletFromMap(e, t) {
    return this.outletsByName.getValuesForKey(t).find((r) => r.element === e);
  }
  get scope() {
    return this.context.scope;
  }
  get identifier() {
    return this.context.identifier;
  }
  get application() {
    return this.context.application;
  }
  get router() {
    return this.application.router;
  }
}
class or {
  constructor(e, t) {
    this.logDebugActivity = (r, i = {}) => {
      const { identifier: n, controller: o, element: c } = this;
      i = Object.assign({ identifier: n, controller: o, element: c }, i), this.application.logDebugActivity(this.identifier, r, i);
    }, this.module = e, this.scope = t, this.controller = new e.controllerConstructor(this), this.bindingObserver = new Zt(this, this.dispatcher), this.valueObserver = new er(this, this.controller), this.targetObserver = new tr(this, this), this.outletObserver = new nr(this, this);
    try {
      this.controller.initialize(), this.logDebugActivity("initialize");
    } catch (r) {
      this.handleError(r, "initializing controller");
    }
  }
  connect() {
    this.bindingObserver.start(), this.valueObserver.start(), this.targetObserver.start(), this.outletObserver.start();
    try {
      this.controller.connect(), this.logDebugActivity("connect");
    } catch (e) {
      this.handleError(e, "connecting controller");
    }
  }
  refresh() {
    this.outletObserver.refresh();
  }
  disconnect() {
    try {
      this.controller.disconnect(), this.logDebugActivity("disconnect");
    } catch (e) {
      this.handleError(e, "disconnecting controller");
    }
    this.outletObserver.stop(), this.targetObserver.stop(), this.valueObserver.stop(), this.bindingObserver.stop();
  }
  get application() {
    return this.module.application;
  }
  get identifier() {
    return this.module.identifier;
  }
  get schema() {
    return this.application.schema;
  }
  get dispatcher() {
    return this.application.dispatcher;
  }
  get element() {
    return this.scope.element;
  }
  get parentElement() {
    return this.element.parentElement;
  }
  handleError(e, t, r = {}) {
    const { identifier: i, controller: n, element: o } = this;
    r = Object.assign({ identifier: i, controller: n, element: o }, r), this.application.handleError(e, `Error ${t}`, r);
  }
  targetConnected(e, t) {
    this.invokeControllerMethod(`${t}TargetConnected`, e);
  }
  targetDisconnected(e, t) {
    this.invokeControllerMethod(`${t}TargetDisconnected`, e);
  }
  outletConnected(e, t, r) {
    this.invokeControllerMethod(`${se(r)}OutletConnected`, e, t);
  }
  outletDisconnected(e, t, r) {
    this.invokeControllerMethod(`${se(r)}OutletDisconnected`, e, t);
  }
  invokeControllerMethod(e, ...t) {
    const r = this.controller;
    typeof r[e] == "function" && r[e](...t);
  }
}
function ar(s) {
  return cr(s, lr(s));
}
function cr(s, e) {
  const t = pr(s), r = hr(s.prototype, e);
  return Object.defineProperties(t.prototype, r), t;
}
function lr(s) {
  return J(s, "blessings").reduce((t, r) => {
    const i = r(s);
    for (const n in i) {
      const o = t[n] || {};
      t[n] = Object.assign(o, i[n]);
    }
    return t;
  }, {});
}
function hr(s, e) {
  return dr(e).reduce((t, r) => {
    const i = ur(s, e, r);
    return i && Object.assign(t, { [r]: i }), t;
  }, {});
}
function ur(s, e, t) {
  const r = Object.getOwnPropertyDescriptor(s, t);
  if (!(r && "value" in r)) {
    const n = Object.getOwnPropertyDescriptor(e, t).value;
    return r && (n.get = r.get || n.get, n.set = r.set || n.set), n;
  }
}
const dr = typeof Object.getOwnPropertySymbols == "function" ? (s) => [...Object.getOwnPropertyNames(s), ...Object.getOwnPropertySymbols(s)] : Object.getOwnPropertyNames, pr = (() => {
  function s(t) {
    function r() {
      return Reflect.construct(t, arguments, new.target);
    }
    return r.prototype = Object.create(t.prototype, {
      constructor: { value: r }
    }), Reflect.setPrototypeOf(r, t), r;
  }
  function e() {
    const r = s(function() {
      this.a.call(this);
    });
    return r.prototype.a = function() {
    }, new r();
  }
  try {
    return e(), s;
  } catch {
    return (r) => class extends r {
    };
  }
})();
function mr(s) {
  return {
    identifier: s.identifier,
    controllerConstructor: ar(s.controllerConstructor)
  };
}
class fr {
  constructor(e, t) {
    this.application = e, this.definition = mr(t), this.contextsByScope = /* @__PURE__ */ new WeakMap(), this.connectedContexts = /* @__PURE__ */ new Set();
  }
  get identifier() {
    return this.definition.identifier;
  }
  get controllerConstructor() {
    return this.definition.controllerConstructor;
  }
  get contexts() {
    return Array.from(this.connectedContexts);
  }
  connectContextForScope(e) {
    const t = this.fetchContextForScope(e);
    this.connectedContexts.add(t), t.connect();
  }
  disconnectContextForScope(e) {
    const t = this.contextsByScope.get(e);
    t && (this.connectedContexts.delete(t), t.disconnect());
  }
  fetchContextForScope(e) {
    let t = this.contextsByScope.get(e);
    return t || (t = new or(this, e), this.contextsByScope.set(e, t)), t;
  }
}
class gr {
  constructor(e) {
    this.scope = e;
  }
  has(e) {
    return this.data.has(this.getDataKey(e));
  }
  get(e) {
    return this.getAll(e)[0];
  }
  getAll(e) {
    const t = this.data.get(this.getDataKey(e)) || "";
    return Nt(t);
  }
  getAttributeName(e) {
    return this.data.getAttributeNameForKey(this.getDataKey(e));
  }
  getDataKey(e) {
    return `${e}-class`;
  }
  get data() {
    return this.scope.data;
  }
}
class vr {
  constructor(e) {
    this.scope = e;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get(e) {
    const t = this.getAttributeNameForKey(e);
    return this.element.getAttribute(t);
  }
  set(e, t) {
    const r = this.getAttributeNameForKey(e);
    return this.element.setAttribute(r, t), this.get(e);
  }
  has(e) {
    const t = this.getAttributeNameForKey(e);
    return this.element.hasAttribute(t);
  }
  delete(e) {
    if (this.has(e)) {
      const t = this.getAttributeNameForKey(e);
      return this.element.removeAttribute(t), !0;
    } else
      return !1;
  }
  getAttributeNameForKey(e) {
    return `data-${this.identifier}-${Be(e)}`;
  }
}
class br {
  constructor(e) {
    this.warnedKeysByObject = /* @__PURE__ */ new WeakMap(), this.logger = e;
  }
  warn(e, t, r) {
    let i = this.warnedKeysByObject.get(e);
    i || (i = /* @__PURE__ */ new Set(), this.warnedKeysByObject.set(e, i)), i.has(t) || (i.add(t), this.logger.warn(r, e));
  }
}
function ie(s, e) {
  return `[${s}~="${e}"]`;
}
class yr {
  constructor(e) {
    this.scope = e;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get schema() {
    return this.scope.schema;
  }
  has(e) {
    return this.find(e) != null;
  }
  find(...e) {
    return e.reduce((t, r) => t || this.findTarget(r) || this.findLegacyTarget(r), void 0);
  }
  findAll(...e) {
    return e.reduce((t, r) => [
      ...t,
      ...this.findAllTargets(r),
      ...this.findAllLegacyTargets(r)
    ], []);
  }
  findTarget(e) {
    const t = this.getSelectorForTargetName(e);
    return this.scope.findElement(t);
  }
  findAllTargets(e) {
    const t = this.getSelectorForTargetName(e);
    return this.scope.findAllElements(t);
  }
  getSelectorForTargetName(e) {
    const t = this.schema.targetAttributeForScope(this.identifier);
    return ie(t, e);
  }
  findLegacyTarget(e) {
    const t = this.getLegacySelectorForTargetName(e);
    return this.deprecate(this.scope.findElement(t), e);
  }
  findAllLegacyTargets(e) {
    const t = this.getLegacySelectorForTargetName(e);
    return this.scope.findAllElements(t).map((r) => this.deprecate(r, e));
  }
  getLegacySelectorForTargetName(e) {
    const t = `${this.identifier}.${e}`;
    return ie(this.schema.targetAttribute, t);
  }
  deprecate(e, t) {
    if (e) {
      const { identifier: r } = this, i = this.schema.targetAttribute, n = this.schema.targetAttributeForScope(r);
      this.guide.warn(e, `target:${t}`, `Please replace ${i}="${r}.${t}" with ${n}="${t}". The ${i} attribute is deprecated and will be removed in a future version of Stimulus.`);
    }
    return e;
  }
  get guide() {
    return this.scope.guide;
  }
}
class wr {
  constructor(e, t) {
    this.scope = e, this.controllerElement = t;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get schema() {
    return this.scope.schema;
  }
  has(e) {
    return this.find(e) != null;
  }
  find(...e) {
    return e.reduce((t, r) => t || this.findOutlet(r), void 0);
  }
  findAll(...e) {
    return e.reduce((t, r) => [...t, ...this.findAllOutlets(r)], []);
  }
  getSelectorForOutletName(e) {
    const t = this.schema.outletAttributeForScope(this.identifier, e);
    return this.controllerElement.getAttribute(t);
  }
  findOutlet(e) {
    const t = this.getSelectorForOutletName(e);
    if (t)
      return this.findElement(t, e);
  }
  findAllOutlets(e) {
    const t = this.getSelectorForOutletName(e);
    return t ? this.findAllElements(t, e) : [];
  }
  findElement(e, t) {
    return this.scope.queryElements(e).filter((i) => this.matchesElement(i, e, t))[0];
  }
  findAllElements(e, t) {
    return this.scope.queryElements(e).filter((i) => this.matchesElement(i, e, t));
  }
  matchesElement(e, t, r) {
    const i = e.getAttribute(this.scope.schema.controllerAttribute) || "";
    return e.matches(t) && i.split(" ").includes(r);
  }
}
class pe {
  constructor(e, t, r, i) {
    this.targets = new yr(this), this.classes = new gr(this), this.data = new vr(this), this.containsElement = (n) => n.closest(this.controllerSelector) === this.element, this.schema = e, this.element = t, this.identifier = r, this.guide = new br(i), this.outlets = new wr(this.documentScope, t);
  }
  findElement(e) {
    return this.element.matches(e) ? this.element : this.queryElements(e).find(this.containsElement);
  }
  findAllElements(e) {
    return [
      ...this.element.matches(e) ? [this.element] : [],
      ...this.queryElements(e).filter(this.containsElement)
    ];
  }
  queryElements(e) {
    return Array.from(this.element.querySelectorAll(e));
  }
  get controllerSelector() {
    return ie(this.schema.controllerAttribute, this.identifier);
  }
  get isDocumentScope() {
    return this.element === document.documentElement;
  }
  get documentScope() {
    return this.isDocumentScope ? this : new pe(this.schema, document.documentElement, this.identifier, this.guide.logger);
  }
}
class Er {
  constructor(e, t, r) {
    this.element = e, this.schema = t, this.delegate = r, this.valueListObserver = new Ne(this.element, this.controllerAttribute, this), this.scopesByIdentifierByElement = /* @__PURE__ */ new WeakMap(), this.scopeReferenceCounts = /* @__PURE__ */ new WeakMap();
  }
  start() {
    this.valueListObserver.start();
  }
  stop() {
    this.valueListObserver.stop();
  }
  get controllerAttribute() {
    return this.schema.controllerAttribute;
  }
  parseValueForToken(e) {
    const { element: t, content: r } = e, i = this.fetchScopesByIdentifierForElement(t);
    let n = i.get(r);
    return n || (n = this.delegate.createScopeForElementAndIdentifier(t, r), i.set(r, n)), n;
  }
  elementMatchedValue(e, t) {
    const r = (this.scopeReferenceCounts.get(t) || 0) + 1;
    this.scopeReferenceCounts.set(t, r), r == 1 && this.delegate.scopeConnected(t);
  }
  elementUnmatchedValue(e, t) {
    const r = this.scopeReferenceCounts.get(t);
    r && (this.scopeReferenceCounts.set(t, r - 1), r == 1 && this.delegate.scopeDisconnected(t));
  }
  fetchScopesByIdentifierForElement(e) {
    let t = this.scopesByIdentifierByElement.get(e);
    return t || (t = /* @__PURE__ */ new Map(), this.scopesByIdentifierByElement.set(e, t)), t;
  }
}
class Sr {
  constructor(e) {
    this.application = e, this.scopeObserver = new Er(this.element, this.schema, this), this.scopesByIdentifier = new H(), this.modulesByIdentifier = /* @__PURE__ */ new Map();
  }
  get element() {
    return this.application.element;
  }
  get schema() {
    return this.application.schema;
  }
  get logger() {
    return this.application.logger;
  }
  get controllerAttribute() {
    return this.schema.controllerAttribute;
  }
  get modules() {
    return Array.from(this.modulesByIdentifier.values());
  }
  get contexts() {
    return this.modules.reduce((e, t) => e.concat(t.contexts), []);
  }
  start() {
    this.scopeObserver.start();
  }
  stop() {
    this.scopeObserver.stop();
  }
  loadDefinition(e) {
    this.unloadIdentifier(e.identifier);
    const t = new fr(this.application, e);
    this.connectModule(t);
    const r = e.controllerConstructor.afterLoad;
    r && r(e.identifier, this.application);
  }
  unloadIdentifier(e) {
    const t = this.modulesByIdentifier.get(e);
    t && this.disconnectModule(t);
  }
  getContextForElementAndIdentifier(e, t) {
    const r = this.modulesByIdentifier.get(t);
    if (r)
      return r.contexts.find((i) => i.element == e);
  }
  handleError(e, t, r) {
    this.application.handleError(e, t, r);
  }
  createScopeForElementAndIdentifier(e, t) {
    return new pe(this.schema, e, t, this.logger);
  }
  scopeConnected(e) {
    this.scopesByIdentifier.add(e.identifier, e);
    const t = this.modulesByIdentifier.get(e.identifier);
    t && t.connectContextForScope(e);
  }
  scopeDisconnected(e) {
    this.scopesByIdentifier.delete(e.identifier, e);
    const t = this.modulesByIdentifier.get(e.identifier);
    t && t.disconnectContextForScope(e);
  }
  connectModule(e) {
    this.modulesByIdentifier.set(e.identifier, e), this.scopesByIdentifier.getValuesForKey(e.identifier).forEach((r) => e.connectContextForScope(r));
  }
  disconnectModule(e) {
    this.modulesByIdentifier.delete(e.identifier), this.scopesByIdentifier.getValuesForKey(e.identifier).forEach((r) => e.disconnectContextForScope(r));
  }
}
const Tr = {
  controllerAttribute: "data-controller",
  actionAttribute: "data-action",
  targetAttribute: "data-target",
  targetAttributeForScope: (s) => `data-${s}-target`,
  outletAttributeForScope: (s, e) => `data-${s}-${e}-outlet`,
  keyMappings: Object.assign(Object.assign({ enter: "Enter", tab: "Tab", esc: "Escape", space: " ", up: "ArrowUp", down: "ArrowDown", left: "ArrowLeft", right: "ArrowRight", home: "Home", end: "End" }, Se("abcdefghijklmnopqrstuvwxyz".split("").map((s) => [s, s]))), Se("0123456789".split("").map((s) => [s, s])))
};
function Se(s) {
  return s.reduce((e, [t, r]) => Object.assign(Object.assign({}, e), { [t]: r }), {});
}
class Ar {
  constructor(e = document.documentElement, t = Tr) {
    this.logger = console, this.debug = !1, this.logDebugActivity = (r, i, n = {}) => {
      this.debug && this.logFormattedMessage(r, i, n);
    }, this.element = e, this.schema = t, this.dispatcher = new Ot(this), this.router = new Sr(this), this.actionDescriptorFilters = Object.assign({}, It);
  }
  static start(e, t) {
    const r = new this(e, t);
    return r.start(), r;
  }
  async start() {
    await Cr(), this.logDebugActivity("application", "starting"), this.dispatcher.start(), this.router.start(), this.logDebugActivity("application", "start");
  }
  stop() {
    this.logDebugActivity("application", "stopping"), this.dispatcher.stop(), this.router.stop(), this.logDebugActivity("application", "stop");
  }
  register(e, t) {
    this.load({ identifier: e, controllerConstructor: t });
  }
  registerActionOption(e, t) {
    this.actionDescriptorFilters[e] = t;
  }
  load(e, ...t) {
    (Array.isArray(e) ? e : [e, ...t]).forEach((i) => {
      i.controllerConstructor.shouldLoad && this.router.loadDefinition(i);
    });
  }
  unload(e, ...t) {
    (Array.isArray(e) ? e : [e, ...t]).forEach((i) => this.router.unloadIdentifier(i));
  }
  get controllers() {
    return this.router.contexts.map((e) => e.controller);
  }
  getControllerForElementAndIdentifier(e, t) {
    const r = this.router.getContextForElementAndIdentifier(e, t);
    return r ? r.controller : null;
  }
  handleError(e, t, r) {
    var i;
    this.logger.error(`%s

%o

%o`, t, e, r), (i = window.onerror) === null || i === void 0 || i.call(window, t, "", 0, 0, e);
  }
  logFormattedMessage(e, t, r = {}) {
    r = Object.assign({ application: this }, r), this.logger.groupCollapsed(`${e} #${t}`), this.logger.log("details:", Object.assign({}, r)), this.logger.groupEnd();
  }
}
function Cr() {
  return new Promise((s) => {
    document.readyState == "loading" ? document.addEventListener("DOMContentLoaded", () => s()) : s();
  });
}
function Lr(s) {
  return J(s, "classes").reduce((t, r) => Object.assign(t, kr(r)), {});
}
function kr(s) {
  return {
    [`${s}Class`]: {
      get() {
        const { classes: e } = this;
        if (e.has(s))
          return e.get(s);
        {
          const t = e.getAttributeName(s);
          throw new Error(`Missing attribute "${t}"`);
        }
      }
    },
    [`${s}Classes`]: {
      get() {
        return this.classes.getAll(s);
      }
    },
    [`has${z(s)}Class`]: {
      get() {
        return this.classes.has(s);
      }
    }
  };
}
function Rr(s) {
  return J(s, "outlets").reduce((t, r) => Object.assign(t, Mr(r)), {});
}
function Mr(s) {
  const e = se(s);
  return {
    [`${e}Outlet`]: {
      get() {
        const t = this.outlets.find(s);
        if (t) {
          const r = this.application.getControllerForElementAndIdentifier(t, s);
          if (r)
            return r;
          throw new Error(`Missing "data-controller=${s}" attribute on outlet element for "${this.identifier}" controller`);
        }
        throw new Error(`Missing outlet element "${s}" for "${this.identifier}" controller`);
      }
    },
    [`${e}Outlets`]: {
      get() {
        const t = this.outlets.findAll(s);
        return t.length > 0 ? t.map((r) => {
          const i = this.application.getControllerForElementAndIdentifier(r, s);
          if (i)
            return i;
          console.warn(`The provided outlet element is missing the outlet controller "${s}" for "${this.identifier}"`, r);
        }).filter((r) => r) : [];
      }
    },
    [`${e}OutletElement`]: {
      get() {
        const t = this.outlets.find(s);
        if (t)
          return t;
        throw new Error(`Missing outlet element "${s}" for "${this.identifier}" controller`);
      }
    },
    [`${e}OutletElements`]: {
      get() {
        return this.outlets.findAll(s);
      }
    },
    [`has${z(e)}Outlet`]: {
      get() {
        return this.outlets.has(s);
      }
    }
  };
}
function Pr(s) {
  return J(s, "targets").reduce((t, r) => Object.assign(t, Fr(r)), {});
}
function Fr(s) {
  return {
    [`${s}Target`]: {
      get() {
        const e = this.targets.find(s);
        if (e)
          return e;
        throw new Error(`Missing target element "${s}" for "${this.identifier}" controller`);
      }
    },
    [`${s}Targets`]: {
      get() {
        return this.targets.findAll(s);
      }
    },
    [`has${z(s)}Target`]: {
      get() {
        return this.targets.has(s);
      }
    }
  };
}
function Or(s) {
  const e = rr(s, "values"), t = {
    valueDescriptorMap: {
      get() {
        return e.reduce((r, i) => {
          const n = je(i, this.identifier), o = this.data.getAttributeNameForKey(n.key);
          return Object.assign(r, { [o]: n });
        }, {});
      }
    }
  };
  return e.reduce((r, i) => Object.assign(r, Ir(i)), t);
}
function Ir(s, e) {
  const t = je(s, e), { key: r, name: i, reader: n, writer: o } = t;
  return {
    [i]: {
      get() {
        const c = this.data.get(r);
        return c !== null ? n(c) : t.defaultValue;
      },
      set(c) {
        c === void 0 ? this.data.delete(r) : this.data.set(r, o(c));
      }
    },
    [`has${z(i)}`]: {
      get() {
        return this.data.has(r) || t.hasCustomDefaultValue;
      }
    }
  };
}
function je([s, e], t) {
  return qr({
    controller: t,
    token: s,
    typeDefinition: e
  });
}
function me(s) {
  switch (s) {
    case Array:
      return "array";
    case Boolean:
      return "boolean";
    case Number:
      return "number";
    case Object:
      return "object";
    case String:
      return "string";
  }
}
function W(s) {
  switch (typeof s) {
    case "boolean":
      return "boolean";
    case "number":
      return "number";
    case "string":
      return "string";
  }
  if (Array.isArray(s))
    return "array";
  if (Object.prototype.toString.call(s) === "[object Object]")
    return "object";
}
function xr(s) {
  const e = me(s.typeObject.type);
  if (!e)
    return;
  const t = W(s.typeObject.default);
  if (e !== t) {
    const r = s.controller ? `${s.controller}.${s.token}` : s.token;
    throw new Error(`The specified default value for the Stimulus Value "${r}" must match the defined type "${e}". The provided default value of "${s.typeObject.default}" is of type "${t}".`);
  }
  return e;
}
function Br(s) {
  const e = xr({
    controller: s.controller,
    token: s.token,
    typeObject: s.typeDefinition
  }), t = W(s.typeDefinition), r = me(s.typeDefinition), i = e || t || r;
  if (i)
    return i;
  const n = s.controller ? `${s.controller}.${s.typeDefinition}` : s.token;
  throw new Error(`Unknown value type "${n}" for "${s.token}" value`);
}
function Dr(s) {
  const e = me(s);
  if (e)
    return Hr[e];
  const t = s.default;
  return t !== void 0 ? t : s;
}
function qr(s) {
  const e = `${Be(s.token)}-value`, t = Br(s);
  return {
    type: t,
    key: e,
    name: de(e),
    get defaultValue() {
      return Dr(s.typeDefinition);
    },
    get hasCustomDefaultValue() {
      return W(s.typeDefinition) !== void 0;
    },
    reader: Nr[t],
    writer: Te[t] || Te.default
  };
}
const Hr = {
  get array() {
    return [];
  },
  boolean: !1,
  number: 0,
  get object() {
    return {};
  },
  string: ""
}, Nr = {
  array(s) {
    const e = JSON.parse(s);
    if (!Array.isArray(e))
      throw new TypeError(`expected value of type "array" but instead got value "${s}" of type "${W(e)}"`);
    return e;
  },
  boolean(s) {
    return !(s == "0" || String(s).toLowerCase() == "false");
  },
  number(s) {
    return Number(s);
  },
  object(s) {
    const e = JSON.parse(s);
    if (e === null || typeof e != "object" || Array.isArray(e))
      throw new TypeError(`expected value of type "object" but instead got value "${s}" of type "${W(e)}"`);
    return e;
  },
  string(s) {
    return s;
  }
}, Te = {
  default: Vr,
  array: Ae,
  object: Ae
};
function Ae(s) {
  return JSON.stringify(s);
}
function Vr(s) {
  return `${s}`;
}
let A = class {
  constructor(e) {
    this.context = e;
  }
  static get shouldLoad() {
    return !0;
  }
  static afterLoad(e, t) {
  }
  get application() {
    return this.context.application;
  }
  get scope() {
    return this.context.scope;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get targets() {
    return this.scope.targets;
  }
  get outlets() {
    return this.scope.outlets;
  }
  get classes() {
    return this.scope.classes;
  }
  get data() {
    return this.scope.data;
  }
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  dispatch(e, { target: t = this.element, detail: r = {}, prefix: i = this.identifier, bubbles: n = !0, cancelable: o = !0 } = {}) {
    const c = i ? `${i}:${e}` : e, a = new CustomEvent(c, { detail: r, bubbles: n, cancelable: o });
    return t.dispatchEvent(a), a;
  }
};
A.blessings = [
  Lr,
  Pr,
  Or,
  Rr
];
A.targets = [];
A.outlets = [];
A.values = {};
class y extends A {
  createTarget(e, t = {}) {
    const r = t.element || "div", i = t.htmlOptions || {}, n = t.data || {}, o = t.innerHTML || "", c = document.createElement(r);
    return Object.keys(n).forEach((a) => {
      c.setAttribute(`data-${a}`, n[a]);
    }), Object.keys(i).forEach((a) => {
      c.setAttribute(a, i[a]);
    }), c.setAttribute(`data-${this.identifier}-target`, `${e}`), c.innerHTML = o, c;
  }
  /**
   * Get instance of remote controller
   * if element id not passed, will be eq to controller name
   * @param controller
   * @param id
   */
  connectRemote(e, t = null) {
    const r = t || e, i = document.getElementById(r);
    return this.application.getControllerForElementAndIdentifier(i, e);
  }
}
const $e = (s) => s.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) !== null, jr = (s, e) => {
  const t = document.createElement("a");
  t.href = s, t.setAttribute("download", e), t.click();
};
(function() {
  if (window.Reflect === void 0 || window.customElements === void 0 || window.customElements.polyfillWrapFlushCallback)
    return;
  const s = HTMLElement, e = {
    HTMLElement: function() {
      return Reflect.construct(s, [], this.constructor);
    }
  };
  window.HTMLElement = e.HTMLElement, HTMLElement.prototype = s.prototype, HTMLElement.prototype.constructor = HTMLElement, Object.setPrototypeOf(HTMLElement, s);
})();
(function(s) {
  if (typeof s.requestSubmit == "function") return;
  s.requestSubmit = function(r) {
    r ? (e(r, this), r.click()) : (r = document.createElement("input"), r.type = "submit", r.hidden = !0, this.appendChild(r), r.click(), this.removeChild(r));
  };
  function e(r, i) {
    r instanceof HTMLElement || t(TypeError, "parameter 1 is not of type 'HTMLElement'"), r.type == "submit" || t(TypeError, "The specified element is not a submit button"), r.form == i || t(DOMException, "The specified element is not owned by this form element", "NotFoundError");
  }
  function t(r, i, n) {
    throw new r("Failed to execute 'requestSubmit' on 'HTMLFormElement': " + i + ".", n);
  }
})(HTMLFormElement.prototype);
const Ue = /* @__PURE__ */ new WeakMap();
function $r(s) {
  const e = s instanceof Element ? s : s instanceof Node ? s.parentElement : null, t = e ? e.closest("input, button") : null;
  return (t == null ? void 0 : t.type) == "submit" ? t : null;
}
function Ur(s) {
  const e = $r(s.target);
  e && e.form && Ue.set(e.form, e);
}
(function() {
  if ("submitter" in Event.prototype)
    return;
  let s;
  if ("SubmitEvent" in window && /Apple Computer/.test(navigator.vendor))
    s = window.SubmitEvent.prototype;
  else {
    if ("SubmitEvent" in window)
      return;
    s = window.Event.prototype;
  }
  addEventListener("click", Ur, !0), Object.defineProperty(s, "submitter", {
    get() {
      if (this.type == "submit" && this.target instanceof HTMLFormElement)
        return Ue.get(this.target);
    }
  });
})();
var I;
(function(s) {
  s.eager = "eager", s.lazy = "lazy";
})(I || (I = {}));
class D extends HTMLElement {
  constructor() {
    super(), this.loaded = Promise.resolve(), this.delegate = new D.delegateConstructor(this);
  }
  static get observedAttributes() {
    return ["disabled", "loading", "src"];
  }
  connectedCallback() {
    this.delegate.connect();
  }
  disconnectedCallback() {
    this.delegate.disconnect();
  }
  reload() {
    const { src: e } = this;
    this.src = null, this.src = e;
  }
  attributeChangedCallback(e) {
    e == "loading" ? this.delegate.loadingStyleChanged() : e == "src" ? this.delegate.sourceURLChanged() : this.delegate.disabledChanged();
  }
  get src() {
    return this.getAttribute("src");
  }
  set src(e) {
    e ? this.setAttribute("src", e) : this.removeAttribute("src");
  }
  get loading() {
    return Wr(this.getAttribute("loading") || "");
  }
  set loading(e) {
    e ? this.setAttribute("loading", e) : this.removeAttribute("loading");
  }
  get disabled() {
    return this.hasAttribute("disabled");
  }
  set disabled(e) {
    e ? this.setAttribute("disabled", "") : this.removeAttribute("disabled");
  }
  get autoscroll() {
    return this.hasAttribute("autoscroll");
  }
  set autoscroll(e) {
    e ? this.setAttribute("autoscroll", "") : this.removeAttribute("autoscroll");
  }
  get complete() {
    return !this.delegate.isLoading;
  }
  get isActive() {
    return this.ownerDocument === document && !this.isPreview;
  }
  get isPreview() {
    var e, t;
    return (t = (e = this.ownerDocument) === null || e === void 0 ? void 0 : e.documentElement) === null || t === void 0 ? void 0 : t.hasAttribute("data-turbo-preview");
  }
}
function Wr(s) {
  switch (s.toLowerCase()) {
    case "lazy":
      return I.lazy;
    default:
      return I.eager;
  }
}
function S(s) {
  return new URL(s.toString(), document.baseURI);
}
function N(s) {
  let e;
  if (s.hash)
    return s.hash.slice(1);
  if (e = s.href.match(/#(.*)$/))
    return e[1];
}
function fe(s, e) {
  const t = (e == null ? void 0 : e.getAttribute("formaction")) || s.getAttribute("action") || s.action;
  return S(t);
}
function _r(s) {
  return (Gr(s).match(/\.[^.]*$/) || [])[0] || "";
}
function Kr(s) {
  return !!_r(s).match(/^(?:|\.(?:htm|html|xhtml))$/);
}
function zr(s, e) {
  const t = Qr(e);
  return s.href === S(t).href || s.href.startsWith(t);
}
function _(s, e) {
  return zr(s, e) && Kr(s);
}
function ne(s) {
  const e = N(s);
  return e != null ? s.href.slice(0, -(e.length + 1)) : s.href;
}
function Y(s) {
  return ne(s);
}
function Jr(s, e) {
  return S(s).href == S(e).href;
}
function Xr(s) {
  return s.pathname.split("/").slice(1);
}
function Gr(s) {
  return Xr(s).slice(-1)[0];
}
function Qr(s) {
  return Yr(s.origin + s.pathname);
}
function Yr(s) {
  return s.endsWith("/") ? s : s + "/";
}
let We = class {
  constructor(e) {
    this.response = e;
  }
  get succeeded() {
    return this.response.ok;
  }
  get failed() {
    return !this.succeeded;
  }
  get clientError() {
    return this.statusCode >= 400 && this.statusCode <= 499;
  }
  get serverError() {
    return this.statusCode >= 500 && this.statusCode <= 599;
  }
  get redirected() {
    return this.response.redirected;
  }
  get location() {
    return S(this.response.url);
  }
  get isHTML() {
    return this.contentType && this.contentType.match(/^(?:text\/([^\s;,]+\b)?html|application\/xhtml\+xml)\b/);
  }
  get statusCode() {
    return this.response.status;
  }
  get contentType() {
    return this.header("Content-Type");
  }
  get responseText() {
    return this.response.clone().text();
  }
  get responseHTML() {
    return this.isHTML ? this.response.clone().text() : Promise.resolve(void 0);
  }
  header(e) {
    return this.response.headers.get(e);
  }
};
function T(s, { target: e, cancelable: t, detail: r } = {}) {
  const i = new CustomEvent(s, { cancelable: t, bubbles: !0, detail: r });
  return e && e.isConnected ? e.dispatchEvent(i) : document.documentElement.dispatchEvent(i), i;
}
function Z() {
  return new Promise((s) => requestAnimationFrame(() => s()));
}
function Zr() {
  return new Promise((s) => setTimeout(() => s(), 0));
}
function es() {
  return Promise.resolve();
}
function _e(s = "") {
  return new DOMParser().parseFromString(s, "text/html");
}
function Ke(s, ...e) {
  const t = ts(s, e).replace(/^\n/, "").split(`
`), r = t[0].match(/^\s+/), i = r ? r[0].length : 0;
  return t.map((n) => n.slice(i)).join(`
`);
}
function ts(s, e) {
  return s.reduce((t, r, i) => {
    const n = e[i] == null ? "" : e[i];
    return t + r + n;
  }, "");
}
function K() {
  return Array.apply(null, { length: 36 }).map((s, e) => e == 8 || e == 13 || e == 18 || e == 23 ? "-" : e == 14 ? "4" : e == 19 ? (Math.floor(Math.random() * 4) + 8).toString(16) : Math.floor(Math.random() * 15).toString(16)).join("");
}
function ee(s, ...e) {
  for (const t of e.map((r) => r == null ? void 0 : r.getAttribute(s)))
    if (typeof t == "string")
      return t;
  return null;
}
function oe(...s) {
  for (const e of s)
    e.localName == "turbo-frame" && e.setAttribute("busy", ""), e.setAttribute("aria-busy", "true");
}
function ae(...s) {
  for (const e of s)
    e.localName == "turbo-frame" && e.removeAttribute("busy"), e.removeAttribute("aria-busy");
}
var w;
(function(s) {
  s[s.get = 0] = "get", s[s.post = 1] = "post", s[s.put = 2] = "put", s[s.patch = 3] = "patch", s[s.delete = 4] = "delete";
})(w || (w = {}));
function rs(s) {
  switch (s.toLowerCase()) {
    case "get":
      return w.get;
    case "post":
      return w.post;
    case "put":
      return w.put;
    case "patch":
      return w.patch;
    case "delete":
      return w.delete;
  }
}
let ge = class {
  constructor(e, t, r, i = new URLSearchParams(), n = null) {
    this.abortController = new AbortController(), this.resolveRequestPromise = (o) => {
    }, this.delegate = e, this.method = t, this.headers = this.defaultHeaders, this.body = i, this.url = r, this.target = n;
  }
  get location() {
    return this.url;
  }
  get params() {
    return this.url.searchParams;
  }
  get entries() {
    return this.body ? Array.from(this.body.entries()) : [];
  }
  cancel() {
    this.abortController.abort();
  }
  async perform() {
    var e, t;
    const { fetchOptions: r } = this;
    (t = (e = this.delegate).prepareHeadersForRequest) === null || t === void 0 || t.call(e, this.headers, this), await this.allowRequestToBeIntercepted(r);
    try {
      this.delegate.requestStarted(this);
      const i = await fetch(this.url.href, r);
      return await this.receive(i);
    } catch (i) {
      if (i.name !== "AbortError")
        throw this.delegate.requestErrored(this, i), i;
    } finally {
      this.delegate.requestFinished(this);
    }
  }
  async receive(e) {
    const t = new We(e);
    return T("turbo:before-fetch-response", { cancelable: !0, detail: { fetchResponse: t }, target: this.target }).defaultPrevented ? this.delegate.requestPreventedHandlingResponse(this, t) : t.succeeded ? this.delegate.requestSucceededWithResponse(this, t) : this.delegate.requestFailedWithResponse(this, t), t;
  }
  get fetchOptions() {
    var e;
    return {
      method: w[this.method].toUpperCase(),
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      body: this.isIdempotent ? null : this.body,
      signal: this.abortSignal,
      referrer: (e = this.delegate.referrer) === null || e === void 0 ? void 0 : e.href
    };
  }
  get defaultHeaders() {
    return {
      Accept: "text/html, application/xhtml+xml"
    };
  }
  get isIdempotent() {
    return this.method == w.get;
  }
  get abortSignal() {
    return this.abortController.signal;
  }
  async allowRequestToBeIntercepted(e) {
    const t = new Promise((i) => this.resolveRequestPromise = i);
    T("turbo:before-fetch-request", {
      cancelable: !0,
      detail: {
        fetchOptions: e,
        url: this.url,
        resume: this.resolveRequestPromise
      },
      target: this.target
    }).defaultPrevented && await t;
  }
};
class ss {
  constructor(e, t) {
    this.started = !1, this.intersect = (r) => {
      const i = r.slice(-1)[0];
      i != null && i.isIntersecting && this.delegate.elementAppearedInViewport(this.element);
    }, this.delegate = e, this.element = t, this.intersectionObserver = new IntersectionObserver(this.intersect);
  }
  start() {
    this.started || (this.started = !0, this.intersectionObserver.observe(this.element));
  }
  stop() {
    this.started && (this.started = !1, this.intersectionObserver.unobserve(this.element));
  }
}
class X {
  constructor(e) {
    this.templateElement = document.createElement("template"), this.templateElement.innerHTML = e;
  }
  static wrap(e) {
    return typeof e == "string" ? new this(e) : e;
  }
  get fragment() {
    const e = document.createDocumentFragment();
    for (const t of this.foreignElements)
      e.appendChild(document.importNode(t, !0));
    return e;
  }
  get foreignElements() {
    return this.templateChildren.reduce((e, t) => t.tagName.toLowerCase() == "turbo-stream" ? [...e, t] : e, []);
  }
  get templateChildren() {
    return Array.from(this.templateElement.content.children);
  }
}
X.contentType = "text/vnd.turbo-stream.html";
var F;
(function(s) {
  s[s.initialized = 0] = "initialized", s[s.requesting = 1] = "requesting", s[s.waiting = 2] = "waiting", s[s.receiving = 3] = "receiving", s[s.stopping = 4] = "stopping", s[s.stopped = 5] = "stopped";
})(F || (F = {}));
var O;
(function(s) {
  s.urlEncoded = "application/x-www-form-urlencoded", s.multipart = "multipart/form-data", s.plain = "text/plain";
})(O || (O = {}));
function is(s) {
  switch (s.toLowerCase()) {
    case O.multipart:
      return O.multipart;
    case O.plain:
      return O.plain;
    default:
      return O.urlEncoded;
  }
}
class G {
  constructor(e, t, r, i = !1) {
    this.state = F.initialized, this.delegate = e, this.formElement = t, this.submitter = r, this.formData = ns(t, r), this.location = S(this.action), this.method == w.get && cs(this.location, [...this.body.entries()]), this.fetchRequest = new ge(this, this.method, this.location, this.body, this.formElement), this.mustRedirect = i;
  }
  static confirmMethod(e, t) {
    return confirm(e);
  }
  get method() {
    var e;
    const t = ((e = this.submitter) === null || e === void 0 ? void 0 : e.getAttribute("formmethod")) || this.formElement.getAttribute("method") || "";
    return rs(t.toLowerCase()) || w.get;
  }
  get action() {
    var e;
    const t = typeof this.formElement.action == "string" ? this.formElement.action : null;
    return ((e = this.submitter) === null || e === void 0 ? void 0 : e.getAttribute("formaction")) || this.formElement.getAttribute("action") || t || "";
  }
  get body() {
    return this.enctype == O.urlEncoded || this.method == w.get ? new URLSearchParams(this.stringFormData) : this.formData;
  }
  get enctype() {
    var e;
    return is(((e = this.submitter) === null || e === void 0 ? void 0 : e.getAttribute("formenctype")) || this.formElement.enctype);
  }
  get isIdempotent() {
    return this.fetchRequest.isIdempotent;
  }
  get stringFormData() {
    return [...this.formData].reduce((e, [t, r]) => e.concat(typeof r == "string" ? [[t, r]] : []), []);
  }
  get confirmationMessage() {
    return this.formElement.getAttribute("data-turbo-confirm");
  }
  get needsConfirmation() {
    return this.confirmationMessage !== null;
  }
  async start() {
    const { initialized: e, requesting: t } = F;
    if (!(this.needsConfirmation && !G.confirmMethod(this.confirmationMessage, this.formElement)) && this.state == e)
      return this.state = t, this.fetchRequest.perform();
  }
  stop() {
    const { stopping: e, stopped: t } = F;
    if (this.state != e && this.state != t)
      return this.state = e, this.fetchRequest.cancel(), !0;
  }
  prepareHeadersForRequest(e, t) {
    if (!t.isIdempotent) {
      const r = os(Ce("csrf-param")) || Ce("csrf-token");
      r && (e["X-CSRF-Token"] = r), e.Accept = [X.contentType, e.Accept].join(", ");
    }
  }
  requestStarted(e) {
    var t;
    this.state = F.waiting, (t = this.submitter) === null || t === void 0 || t.setAttribute("disabled", ""), T("turbo:submit-start", { target: this.formElement, detail: { formSubmission: this } }), this.delegate.formSubmissionStarted(this);
  }
  requestPreventedHandlingResponse(e, t) {
    this.result = { success: t.succeeded, fetchResponse: t };
  }
  requestSucceededWithResponse(e, t) {
    if (t.clientError || t.serverError)
      this.delegate.formSubmissionFailedWithResponse(this, t);
    else if (this.requestMustRedirect(e) && as(t)) {
      const r = new Error("Form responses must redirect to another location");
      this.delegate.formSubmissionErrored(this, r);
    } else
      this.state = F.receiving, this.result = { success: !0, fetchResponse: t }, this.delegate.formSubmissionSucceededWithResponse(this, t);
  }
  requestFailedWithResponse(e, t) {
    this.result = { success: !1, fetchResponse: t }, this.delegate.formSubmissionFailedWithResponse(this, t);
  }
  requestErrored(e, t) {
    this.result = { success: !1, error: t }, this.delegate.formSubmissionErrored(this, t);
  }
  requestFinished(e) {
    var t;
    this.state = F.stopped, (t = this.submitter) === null || t === void 0 || t.removeAttribute("disabled"), T("turbo:submit-end", { target: this.formElement, detail: Object.assign({ formSubmission: this }, this.result) }), this.delegate.formSubmissionFinished(this);
  }
  requestMustRedirect(e) {
    return !e.isIdempotent && this.mustRedirect;
  }
}
function ns(s, e) {
  const t = new FormData(s), r = e == null ? void 0 : e.getAttribute("name"), i = e == null ? void 0 : e.getAttribute("value");
  return r && i != null && t.get(r) != i && t.append(r, i), t;
}
function os(s) {
  if (s != null) {
    const t = (document.cookie ? document.cookie.split("; ") : []).find((r) => r.startsWith(s));
    if (t) {
      const r = t.split("=").slice(1).join("=");
      return r ? decodeURIComponent(r) : void 0;
    }
  }
}
function Ce(s) {
  const e = document.querySelector(`meta[name="${s}"]`);
  return e && e.content;
}
function as(s) {
  return s.statusCode == 200 && !s.redirected;
}
function cs(s, e) {
  const t = new URLSearchParams();
  for (const [r, i] of e)
    i instanceof File || t.append(r, i);
  return s.search = t.toString(), s;
}
class te {
  constructor(e) {
    this.element = e;
  }
  get children() {
    return [...this.element.children];
  }
  hasAnchor(e) {
    return this.getElementForAnchor(e) != null;
  }
  getElementForAnchor(e) {
    return e ? this.element.querySelector(`[id='${e}'], a[name='${e}']`) : null;
  }
  get isConnected() {
    return this.element.isConnected;
  }
  get firstAutofocusableElement() {
    return this.element.querySelector("[autofocus]");
  }
  get permanentElements() {
    return [...this.element.querySelectorAll("[id][data-turbo-permanent]")];
  }
  getPermanentElementById(e) {
    return this.element.querySelector(`#${e}[data-turbo-permanent]`);
  }
  getPermanentElementMapForSnapshot(e) {
    const t = {};
    for (const r of this.permanentElements) {
      const { id: i } = r, n = e.getPermanentElementById(i);
      n && (t[i] = [r, n]);
    }
    return t;
  }
}
class ze {
  constructor(e, t) {
    this.submitBubbled = (r) => {
      const i = r.target;
      if (!r.defaultPrevented && i instanceof HTMLFormElement && i.closest("turbo-frame, html") == this.element) {
        const n = r.submitter || void 0;
        ((n == null ? void 0 : n.getAttribute("formmethod")) || i.method) != "dialog" && this.delegate.shouldInterceptFormSubmission(i, n) && (r.preventDefault(), r.stopImmediatePropagation(), this.delegate.formSubmissionIntercepted(i, n));
      }
    }, this.delegate = e, this.element = t;
  }
  start() {
    this.element.addEventListener("submit", this.submitBubbled);
  }
  stop() {
    this.element.removeEventListener("submit", this.submitBubbled);
  }
}
class Je {
  constructor(e, t) {
    this.resolveRenderPromise = (r) => {
    }, this.resolveInterceptionPromise = (r) => {
    }, this.delegate = e, this.element = t;
  }
  scrollToAnchor(e) {
    const t = this.snapshot.getElementForAnchor(e);
    t ? (this.scrollToElement(t), this.focusElement(t)) : this.scrollToPosition({ x: 0, y: 0 });
  }
  scrollToAnchorFromLocation(e) {
    this.scrollToAnchor(N(e));
  }
  scrollToElement(e) {
    e.scrollIntoView();
  }
  focusElement(e) {
    e instanceof HTMLElement && (e.hasAttribute("tabindex") ? e.focus() : (e.setAttribute("tabindex", "-1"), e.focus(), e.removeAttribute("tabindex")));
  }
  scrollToPosition({ x: e, y: t }) {
    this.scrollRoot.scrollTo(e, t);
  }
  scrollToTop() {
    this.scrollToPosition({ x: 0, y: 0 });
  }
  get scrollRoot() {
    return window;
  }
  async render(e) {
    const { isPreview: t, shouldRender: r, newSnapshot: i } = e;
    if (r)
      try {
        this.renderPromise = new Promise((c) => this.resolveRenderPromise = c), this.renderer = e, this.prepareToRenderSnapshot(e);
        const n = new Promise((c) => this.resolveInterceptionPromise = c);
        this.delegate.allowsImmediateRender(i, this.resolveInterceptionPromise) || await n, await this.renderSnapshot(e), this.delegate.viewRenderedSnapshot(i, t), this.finishRenderingSnapshot(e);
      } finally {
        delete this.renderer, this.resolveRenderPromise(void 0), delete this.renderPromise;
      }
    else
      this.invalidate();
  }
  invalidate() {
    this.delegate.viewInvalidated();
  }
  prepareToRenderSnapshot(e) {
    this.markAsPreview(e.isPreview), e.prepareToRender();
  }
  markAsPreview(e) {
    e ? this.element.setAttribute("data-turbo-preview", "") : this.element.removeAttribute("data-turbo-preview");
  }
  async renderSnapshot(e) {
    await e.render();
  }
  finishRenderingSnapshot(e) {
    e.finishRendering();
  }
}
class ls extends Je {
  invalidate() {
    this.element.innerHTML = "";
  }
  get snapshot() {
    return new te(this.element);
  }
}
class Xe {
  constructor(e, t) {
    this.clickBubbled = (r) => {
      this.respondsToEventTarget(r.target) ? this.clickEvent = r : delete this.clickEvent;
    }, this.linkClicked = (r) => {
      this.clickEvent && this.respondsToEventTarget(r.target) && r.target instanceof Element && this.delegate.shouldInterceptLinkClick(r.target, r.detail.url) && (this.clickEvent.preventDefault(), r.preventDefault(), this.delegate.linkClickIntercepted(r.target, r.detail.url)), delete this.clickEvent;
    }, this.willVisit = () => {
      delete this.clickEvent;
    }, this.delegate = e, this.element = t;
  }
  start() {
    this.element.addEventListener("click", this.clickBubbled), document.addEventListener("turbo:click", this.linkClicked), document.addEventListener("turbo:before-visit", this.willVisit);
  }
  stop() {
    this.element.removeEventListener("click", this.clickBubbled), document.removeEventListener("turbo:click", this.linkClicked), document.removeEventListener("turbo:before-visit", this.willVisit);
  }
  respondsToEventTarget(e) {
    const t = e instanceof Element ? e : e instanceof Node ? e.parentElement : null;
    return t && t.closest("turbo-frame, html") == this.element;
  }
}
class hs {
  constructor(e) {
    this.permanentElementMap = e;
  }
  static preservingPermanentElements(e, t) {
    const r = new this(e);
    r.enter(), t(), r.leave();
  }
  enter() {
    for (const e in this.permanentElementMap) {
      const [, t] = this.permanentElementMap[e];
      this.replaceNewPermanentElementWithPlaceholder(t);
    }
  }
  leave() {
    for (const e in this.permanentElementMap) {
      const [t] = this.permanentElementMap[e];
      this.replaceCurrentPermanentElementWithClone(t), this.replacePlaceholderWithPermanentElement(t);
    }
  }
  replaceNewPermanentElementWithPlaceholder(e) {
    const t = us(e);
    e.replaceWith(t);
  }
  replaceCurrentPermanentElementWithClone(e) {
    const t = e.cloneNode(!0);
    e.replaceWith(t);
  }
  replacePlaceholderWithPermanentElement(e) {
    const t = this.getPlaceholderById(e.id);
    t == null || t.replaceWith(e);
  }
  getPlaceholderById(e) {
    return this.placeholders.find((t) => t.content == e);
  }
  get placeholders() {
    return [...document.querySelectorAll("meta[name=turbo-permanent-placeholder][content]")];
  }
}
function us(s) {
  const e = document.createElement("meta");
  return e.setAttribute("name", "turbo-permanent-placeholder"), e.setAttribute("content", s.id), e;
}
class ve {
  constructor(e, t, r, i = !0) {
    this.currentSnapshot = e, this.newSnapshot = t, this.isPreview = r, this.willRender = i, this.promise = new Promise((n, o) => this.resolvingFunctions = { resolve: n, reject: o });
  }
  get shouldRender() {
    return !0;
  }
  prepareToRender() {
  }
  finishRendering() {
    this.resolvingFunctions && (this.resolvingFunctions.resolve(), delete this.resolvingFunctions);
  }
  createScriptElement(e) {
    if (e.getAttribute("data-turbo-eval") == "false")
      return e;
    {
      const t = document.createElement("script");
      return this.cspNonce && (t.nonce = this.cspNonce), t.textContent = e.textContent, t.async = !1, ds(t, e), t;
    }
  }
  preservingPermanentElements(e) {
    hs.preservingPermanentElements(this.permanentElementMap, e);
  }
  focusFirstAutofocusableElement() {
    const e = this.connectedSnapshot.firstAutofocusableElement;
    ps(e) && e.focus();
  }
  get connectedSnapshot() {
    return this.newSnapshot.isConnected ? this.newSnapshot : this.currentSnapshot;
  }
  get currentElement() {
    return this.currentSnapshot.element;
  }
  get newElement() {
    return this.newSnapshot.element;
  }
  get permanentElementMap() {
    return this.currentSnapshot.getPermanentElementMapForSnapshot(this.newSnapshot);
  }
  get cspNonce() {
    var e;
    return (e = document.head.querySelector('meta[name="csp-nonce"]')) === null || e === void 0 ? void 0 : e.getAttribute("content");
  }
}
function ds(s, e) {
  for (const { name: t, value: r } of [...e.attributes])
    s.setAttribute(t, r);
}
function ps(s) {
  return s && typeof s.focus == "function";
}
class ms extends ve {
  get shouldRender() {
    return !0;
  }
  async render() {
    await Z(), this.preservingPermanentElements(() => {
      this.loadFrameElement();
    }), this.scrollFrameIntoView(), await Z(), this.focusFirstAutofocusableElement(), await Z(), this.activateScriptElements();
  }
  loadFrameElement() {
    var e;
    const t = document.createRange();
    t.selectNodeContents(this.currentElement), t.deleteContents();
    const r = this.newElement, i = (e = r.ownerDocument) === null || e === void 0 ? void 0 : e.createRange();
    i && (i.selectNodeContents(r), this.currentElement.appendChild(i.extractContents()));
  }
  scrollFrameIntoView() {
    if (this.currentElement.autoscroll || this.newElement.autoscroll) {
      const e = this.currentElement.firstElementChild, t = fs(this.currentElement.getAttribute("data-autoscroll-block"), "end");
      if (e)
        return e.scrollIntoView({ block: t }), !0;
    }
    return !1;
  }
  activateScriptElements() {
    for (const e of this.newScriptElements) {
      const t = this.createScriptElement(e);
      e.replaceWith(t);
    }
  }
  get newScriptElements() {
    return this.currentElement.querySelectorAll("script");
  }
}
function fs(s, e) {
  return s == "end" || s == "start" || s == "center" || s == "nearest" ? s : e;
}
class M {
  constructor() {
    this.hiding = !1, this.value = 0, this.visible = !1, this.trickle = () => {
      this.setValue(this.value + Math.random() / 100);
    }, this.stylesheetElement = this.createStylesheetElement(), this.progressElement = this.createProgressElement(), this.installStylesheetElement(), this.setValue(0);
  }
  static get defaultCSS() {
    return Ke`
      .turbo-progress-bar {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        height: 3px;
        background: #0076ff;
        z-index: 9999;
        transition:
          width ${M.animationDuration}ms ease-out,
          opacity ${M.animationDuration / 2}ms ${M.animationDuration / 2}ms ease-in;
        transform: translate3d(0, 0, 0);
      }
    `;
  }
  show() {
    this.visible || (this.visible = !0, this.installProgressElement(), this.startTrickling());
  }
  hide() {
    this.visible && !this.hiding && (this.hiding = !0, this.fadeProgressElement(() => {
      this.uninstallProgressElement(), this.stopTrickling(), this.visible = !1, this.hiding = !1;
    }));
  }
  setValue(e) {
    this.value = e, this.refresh();
  }
  installStylesheetElement() {
    document.head.insertBefore(this.stylesheetElement, document.head.firstChild);
  }
  installProgressElement() {
    this.progressElement.style.width = "0", this.progressElement.style.opacity = "1", document.documentElement.insertBefore(this.progressElement, document.body), this.refresh();
  }
  fadeProgressElement(e) {
    this.progressElement.style.opacity = "0", setTimeout(e, M.animationDuration * 1.5);
  }
  uninstallProgressElement() {
    this.progressElement.parentNode && document.documentElement.removeChild(this.progressElement);
  }
  startTrickling() {
    this.trickleInterval || (this.trickleInterval = window.setInterval(this.trickle, M.animationDuration));
  }
  stopTrickling() {
    window.clearInterval(this.trickleInterval), delete this.trickleInterval;
  }
  refresh() {
    requestAnimationFrame(() => {
      this.progressElement.style.width = `${10 + this.value * 90}%`;
    });
  }
  createStylesheetElement() {
    const e = document.createElement("style");
    return e.type = "text/css", e.textContent = M.defaultCSS, e;
  }
  createProgressElement() {
    const e = document.createElement("div");
    return e.className = "turbo-progress-bar", e;
  }
}
M.animationDuration = 300;
class gs extends te {
  constructor() {
    super(...arguments), this.detailsByOuterHTML = this.children.filter((e) => !ws(e)).map((e) => Ts(e)).reduce((e, t) => {
      const { outerHTML: r } = t, i = r in e ? e[r] : {
        type: vs(t),
        tracked: bs(t),
        elements: []
      };
      return Object.assign(Object.assign({}, e), { [r]: Object.assign(Object.assign({}, i), { elements: [...i.elements, t] }) });
    }, {});
  }
  get trackedElementSignature() {
    return Object.keys(this.detailsByOuterHTML).filter((e) => this.detailsByOuterHTML[e].tracked).join("");
  }
  getScriptElementsNotInSnapshot(e) {
    return this.getElementsMatchingTypeNotInSnapshot("script", e);
  }
  getStylesheetElementsNotInSnapshot(e) {
    return this.getElementsMatchingTypeNotInSnapshot("stylesheet", e);
  }
  getElementsMatchingTypeNotInSnapshot(e, t) {
    return Object.keys(this.detailsByOuterHTML).filter((r) => !(r in t.detailsByOuterHTML)).map((r) => this.detailsByOuterHTML[r]).filter(({ type: r }) => r == e).map(({ elements: [r] }) => r);
  }
  get provisionalElements() {
    return Object.keys(this.detailsByOuterHTML).reduce((e, t) => {
      const { type: r, tracked: i, elements: n } = this.detailsByOuterHTML[t];
      return r == null && !i ? [...e, ...n] : n.length > 1 ? [...e, ...n.slice(1)] : e;
    }, []);
  }
  getMetaValue(e) {
    const t = this.findMetaElementByName(e);
    return t ? t.getAttribute("content") : null;
  }
  findMetaElementByName(e) {
    return Object.keys(this.detailsByOuterHTML).reduce((t, r) => {
      const { elements: [i] } = this.detailsByOuterHTML[r];
      return Ss(i, e) ? i : t;
    }, void 0);
  }
}
function vs(s) {
  if (ys(s))
    return "script";
  if (Es(s))
    return "stylesheet";
}
function bs(s) {
  return s.getAttribute("data-turbo-track") == "reload";
}
function ys(s) {
  return s.tagName.toLowerCase() == "script";
}
function ws(s) {
  return s.tagName.toLowerCase() == "noscript";
}
function Es(s) {
  const e = s.tagName.toLowerCase();
  return e == "style" || e == "link" && s.getAttribute("rel") == "stylesheet";
}
function Ss(s, e) {
  return s.tagName.toLowerCase() == "meta" && s.getAttribute("name") == e;
}
function Ts(s) {
  return s.hasAttribute("nonce") && s.setAttribute("nonce", ""), s;
}
class x extends te {
  constructor(e, t) {
    super(e), this.headSnapshot = t;
  }
  static fromHTMLString(e = "") {
    return this.fromDocument(_e(e));
  }
  static fromElement(e) {
    return this.fromDocument(e.ownerDocument);
  }
  static fromDocument({ head: e, body: t }) {
    return new this(t, new gs(e));
  }
  clone() {
    return new x(this.element.cloneNode(!0), this.headSnapshot);
  }
  get headElement() {
    return this.headSnapshot.element;
  }
  get rootLocation() {
    var e;
    const t = (e = this.getSetting("root")) !== null && e !== void 0 ? e : "/";
    return S(t);
  }
  get cacheControlValue() {
    return this.getSetting("cache-control");
  }
  get isPreviewable() {
    return this.cacheControlValue != "no-preview";
  }
  get isCacheable() {
    return this.cacheControlValue != "no-cache";
  }
  get isVisitable() {
    return this.getSetting("visit-control") != "reload";
  }
  getSetting(e) {
    return this.headSnapshot.getMetaValue(`turbo-${e}`);
  }
}
var j;
(function(s) {
  s.visitStart = "visitStart", s.requestStart = "requestStart", s.requestEnd = "requestEnd", s.visitEnd = "visitEnd";
})(j || (j = {}));
var L;
(function(s) {
  s.initialized = "initialized", s.started = "started", s.canceled = "canceled", s.failed = "failed", s.completed = "completed";
})(L || (L = {}));
const As = {
  action: "advance",
  historyChanged: !1,
  visitCachedSnapshot: () => {
  },
  willRender: !0
};
var B;
(function(s) {
  s[s.networkFailure = 0] = "networkFailure", s[s.timeoutFailure = -1] = "timeoutFailure", s[s.contentTypeMismatch = -2] = "contentTypeMismatch";
})(B || (B = {}));
class Cs {
  constructor(e, t, r, i = {}) {
    this.identifier = K(), this.timingMetrics = {}, this.followedRedirect = !1, this.historyChanged = !1, this.scrolled = !1, this.snapshotCached = !1, this.state = L.initialized, this.delegate = e, this.location = t, this.restorationIdentifier = r || K();
    const { action: n, historyChanged: o, referrer: c, snapshotHTML: a, response: l, visitCachedSnapshot: h, willRender: u } = Object.assign(Object.assign({}, As), i);
    this.action = n, this.historyChanged = o, this.referrer = c, this.snapshotHTML = a, this.response = l, this.isSamePage = this.delegate.locationWithActionIsSamePage(this.location, this.action), this.visitCachedSnapshot = h, this.willRender = u, this.scrolled = !u;
  }
  get adapter() {
    return this.delegate.adapter;
  }
  get view() {
    return this.delegate.view;
  }
  get history() {
    return this.delegate.history;
  }
  get restorationData() {
    return this.history.getRestorationDataForIdentifier(this.restorationIdentifier);
  }
  get silent() {
    return this.isSamePage;
  }
  start() {
    this.state == L.initialized && (this.recordTimingMetric(j.visitStart), this.state = L.started, this.adapter.visitStarted(this), this.delegate.visitStarted(this));
  }
  cancel() {
    this.state == L.started && (this.request && this.request.cancel(), this.cancelRender(), this.state = L.canceled);
  }
  complete() {
    this.state == L.started && (this.recordTimingMetric(j.visitEnd), this.state = L.completed, this.adapter.visitCompleted(this), this.delegate.visitCompleted(this), this.followRedirect());
  }
  fail() {
    this.state == L.started && (this.state = L.failed, this.adapter.visitFailed(this));
  }
  changeHistory() {
    var e;
    if (!this.historyChanged) {
      const t = this.location.href === ((e = this.referrer) === null || e === void 0 ? void 0 : e.href) ? "replace" : this.action, r = this.getHistoryMethodForAction(t);
      this.history.update(r, this.location, this.restorationIdentifier), this.historyChanged = !0;
    }
  }
  issueRequest() {
    this.hasPreloadedResponse() ? this.simulateRequest() : this.shouldIssueRequest() && !this.request && (this.request = new ge(this, w.get, this.location), this.request.perform());
  }
  simulateRequest() {
    this.response && (this.startRequest(), this.recordResponse(), this.finishRequest());
  }
  startRequest() {
    this.recordTimingMetric(j.requestStart), this.adapter.visitRequestStarted(this);
  }
  recordResponse(e = this.response) {
    if (this.response = e, e) {
      const { statusCode: t } = e;
      Le(t) ? this.adapter.visitRequestCompleted(this) : this.adapter.visitRequestFailedWithStatusCode(this, t);
    }
  }
  finishRequest() {
    this.recordTimingMetric(j.requestEnd), this.adapter.visitRequestFinished(this);
  }
  loadResponse() {
    if (this.response) {
      const { statusCode: e, responseHTML: t } = this.response;
      this.render(async () => {
        this.cacheSnapshot(), this.view.renderPromise && await this.view.renderPromise, Le(e) && t != null ? (await this.view.renderPage(x.fromHTMLString(t), !1, this.willRender), this.adapter.visitRendered(this), this.complete()) : (await this.view.renderError(x.fromHTMLString(t)), this.adapter.visitRendered(this), this.fail());
      });
    }
  }
  getCachedSnapshot() {
    const e = this.view.getCachedSnapshotForLocation(this.location) || this.getPreloadedSnapshot();
    if (e && (!N(this.location) || e.hasAnchor(N(this.location))) && (this.action == "restore" || e.isPreviewable))
      return e;
  }
  getPreloadedSnapshot() {
    if (this.snapshotHTML)
      return x.fromHTMLString(this.snapshotHTML);
  }
  hasCachedSnapshot() {
    return this.getCachedSnapshot() != null;
  }
  loadCachedSnapshot() {
    const e = this.getCachedSnapshot();
    if (e) {
      const t = this.shouldIssueRequest();
      this.render(async () => {
        this.cacheSnapshot(), this.isSamePage ? this.adapter.visitRendered(this) : (this.view.renderPromise && await this.view.renderPromise, await this.view.renderPage(e, t, this.willRender), this.adapter.visitRendered(this), t || this.complete());
      });
    }
  }
  followRedirect() {
    var e;
    this.redirectedToLocation && !this.followedRedirect && (!((e = this.response) === null || e === void 0) && e.redirected) && (this.adapter.visitProposedToLocation(this.redirectedToLocation, {
      action: "replace",
      response: this.response
    }), this.followedRedirect = !0);
  }
  goToSamePageAnchor() {
    this.isSamePage && this.render(async () => {
      this.cacheSnapshot(), this.adapter.visitRendered(this);
    });
  }
  requestStarted() {
    this.startRequest();
  }
  requestPreventedHandlingResponse(e, t) {
  }
  async requestSucceededWithResponse(e, t) {
    const r = await t.responseHTML, { redirected: i, statusCode: n } = t;
    r == null ? this.recordResponse({ statusCode: B.contentTypeMismatch, redirected: i }) : (this.redirectedToLocation = t.redirected ? t.location : void 0, this.recordResponse({ statusCode: n, responseHTML: r, redirected: i }));
  }
  async requestFailedWithResponse(e, t) {
    const r = await t.responseHTML, { redirected: i, statusCode: n } = t;
    r == null ? this.recordResponse({ statusCode: B.contentTypeMismatch, redirected: i }) : this.recordResponse({ statusCode: n, responseHTML: r, redirected: i });
  }
  requestErrored(e, t) {
    this.recordResponse({ statusCode: B.networkFailure, redirected: !1 });
  }
  requestFinished() {
    this.finishRequest();
  }
  performScroll() {
    this.scrolled || (this.action == "restore" ? this.scrollToRestoredPosition() || this.scrollToAnchor() || this.view.scrollToTop() : this.scrollToAnchor() || this.view.scrollToTop(), this.isSamePage && this.delegate.visitScrolledToSamePageLocation(this.view.lastRenderedLocation, this.location), this.scrolled = !0);
  }
  scrollToRestoredPosition() {
    const { scrollPosition: e } = this.restorationData;
    if (e)
      return this.view.scrollToPosition(e), !0;
  }
  scrollToAnchor() {
    const e = N(this.location);
    if (e != null)
      return this.view.scrollToAnchor(e), !0;
  }
  recordTimingMetric(e) {
    this.timingMetrics[e] = (/* @__PURE__ */ new Date()).getTime();
  }
  getTimingMetrics() {
    return Object.assign({}, this.timingMetrics);
  }
  getHistoryMethodForAction(e) {
    switch (e) {
      case "replace":
        return history.replaceState;
      case "advance":
      case "restore":
        return history.pushState;
    }
  }
  hasPreloadedResponse() {
    return typeof this.response == "object";
  }
  shouldIssueRequest() {
    return this.isSamePage ? !1 : this.action == "restore" ? !this.hasCachedSnapshot() : this.willRender;
  }
  cacheSnapshot() {
    this.snapshotCached || (this.view.cacheSnapshot().then((e) => e && this.visitCachedSnapshot(e)), this.snapshotCached = !0);
  }
  async render(e) {
    this.cancelRender(), await new Promise((t) => {
      this.frame = requestAnimationFrame(() => t());
    }), await e(), delete this.frame, this.performScroll();
  }
  cancelRender() {
    this.frame && (cancelAnimationFrame(this.frame), delete this.frame);
  }
}
function Le(s) {
  return s >= 200 && s < 300;
}
class Ls {
  constructor(e) {
    this.progressBar = new M(), this.showProgressBar = () => {
      this.progressBar.show();
    }, this.session = e;
  }
  visitProposedToLocation(e, t) {
    this.navigator.startVisit(e, K(), t);
  }
  visitStarted(e) {
    e.loadCachedSnapshot(), e.issueRequest(), e.changeHistory(), e.goToSamePageAnchor();
  }
  visitRequestStarted(e) {
    this.progressBar.setValue(0), e.hasCachedSnapshot() || e.action != "restore" ? this.showVisitProgressBarAfterDelay() : this.showProgressBar();
  }
  visitRequestCompleted(e) {
    e.loadResponse();
  }
  visitRequestFailedWithStatusCode(e, t) {
    switch (t) {
      case B.networkFailure:
      case B.timeoutFailure:
      case B.contentTypeMismatch:
        return this.reload();
      default:
        return e.loadResponse();
    }
  }
  visitRequestFinished(e) {
    this.progressBar.setValue(1), this.hideVisitProgressBar();
  }
  visitCompleted(e) {
  }
  pageInvalidated() {
    this.reload();
  }
  visitFailed(e) {
  }
  visitRendered(e) {
  }
  formSubmissionStarted(e) {
    this.progressBar.setValue(0), this.showFormProgressBarAfterDelay();
  }
  formSubmissionFinished(e) {
    this.progressBar.setValue(1), this.hideFormProgressBar();
  }
  showVisitProgressBarAfterDelay() {
    this.visitProgressBarTimeout = window.setTimeout(this.showProgressBar, this.session.progressBarDelay);
  }
  hideVisitProgressBar() {
    this.progressBar.hide(), this.visitProgressBarTimeout != null && (window.clearTimeout(this.visitProgressBarTimeout), delete this.visitProgressBarTimeout);
  }
  showFormProgressBarAfterDelay() {
    this.formProgressBarTimeout == null && (this.formProgressBarTimeout = window.setTimeout(this.showProgressBar, this.session.progressBarDelay));
  }
  hideFormProgressBar() {
    this.progressBar.hide(), this.formProgressBarTimeout != null && (window.clearTimeout(this.formProgressBarTimeout), delete this.formProgressBarTimeout);
  }
  reload() {
    window.location.reload();
  }
  get navigator() {
    return this.session.navigator;
  }
}
class ks {
  constructor() {
    this.started = !1;
  }
  start() {
    this.started || (this.started = !0, addEventListener("turbo:before-cache", this.removeStaleElements, !1));
  }
  stop() {
    this.started && (this.started = !1, removeEventListener("turbo:before-cache", this.removeStaleElements, !1));
  }
  removeStaleElements() {
    const e = [...document.querySelectorAll('[data-turbo-cache="false"]')];
    for (const t of e)
      t.remove();
  }
}
class Rs {
  constructor(e) {
    this.started = !1, this.submitCaptured = () => {
      removeEventListener("submit", this.submitBubbled, !1), addEventListener("submit", this.submitBubbled, !1);
    }, this.submitBubbled = (t) => {
      if (!t.defaultPrevented) {
        const r = t.target instanceof HTMLFormElement ? t.target : void 0, i = t.submitter || void 0;
        r && ((i == null ? void 0 : i.getAttribute("formmethod")) || r.getAttribute("method")) != "dialog" && this.delegate.willSubmitForm(r, i) && (t.preventDefault(), this.delegate.formSubmitted(r, i));
      }
    }, this.delegate = e;
  }
  start() {
    this.started || (addEventListener("submit", this.submitCaptured, !0), this.started = !0);
  }
  stop() {
    this.started && (removeEventListener("submit", this.submitCaptured, !0), this.started = !1);
  }
}
class Ms {
  constructor(e) {
    this.element = e, this.linkInterceptor = new Xe(this, e), this.formInterceptor = new ze(this, e);
  }
  start() {
    this.linkInterceptor.start(), this.formInterceptor.start();
  }
  stop() {
    this.linkInterceptor.stop(), this.formInterceptor.stop();
  }
  shouldInterceptLinkClick(e, t) {
    return this.shouldRedirect(e);
  }
  linkClickIntercepted(e, t) {
    const r = this.findFrameElement(e);
    r && r.delegate.linkClickIntercepted(e, t);
  }
  shouldInterceptFormSubmission(e, t) {
    return this.shouldSubmit(e, t);
  }
  formSubmissionIntercepted(e, t) {
    const r = this.findFrameElement(e, t);
    r && (r.removeAttribute("reloadable"), r.delegate.formSubmissionIntercepted(e, t));
  }
  shouldSubmit(e, t) {
    var r;
    const i = fe(e, t), n = this.element.ownerDocument.querySelector('meta[name="turbo-root"]'), o = S((r = n == null ? void 0 : n.content) !== null && r !== void 0 ? r : "/");
    return this.shouldRedirect(e, t) && _(i, o);
  }
  shouldRedirect(e, t) {
    const r = this.findFrameElement(e, t);
    return r ? r != e.closest("turbo-frame") : !1;
  }
  findFrameElement(e, t) {
    const r = (t == null ? void 0 : t.getAttribute("data-turbo-frame")) || e.getAttribute("data-turbo-frame");
    if (r && r != "_top") {
      const i = this.element.querySelector(`#${r}:not([disabled])`);
      if (i instanceof D)
        return i;
    }
  }
}
class Ps {
  constructor(e) {
    this.restorationIdentifier = K(), this.restorationData = {}, this.started = !1, this.pageLoaded = !1, this.onPopState = (t) => {
      if (this.shouldHandlePopState()) {
        const { turbo: r } = t.state || {};
        if (r) {
          this.location = new URL(window.location.href);
          const { restorationIdentifier: i } = r;
          this.restorationIdentifier = i, this.delegate.historyPoppedToLocationWithRestorationIdentifier(this.location, i);
        }
      }
    }, this.onPageLoad = async (t) => {
      await es(), this.pageLoaded = !0;
    }, this.delegate = e;
  }
  start() {
    this.started || (addEventListener("popstate", this.onPopState, !1), addEventListener("load", this.onPageLoad, !1), this.started = !0, this.replace(new URL(window.location.href)));
  }
  stop() {
    this.started && (removeEventListener("popstate", this.onPopState, !1), removeEventListener("load", this.onPageLoad, !1), this.started = !1);
  }
  push(e, t) {
    this.update(history.pushState, e, t);
  }
  replace(e, t) {
    this.update(history.replaceState, e, t);
  }
  update(e, t, r = K()) {
    const i = { turbo: { restorationIdentifier: r } };
    e.call(history, i, "", t.href), this.location = t, this.restorationIdentifier = r;
  }
  getRestorationDataForIdentifier(e) {
    return this.restorationData[e] || {};
  }
  updateRestorationData(e) {
    const { restorationIdentifier: t } = this, r = this.restorationData[t];
    this.restorationData[t] = Object.assign(Object.assign({}, r), e);
  }
  assumeControlOfScrollRestoration() {
    var e;
    this.previousScrollRestoration || (this.previousScrollRestoration = (e = history.scrollRestoration) !== null && e !== void 0 ? e : "auto", history.scrollRestoration = "manual");
  }
  relinquishControlOfScrollRestoration() {
    this.previousScrollRestoration && (history.scrollRestoration = this.previousScrollRestoration, delete this.previousScrollRestoration);
  }
  shouldHandlePopState() {
    return this.pageIsLoaded();
  }
  pageIsLoaded() {
    return this.pageLoaded || document.readyState == "complete";
  }
}
class Fs {
  constructor(e) {
    this.started = !1, this.clickCaptured = () => {
      removeEventListener("click", this.clickBubbled, !1), addEventListener("click", this.clickBubbled, !1);
    }, this.clickBubbled = (t) => {
      if (this.clickEventIsSignificant(t)) {
        const r = t.composedPath && t.composedPath()[0] || t.target, i = this.findLinkFromClickTarget(r);
        if (i) {
          const n = this.getLocationForLink(i);
          this.delegate.willFollowLinkToLocation(i, n) && (t.preventDefault(), this.delegate.followedLinkToLocation(i, n));
        }
      }
    }, this.delegate = e;
  }
  start() {
    this.started || (addEventListener("click", this.clickCaptured, !0), this.started = !0);
  }
  stop() {
    this.started && (removeEventListener("click", this.clickCaptured, !0), this.started = !1);
  }
  clickEventIsSignificant(e) {
    return !(e.target && e.target.isContentEditable || e.defaultPrevented || e.which > 1 || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey);
  }
  findLinkFromClickTarget(e) {
    if (e instanceof Element)
      return e.closest("a[href]:not([target^=_]):not([download])");
  }
  getLocationForLink(e) {
    return S(e.getAttribute("href") || "");
  }
}
function be(s) {
  return s == "advance" || s == "replace" || s == "restore";
}
class Os {
  constructor(e) {
    this.delegate = e;
  }
  proposeVisit(e, t = {}) {
    this.delegate.allowsVisitingLocationWithAction(e, t.action) && (_(e, this.view.snapshot.rootLocation) ? this.delegate.visitProposedToLocation(e, t) : window.location.href = e.toString());
  }
  startVisit(e, t, r = {}) {
    this.stop(), this.currentVisit = new Cs(this, S(e), t, Object.assign({ referrer: this.location }, r)), this.currentVisit.start();
  }
  submitForm(e, t) {
    this.stop(), this.formSubmission = new G(this, e, t, !0), this.formSubmission.start();
  }
  stop() {
    this.formSubmission && (this.formSubmission.stop(), delete this.formSubmission), this.currentVisit && (this.currentVisit.cancel(), delete this.currentVisit);
  }
  get adapter() {
    return this.delegate.adapter;
  }
  get view() {
    return this.delegate.view;
  }
  get history() {
    return this.delegate.history;
  }
  formSubmissionStarted(e) {
    typeof this.adapter.formSubmissionStarted == "function" && this.adapter.formSubmissionStarted(e);
  }
  async formSubmissionSucceededWithResponse(e, t) {
    if (e == this.formSubmission) {
      const r = await t.responseHTML;
      if (r) {
        e.method != w.get && this.view.clearSnapshotCache();
        const { statusCode: i, redirected: n } = t, c = { action: this.getActionForFormSubmission(e), response: { statusCode: i, responseHTML: r, redirected: n } };
        this.proposeVisit(t.location, c);
      }
    }
  }
  async formSubmissionFailedWithResponse(e, t) {
    const r = await t.responseHTML;
    if (r) {
      const i = x.fromHTMLString(r);
      t.serverError ? await this.view.renderError(i) : await this.view.renderPage(i), this.view.scrollToTop(), this.view.clearSnapshotCache();
    }
  }
  formSubmissionErrored(e, t) {
    console.error(t);
  }
  formSubmissionFinished(e) {
    typeof this.adapter.formSubmissionFinished == "function" && this.adapter.formSubmissionFinished(e);
  }
  visitStarted(e) {
    this.delegate.visitStarted(e);
  }
  visitCompleted(e) {
    this.delegate.visitCompleted(e);
  }
  locationWithActionIsSamePage(e, t) {
    const r = N(e), i = N(this.view.lastRenderedLocation), n = t === "restore" && typeof r > "u";
    return t !== "replace" && ne(e) === ne(this.view.lastRenderedLocation) && (n || r != null && r !== i);
  }
  visitScrolledToSamePageLocation(e, t) {
    this.delegate.visitScrolledToSamePageLocation(e, t);
  }
  get location() {
    return this.history.location;
  }
  get restorationIdentifier() {
    return this.history.restorationIdentifier;
  }
  getActionForFormSubmission(e) {
    const { formElement: t, submitter: r } = e, i = ee("data-turbo-action", r, t);
    return be(i) ? i : "advance";
  }
}
var R;
(function(s) {
  s[s.initial = 0] = "initial", s[s.loading = 1] = "loading", s[s.interactive = 2] = "interactive", s[s.complete = 3] = "complete";
})(R || (R = {}));
class Is {
  constructor(e) {
    this.stage = R.initial, this.started = !1, this.interpretReadyState = () => {
      const { readyState: t } = this;
      t == "interactive" ? this.pageIsInteractive() : t == "complete" && this.pageIsComplete();
    }, this.pageWillUnload = () => {
      this.delegate.pageWillUnload();
    }, this.delegate = e;
  }
  start() {
    this.started || (this.stage == R.initial && (this.stage = R.loading), document.addEventListener("readystatechange", this.interpretReadyState, !1), addEventListener("pagehide", this.pageWillUnload, !1), this.started = !0);
  }
  stop() {
    this.started && (document.removeEventListener("readystatechange", this.interpretReadyState, !1), removeEventListener("pagehide", this.pageWillUnload, !1), this.started = !1);
  }
  pageIsInteractive() {
    this.stage == R.loading && (this.stage = R.interactive, this.delegate.pageBecameInteractive());
  }
  pageIsComplete() {
    this.pageIsInteractive(), this.stage == R.interactive && (this.stage = R.complete, this.delegate.pageLoaded());
  }
  get readyState() {
    return document.readyState;
  }
}
class xs {
  constructor(e) {
    this.started = !1, this.onScroll = () => {
      this.updatePosition({ x: window.pageXOffset, y: window.pageYOffset });
    }, this.delegate = e;
  }
  start() {
    this.started || (addEventListener("scroll", this.onScroll, !1), this.onScroll(), this.started = !0);
  }
  stop() {
    this.started && (removeEventListener("scroll", this.onScroll, !1), this.started = !1);
  }
  updatePosition(e) {
    this.delegate.scrollPositionChanged(e);
  }
}
class Bs {
  constructor(e) {
    this.sources = /* @__PURE__ */ new Set(), this.started = !1, this.inspectFetchResponse = (t) => {
      const r = Ds(t);
      r && qs(r) && (t.preventDefault(), this.receiveMessageResponse(r));
    }, this.receiveMessageEvent = (t) => {
      this.started && typeof t.data == "string" && this.receiveMessageHTML(t.data);
    }, this.delegate = e;
  }
  start() {
    this.started || (this.started = !0, addEventListener("turbo:before-fetch-response", this.inspectFetchResponse, !1));
  }
  stop() {
    this.started && (this.started = !1, removeEventListener("turbo:before-fetch-response", this.inspectFetchResponse, !1));
  }
  connectStreamSource(e) {
    this.streamSourceIsConnected(e) || (this.sources.add(e), e.addEventListener("message", this.receiveMessageEvent, !1));
  }
  disconnectStreamSource(e) {
    this.streamSourceIsConnected(e) && (this.sources.delete(e), e.removeEventListener("message", this.receiveMessageEvent, !1));
  }
  streamSourceIsConnected(e) {
    return this.sources.has(e);
  }
  async receiveMessageResponse(e) {
    const t = await e.responseHTML;
    t && this.receiveMessageHTML(t);
  }
  receiveMessageHTML(e) {
    this.delegate.receivedMessageFromStream(new X(e));
  }
}
function Ds(s) {
  var e;
  const t = (e = s.detail) === null || e === void 0 ? void 0 : e.fetchResponse;
  if (t instanceof We)
    return t;
}
function qs(s) {
  var e;
  return ((e = s.contentType) !== null && e !== void 0 ? e : "").startsWith(X.contentType);
}
class Hs extends ve {
  async render() {
    this.replaceHeadAndBody(), this.activateScriptElements();
  }
  replaceHeadAndBody() {
    const { documentElement: e, head: t, body: r } = document;
    e.replaceChild(this.newHead, t), e.replaceChild(this.newElement, r);
  }
  activateScriptElements() {
    for (const e of this.scriptElements) {
      const t = e.parentNode;
      if (t) {
        const r = this.createScriptElement(e);
        t.replaceChild(r, e);
      }
    }
  }
  get newHead() {
    return this.newSnapshot.headSnapshot.element;
  }
  get scriptElements() {
    return [...document.documentElement.querySelectorAll("script")];
  }
}
class Ge extends ve {
  get shouldRender() {
    return this.newSnapshot.isVisitable && this.trackedElementsAreIdentical;
  }
  prepareToRender() {
    this.mergeHead();
  }
  async render() {
    this.willRender && this.replaceBody();
  }
  finishRendering() {
    super.finishRendering(), this.isPreview || this.focusFirstAutofocusableElement();
  }
  get currentHeadSnapshot() {
    return this.currentSnapshot.headSnapshot;
  }
  get newHeadSnapshot() {
    return this.newSnapshot.headSnapshot;
  }
  get newElement() {
    return this.newSnapshot.element;
  }
  mergeHead() {
    this.copyNewHeadStylesheetElements(), this.copyNewHeadScriptElements(), this.removeCurrentHeadProvisionalElements(), this.copyNewHeadProvisionalElements();
  }
  replaceBody() {
    this.preservingPermanentElements(() => {
      this.activateNewBody(), this.assignNewBody();
    });
  }
  get trackedElementsAreIdentical() {
    return this.currentHeadSnapshot.trackedElementSignature == this.newHeadSnapshot.trackedElementSignature;
  }
  copyNewHeadStylesheetElements() {
    for (const e of this.newHeadStylesheetElements)
      document.head.appendChild(e);
  }
  copyNewHeadScriptElements() {
    for (const e of this.newHeadScriptElements)
      document.head.appendChild(this.createScriptElement(e));
  }
  removeCurrentHeadProvisionalElements() {
    for (const e of this.currentHeadProvisionalElements)
      document.head.removeChild(e);
  }
  copyNewHeadProvisionalElements() {
    for (const e of this.newHeadProvisionalElements)
      document.head.appendChild(e);
  }
  activateNewBody() {
    document.adoptNode(this.newElement), this.activateNewBodyScriptElements();
  }
  activateNewBodyScriptElements() {
    for (const e of this.newBodyScriptElements) {
      const t = this.createScriptElement(e);
      e.replaceWith(t);
    }
  }
  assignNewBody() {
    document.body && this.newElement instanceof HTMLBodyElement ? document.body.replaceWith(this.newElement) : document.documentElement.appendChild(this.newElement);
  }
  get newHeadStylesheetElements() {
    return this.newHeadSnapshot.getStylesheetElementsNotInSnapshot(this.currentHeadSnapshot);
  }
  get newHeadScriptElements() {
    return this.newHeadSnapshot.getScriptElementsNotInSnapshot(this.currentHeadSnapshot);
  }
  get currentHeadProvisionalElements() {
    return this.currentHeadSnapshot.provisionalElements;
  }
  get newHeadProvisionalElements() {
    return this.newHeadSnapshot.provisionalElements;
  }
  get newBodyScriptElements() {
    return this.newElement.querySelectorAll("script");
  }
}
class Ns {
  constructor(e) {
    this.keys = [], this.snapshots = {}, this.size = e;
  }
  has(e) {
    return Y(e) in this.snapshots;
  }
  get(e) {
    if (this.has(e)) {
      const t = this.read(e);
      return this.touch(e), t;
    }
  }
  put(e, t) {
    return this.write(e, t), this.touch(e), t;
  }
  clear() {
    this.snapshots = {};
  }
  read(e) {
    return this.snapshots[Y(e)];
  }
  write(e, t) {
    this.snapshots[Y(e)] = t;
  }
  touch(e) {
    const t = Y(e), r = this.keys.indexOf(t);
    r > -1 && this.keys.splice(r, 1), this.keys.unshift(t), this.trim();
  }
  trim() {
    for (const e of this.keys.splice(this.size))
      delete this.snapshots[e];
  }
}
class Vs extends Je {
  constructor() {
    super(...arguments), this.snapshotCache = new Ns(10), this.lastRenderedLocation = new URL(location.href);
  }
  renderPage(e, t = !1, r = !0) {
    const i = new Ge(this.snapshot, e, t, r);
    return this.render(i);
  }
  renderError(e) {
    const t = new Hs(this.snapshot, e, !1);
    return this.render(t);
  }
  clearSnapshotCache() {
    this.snapshotCache.clear();
  }
  async cacheSnapshot() {
    if (this.shouldCacheSnapshot) {
      this.delegate.viewWillCacheSnapshot();
      const { snapshot: e, lastRenderedLocation: t } = this;
      await Zr();
      const r = e.clone();
      return this.snapshotCache.put(t, r), r;
    }
  }
  getCachedSnapshotForLocation(e) {
    return this.snapshotCache.get(e);
  }
  get snapshot() {
    return x.fromElement(this.element);
  }
  get shouldCacheSnapshot() {
    return this.snapshot.isCacheable;
  }
}
class js {
  constructor() {
    this.navigator = new Os(this), this.history = new Ps(this), this.view = new Vs(this, document.documentElement), this.adapter = new Ls(this), this.pageObserver = new Is(this), this.cacheObserver = new ks(), this.linkClickObserver = new Fs(this), this.formSubmitObserver = new Rs(this), this.scrollObserver = new xs(this), this.streamObserver = new Bs(this), this.frameRedirector = new Ms(document.documentElement), this.drive = !0, this.enabled = !0, this.progressBarDelay = 500, this.started = !1;
  }
  start() {
    this.started || (this.pageObserver.start(), this.cacheObserver.start(), this.linkClickObserver.start(), this.formSubmitObserver.start(), this.scrollObserver.start(), this.streamObserver.start(), this.frameRedirector.start(), this.history.start(), this.started = !0, this.enabled = !0);
  }
  disable() {
    this.enabled = !1;
  }
  stop() {
    this.started && (this.pageObserver.stop(), this.cacheObserver.stop(), this.linkClickObserver.stop(), this.formSubmitObserver.stop(), this.scrollObserver.stop(), this.streamObserver.stop(), this.frameRedirector.stop(), this.history.stop(), this.started = !1);
  }
  registerAdapter(e) {
    this.adapter = e;
  }
  visit(e, t = {}) {
    this.navigator.proposeVisit(S(e), t);
  }
  connectStreamSource(e) {
    this.streamObserver.connectStreamSource(e);
  }
  disconnectStreamSource(e) {
    this.streamObserver.disconnectStreamSource(e);
  }
  renderStreamMessage(e) {
    document.documentElement.appendChild(X.wrap(e).fragment);
  }
  clearCache() {
    this.view.clearSnapshotCache();
  }
  setProgressBarDelay(e) {
    this.progressBarDelay = e;
  }
  get location() {
    return this.history.location;
  }
  get restorationIdentifier() {
    return this.history.restorationIdentifier;
  }
  historyPoppedToLocationWithRestorationIdentifier(e, t) {
    this.enabled ? this.navigator.startVisit(e, t, { action: "restore", historyChanged: !0 }) : this.adapter.pageInvalidated();
  }
  scrollPositionChanged(e) {
    this.history.updateRestorationData({ scrollPosition: e });
  }
  willFollowLinkToLocation(e, t) {
    return this.elementDriveEnabled(e) && _(t, this.snapshot.rootLocation) && this.applicationAllowsFollowingLinkToLocation(e, t);
  }
  followedLinkToLocation(e, t) {
    const r = this.getActionForLink(e);
    this.convertLinkWithMethodClickToFormSubmission(e) || this.visit(t.href, { action: r });
  }
  convertLinkWithMethodClickToFormSubmission(e) {
    const t = e.getAttribute("data-turbo-method");
    if (t) {
      const r = document.createElement("form");
      r.method = t, r.action = e.getAttribute("href") || "undefined", r.hidden = !0, e.hasAttribute("data-turbo-confirm") && r.setAttribute("data-turbo-confirm", e.getAttribute("data-turbo-confirm"));
      const i = this.getTargetFrameForLink(e);
      return i ? (r.setAttribute("data-turbo-frame", i), r.addEventListener("turbo:submit-start", () => r.remove())) : r.addEventListener("submit", () => r.remove()), document.body.appendChild(r), T("submit", { cancelable: !0, target: r });
    } else
      return !1;
  }
  allowsVisitingLocationWithAction(e, t) {
    return this.locationWithActionIsSamePage(e, t) || this.applicationAllowsVisitingLocation(e);
  }
  visitProposedToLocation(e, t) {
    ke(e), this.adapter.visitProposedToLocation(e, t);
  }
  visitStarted(e) {
    ke(e.location), e.silent || this.notifyApplicationAfterVisitingLocation(e.location, e.action);
  }
  visitCompleted(e) {
    this.notifyApplicationAfterPageLoad(e.getTimingMetrics());
  }
  locationWithActionIsSamePage(e, t) {
    return this.navigator.locationWithActionIsSamePage(e, t);
  }
  visitScrolledToSamePageLocation(e, t) {
    this.notifyApplicationAfterVisitingSamePageLocation(e, t);
  }
  willSubmitForm(e, t) {
    const r = fe(e, t);
    return this.elementDriveEnabled(e) && (!t || this.elementDriveEnabled(t)) && _(S(r), this.snapshot.rootLocation);
  }
  formSubmitted(e, t) {
    this.navigator.submitForm(e, t);
  }
  pageBecameInteractive() {
    this.view.lastRenderedLocation = this.location, this.notifyApplicationAfterPageLoad();
  }
  pageLoaded() {
    this.history.assumeControlOfScrollRestoration();
  }
  pageWillUnload() {
    this.history.relinquishControlOfScrollRestoration();
  }
  receivedMessageFromStream(e) {
    this.renderStreamMessage(e);
  }
  viewWillCacheSnapshot() {
    var e;
    !((e = this.navigator.currentVisit) === null || e === void 0) && e.silent || this.notifyApplicationBeforeCachingSnapshot();
  }
  allowsImmediateRender({ element: e }, t) {
    return !this.notifyApplicationBeforeRender(e, t).defaultPrevented;
  }
  viewRenderedSnapshot(e, t) {
    this.view.lastRenderedLocation = this.history.location, this.notifyApplicationAfterRender();
  }
  viewInvalidated() {
    this.adapter.pageInvalidated();
  }
  frameLoaded(e) {
    this.notifyApplicationAfterFrameLoad(e);
  }
  frameRendered(e, t) {
    this.notifyApplicationAfterFrameRender(e, t);
  }
  applicationAllowsFollowingLinkToLocation(e, t) {
    return !this.notifyApplicationAfterClickingLinkToLocation(e, t).defaultPrevented;
  }
  applicationAllowsVisitingLocation(e) {
    return !this.notifyApplicationBeforeVisitingLocation(e).defaultPrevented;
  }
  notifyApplicationAfterClickingLinkToLocation(e, t) {
    return T("turbo:click", { target: e, detail: { url: t.href }, cancelable: !0 });
  }
  notifyApplicationBeforeVisitingLocation(e) {
    return T("turbo:before-visit", { detail: { url: e.href }, cancelable: !0 });
  }
  notifyApplicationAfterVisitingLocation(e, t) {
    return oe(document.documentElement), T("turbo:visit", { detail: { url: e.href, action: t } });
  }
  notifyApplicationBeforeCachingSnapshot() {
    return T("turbo:before-cache");
  }
  notifyApplicationBeforeRender(e, t) {
    return T("turbo:before-render", { detail: { newBody: e, resume: t }, cancelable: !0 });
  }
  notifyApplicationAfterRender() {
    return T("turbo:render");
  }
  notifyApplicationAfterPageLoad(e = {}) {
    return ae(document.documentElement), T("turbo:load", { detail: { url: this.location.href, timing: e } });
  }
  notifyApplicationAfterVisitingSamePageLocation(e, t) {
    dispatchEvent(new HashChangeEvent("hashchange", { oldURL: e.toString(), newURL: t.toString() }));
  }
  notifyApplicationAfterFrameLoad(e) {
    return T("turbo:frame-load", { target: e });
  }
  notifyApplicationAfterFrameRender(e, t) {
    return T("turbo:frame-render", { detail: { fetchResponse: e }, target: t, cancelable: !0 });
  }
  elementDriveEnabled(e) {
    const t = e == null ? void 0 : e.closest("[data-turbo]");
    return this.drive ? t ? t.getAttribute("data-turbo") != "false" : !0 : t ? t.getAttribute("data-turbo") == "true" : !1;
  }
  getActionForLink(e) {
    const t = e.getAttribute("data-turbo-action");
    return be(t) ? t : "advance";
  }
  getTargetFrameForLink(e) {
    const t = e.getAttribute("data-turbo-frame");
    if (t)
      return t;
    {
      const r = e.closest("turbo-frame");
      if (r)
        return r.id;
    }
  }
  get snapshot() {
    return this.view.snapshot;
  }
}
function ke(s) {
  Object.defineProperties(s, $s);
}
const $s = {
  absoluteURL: {
    get() {
      return this.toString();
    }
  }
}, E = new js(), { navigator: Us } = E;
function Qe() {
  E.start();
}
function Ws(s) {
  E.registerAdapter(s);
}
function _s(s, e) {
  E.visit(s, e);
}
function Ye(s) {
  E.connectStreamSource(s);
}
function Ze(s) {
  E.disconnectStreamSource(s);
}
function Ks(s) {
  E.renderStreamMessage(s);
}
function zs() {
  E.clearCache();
}
function Js(s) {
  E.setProgressBarDelay(s);
}
function Xs(s) {
  G.confirmMethod = s;
}
var Gs = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  navigator: Us,
  session: E,
  PageRenderer: Ge,
  PageSnapshot: x,
  start: Qe,
  registerAdapter: Ws,
  visit: _s,
  connectStreamSource: Ye,
  disconnectStreamSource: Ze,
  renderStreamMessage: Ks,
  clearCache: zs,
  setProgressBarDelay: Js,
  setConfirmMethod: Xs
});
class Qs {
  constructor(e) {
    this.fetchResponseLoaded = (t) => {
    }, this.currentFetchRequest = null, this.resolveVisitPromise = () => {
    }, this.connected = !1, this.hasBeenLoaded = !1, this.settingSourceURL = !1, this.element = e, this.view = new ls(this, this.element), this.appearanceObserver = new ss(this, this.element), this.linkInterceptor = new Xe(this, this.element), this.formInterceptor = new ze(this, this.element);
  }
  connect() {
    this.connected || (this.connected = !0, this.reloadable = !1, this.loadingStyle == I.lazy && this.appearanceObserver.start(), this.linkInterceptor.start(), this.formInterceptor.start(), this.sourceURLChanged());
  }
  disconnect() {
    this.connected && (this.connected = !1, this.appearanceObserver.stop(), this.linkInterceptor.stop(), this.formInterceptor.stop());
  }
  disabledChanged() {
    this.loadingStyle == I.eager && this.loadSourceURL();
  }
  sourceURLChanged() {
    (this.loadingStyle == I.eager || this.hasBeenLoaded) && this.loadSourceURL();
  }
  loadingStyleChanged() {
    this.loadingStyle == I.lazy ? this.appearanceObserver.start() : (this.appearanceObserver.stop(), this.loadSourceURL());
  }
  async loadSourceURL() {
    if (!this.settingSourceURL && this.enabled && this.isActive && (this.reloadable || this.sourceURL != this.currentURL)) {
      const e = this.currentURL;
      if (this.currentURL = this.sourceURL, this.sourceURL)
        try {
          this.element.loaded = this.visit(S(this.sourceURL)), this.appearanceObserver.stop(), await this.element.loaded, this.hasBeenLoaded = !0;
        } catch (t) {
          throw this.currentURL = e, t;
        }
    }
  }
  async loadResponse(e) {
    (e.redirected || e.succeeded && e.isHTML) && (this.sourceURL = e.response.url);
    try {
      const t = await e.responseHTML;
      if (t) {
        const { body: r } = _e(t), i = new te(await this.extractForeignFrameElement(r)), n = new ms(this.view.snapshot, i, !1, !1);
        this.view.renderPromise && await this.view.renderPromise, await this.view.render(n), E.frameRendered(e, this.element), E.frameLoaded(this.element), this.fetchResponseLoaded(e);
      }
    } catch (t) {
      console.error(t), this.view.invalidate();
    } finally {
      this.fetchResponseLoaded = () => {
      };
    }
  }
  elementAppearedInViewport(e) {
    this.loadSourceURL();
  }
  shouldInterceptLinkClick(e, t) {
    return e.hasAttribute("data-turbo-method") ? !1 : this.shouldInterceptNavigation(e);
  }
  linkClickIntercepted(e, t) {
    this.reloadable = !0, this.navigateFrame(e, t);
  }
  shouldInterceptFormSubmission(e, t) {
    return this.shouldInterceptNavigation(e, t);
  }
  formSubmissionIntercepted(e, t) {
    this.formSubmission && this.formSubmission.stop(), this.reloadable = !1, this.formSubmission = new G(this, e, t);
    const { fetchRequest: r } = this.formSubmission;
    this.prepareHeadersForRequest(r.headers, r), this.formSubmission.start();
  }
  prepareHeadersForRequest(e, t) {
    e["Turbo-Frame"] = this.id;
  }
  requestStarted(e) {
    oe(this.element);
  }
  requestPreventedHandlingResponse(e, t) {
    this.resolveVisitPromise();
  }
  async requestSucceededWithResponse(e, t) {
    await this.loadResponse(t), this.resolveVisitPromise();
  }
  requestFailedWithResponse(e, t) {
    console.error(t), this.resolveVisitPromise();
  }
  requestErrored(e, t) {
    console.error(t), this.resolveVisitPromise();
  }
  requestFinished(e) {
    ae(this.element);
  }
  formSubmissionStarted({ formElement: e }) {
    oe(e, this.findFrameElement(e));
  }
  formSubmissionSucceededWithResponse(e, t) {
    const r = this.findFrameElement(e.formElement, e.submitter);
    this.proposeVisitIfNavigatedWithAction(r, e.formElement, e.submitter), r.delegate.loadResponse(t);
  }
  formSubmissionFailedWithResponse(e, t) {
    this.element.delegate.loadResponse(t);
  }
  formSubmissionErrored(e, t) {
    console.error(t);
  }
  formSubmissionFinished({ formElement: e }) {
    ae(e, this.findFrameElement(e));
  }
  allowsImmediateRender(e, t) {
    return !0;
  }
  viewRenderedSnapshot(e, t) {
  }
  viewInvalidated() {
  }
  async visit(e) {
    var t;
    const r = new ge(this, w.get, e, new URLSearchParams(), this.element);
    return (t = this.currentFetchRequest) === null || t === void 0 || t.cancel(), this.currentFetchRequest = r, new Promise((i) => {
      this.resolveVisitPromise = () => {
        this.resolveVisitPromise = () => {
        }, this.currentFetchRequest = null, i();
      }, r.perform();
    });
  }
  navigateFrame(e, t, r) {
    const i = this.findFrameElement(e, r);
    this.proposeVisitIfNavigatedWithAction(i, e, r), i.setAttribute("reloadable", ""), i.src = t;
  }
  proposeVisitIfNavigatedWithAction(e, t, r) {
    const i = ee("data-turbo-action", r, t, e);
    if (be(i)) {
      const { visitCachedSnapshot: n } = new Ys(e);
      e.delegate.fetchResponseLoaded = (o) => {
        if (e.src) {
          const { statusCode: c, redirected: a } = o, l = e.ownerDocument.documentElement.outerHTML, h = { statusCode: c, redirected: a, responseHTML: l };
          E.visit(e.src, { action: i, response: h, visitCachedSnapshot: n, willRender: !1 });
        }
      };
    }
  }
  findFrameElement(e, t) {
    var r;
    const i = ee("data-turbo-frame", t, e) || this.element.getAttribute("target");
    return (r = Re(i)) !== null && r !== void 0 ? r : this.element;
  }
  async extractForeignFrameElement(e) {
    let t;
    const r = CSS.escape(this.id);
    try {
      if (t = Me(e.querySelector(`turbo-frame#${r}`), this.currentURL))
        return t;
      if (t = Me(e.querySelector(`turbo-frame[src][recurse~=${r}]`), this.currentURL))
        return await t.loaded, await this.extractForeignFrameElement(t);
      console.error(`Response has no matching <turbo-frame id="${r}"> element`);
    } catch (i) {
      console.error(i);
    }
    return new D();
  }
  formActionIsVisitable(e, t) {
    const r = fe(e, t);
    return _(S(r), this.rootLocation);
  }
  shouldInterceptNavigation(e, t) {
    const r = ee("data-turbo-frame", t, e) || this.element.getAttribute("target");
    if (e instanceof HTMLFormElement && !this.formActionIsVisitable(e, t) || !this.enabled || r == "_top")
      return !1;
    if (r) {
      const i = Re(r);
      if (i)
        return !i.disabled;
    }
    return !(!E.elementDriveEnabled(e) || t && !E.elementDriveEnabled(t));
  }
  get id() {
    return this.element.id;
  }
  get enabled() {
    return !this.element.disabled;
  }
  get sourceURL() {
    if (this.element.src)
      return this.element.src;
  }
  get reloadable() {
    return this.findFrameElement(this.element).hasAttribute("reloadable");
  }
  set reloadable(e) {
    const t = this.findFrameElement(this.element);
    e ? t.setAttribute("reloadable", "") : t.removeAttribute("reloadable");
  }
  set sourceURL(e) {
    this.settingSourceURL = !0, this.element.src = e ?? null, this.currentURL = this.element.src, this.settingSourceURL = !1;
  }
  get loadingStyle() {
    return this.element.loading;
  }
  get isLoading() {
    return this.formSubmission !== void 0 || this.resolveVisitPromise() !== void 0;
  }
  get isActive() {
    return this.element.isActive && this.connected;
  }
  get rootLocation() {
    var e;
    const t = this.element.ownerDocument.querySelector('meta[name="turbo-root"]'), r = (e = t == null ? void 0 : t.content) !== null && e !== void 0 ? e : "/";
    return S(r);
  }
}
class Ys {
  constructor(e) {
    this.visitCachedSnapshot = ({ element: t }) => {
      var r;
      const { id: i, clone: n } = this;
      (r = t.querySelector("#" + i)) === null || r === void 0 || r.replaceWith(n);
    }, this.clone = e.cloneNode(!0), this.id = e.id;
  }
}
function Re(s) {
  if (s != null) {
    const e = document.getElementById(s);
    if (e instanceof D)
      return e;
  }
}
function Me(s, e) {
  if (s) {
    const t = s.getAttribute("src");
    if (t != null && e != null && Jr(t, e))
      throw new Error(`Matching <turbo-frame id="${s.id}"> element has a source URL which references itself`);
    if (s.ownerDocument !== document && (s = document.importNode(s, !0)), s instanceof D)
      return s.connectedCallback(), s.disconnectedCallback(), s;
  }
}
const Zs = {
  after() {
    this.targetElements.forEach((s) => {
      var e;
      return (e = s.parentElement) === null || e === void 0 ? void 0 : e.insertBefore(this.templateContent, s.nextSibling);
    });
  },
  append() {
    this.removeDuplicateTargetChildren(), this.targetElements.forEach((s) => s.append(this.templateContent));
  },
  before() {
    this.targetElements.forEach((s) => {
      var e;
      return (e = s.parentElement) === null || e === void 0 ? void 0 : e.insertBefore(this.templateContent, s);
    });
  },
  prepend() {
    this.removeDuplicateTargetChildren(), this.targetElements.forEach((s) => s.prepend(this.templateContent));
  },
  remove() {
    this.targetElements.forEach((s) => s.remove());
  },
  replace() {
    this.targetElements.forEach((s) => s.replaceWith(this.templateContent));
  },
  update() {
    this.targetElements.forEach((s) => {
      s.innerHTML = "", s.append(this.templateContent);
    });
  }
};
class ei extends HTMLElement {
  async connectedCallback() {
    try {
      await this.render();
    } catch (e) {
      console.error(e);
    } finally {
      this.disconnect();
    }
  }
  async render() {
    var e;
    return (e = this.renderPromise) !== null && e !== void 0 ? e : this.renderPromise = (async () => {
      this.dispatchEvent(this.beforeRenderEvent) && (await Z(), this.performAction());
    })();
  }
  disconnect() {
    try {
      this.remove();
    } catch {
    }
  }
  removeDuplicateTargetChildren() {
    this.duplicateChildren.forEach((e) => e.remove());
  }
  get duplicateChildren() {
    var e;
    const t = this.targetElements.flatMap((i) => [...i.children]).filter((i) => !!i.id), r = [...(e = this.templateContent) === null || e === void 0 ? void 0 : e.children].filter((i) => !!i.id).map((i) => i.id);
    return t.filter((i) => r.includes(i.id));
  }
  get performAction() {
    if (this.action) {
      const e = Zs[this.action];
      if (e)
        return e;
      this.raise("unknown action");
    }
    this.raise("action attribute is missing");
  }
  get targetElements() {
    if (this.target)
      return this.targetElementsById;
    if (this.targets)
      return this.targetElementsByQuery;
    this.raise("target or targets attribute is missing");
  }
  get templateContent() {
    return this.templateElement.content.cloneNode(!0);
  }
  get templateElement() {
    if (this.firstElementChild instanceof HTMLTemplateElement)
      return this.firstElementChild;
    this.raise("first child element must be a <template> element");
  }
  get action() {
    return this.getAttribute("action");
  }
  get target() {
    return this.getAttribute("target");
  }
  get targets() {
    return this.getAttribute("targets");
  }
  raise(e) {
    throw new Error(`${this.description}: ${e}`);
  }
  get description() {
    var e, t;
    return (t = ((e = this.outerHTML.match(/<[^>]+>/)) !== null && e !== void 0 ? e : [])[0]) !== null && t !== void 0 ? t : "<turbo-stream>";
  }
  get beforeRenderEvent() {
    return new CustomEvent("turbo:before-stream-render", { bubbles: !0, cancelable: !0 });
  }
  get targetElementsById() {
    var e;
    const t = (e = this.ownerDocument) === null || e === void 0 ? void 0 : e.getElementById(this.target);
    return t !== null ? [t] : [];
  }
  get targetElementsByQuery() {
    var e;
    const t = (e = this.ownerDocument) === null || e === void 0 ? void 0 : e.querySelectorAll(this.targets);
    return t.length !== 0 ? Array.prototype.slice.call(t) : [];
  }
}
D.delegateConstructor = Qs;
customElements.define("turbo-frame", D);
customElements.define("turbo-stream", ei);
(() => {
  let s = document.currentScript;
  if (s && !s.hasAttribute("data-turbo-suppress-warning")) {
    for (; s = s.parentElement; )
      if (s == document.body)
        return console.warn(Ke`
        You are loading Turbo from a <script> element inside the <body> element. This is probably not what you meant to do!

        Load your application’s JavaScript bundle inside the <head> element instead. <script> elements in <body> are evaluated with each page change.

        For more information, see: https://turbo.hotwired.dev/handbook/building#working-with-script-elements

        ——
        Suppress this warning by adding a "data-turbo-suppress-warning" attribute to: %s
      `, s.outerHTML);
  }
})();
window.Turbo = Gs;
Qe();
let et;
async function ti() {
  return et || Pe(ri().then(Pe));
}
function Pe(s) {
  return et = s;
}
async function ri() {
  const { createConsumer: s } = await import(
    /* webpackChunkName: "actioncable" */
    "./index-BhCL4V9r.js"
  );
  return s();
}
async function si(s, e) {
  const { subscriptions: t } = await ti();
  return t.create(s, e);
}
function ce(s) {
  return !s || typeof s != "object" || s instanceof Date || s instanceof RegExp ? s : Array.isArray(s) ? s.map(ce) : Object.keys(s).reduce(function(e, t) {
    var r = t[0].toLowerCase() + t.slice(1).replace(/([A-Z]+)/g, function(i, n) {
      return "_" + n.toLowerCase();
    });
    return e[r] = ce(s[t]), e;
  }, {});
}
class ii extends HTMLElement {
  async connectedCallback() {
    Ye(this), this.subscription = await si(this.channel, { received: this.dispatchMessageEvent.bind(this) });
  }
  disconnectedCallback() {
    Ze(this), this.subscription && this.subscription.unsubscribe();
  }
  dispatchMessageEvent(e) {
    const t = new MessageEvent("message", { data: e });
    return this.dispatchEvent(t);
  }
  get channel() {
    const e = this.getAttribute("channel"), t = this.getAttribute("signed-stream-name");
    return { channel: e, signed_stream_name: t, ...ce({ ...this.dataset }) };
  }
}
customElements.define("turbo-cable-stream-source", ii);
function ni({ detail: { formSubmission: { fetchRequest: s, submitter: e } } }) {
  e && e.formMethod && s.body.has("_method") && s.body.set("_method", e.formMethod);
}
addEventListener("turbo:submit-start", ni);
class le extends A {
  connect() {
    setTimeout(() => {
      this.close();
    }, this.timeoutValue);
  }
  close() {
    this.alertTarget.remove();
  }
}
b(le, "targets", ["alert"]), b(le, "values", {
  timeout: { type: Number, default: 1e3 }
});
class tt extends y {
  closeModal() {
    this.wrapTarget.remove();
  }
}
b(tt, "targets", ["wrap"]);
class rt extends y {
  changeType() {
    this.inputTarget.type === "password" ? this.showPassword() : this.hidePassword();
  }
  showPassword() {
    this.inputTarget.type = "text", this.showTarget.style.display = "block", this.hideTarget.style.display = "none";
  }
  hidePassword() {
    this.inputTarget.type = "password", this.showTarget.style.display = "none", this.hideTarget.style.display = "block";
  }
}
b(rt, "targets", ["input", "show", "hide"]);
class st extends y {
  connect() {
    this.changeInput();
  }
  changeInput() {
    this.selectTarget.value === "Fixed" ? (this.fixedInputTarget.style.display = "block", this.percentageInputTarget.style.display = "none", this.percentageInputTarget.querySelector("input").value = "") : (this.fixedInputTarget.style.display = "none", this.percentageInputTarget.style.display = "block", this.fixedInputTarget.querySelector("input").value = "");
  }
}
b(st, "targets", ["select", "fixedInput", "percentageInput"]);
class it extends y {
  connect() {
    this.changeInput();
  }
  changeInput() {
    this.selectTarget.value === "repeating" ? (this.inputTarget.classList.add("w-7/12"), this.inputTarget.classList.remove("hidden"), this.selectTarget.parentElement.parentElement.classList.remove("w-full"), this.selectTarget.parentElement.parentElement.classList.add("w-1/2")) : (this.inputTarget.classList.remove("w-7/12"), this.inputTarget.classList.add("hidden"), this.selectTarget.parentElement.parentElement.classList.add("w-full"), this.selectTarget.parentElement.parentElement.classList.remove("w-1/2"), this.inputTarget.querySelector("input").value = "");
  }
}
b(it, "targets", ["select", "input"]);
class nt extends y {
  connect() {
    document.addEventListener("click", (e) => {
      this.inputTarget.contains(e.target) || this.closeSelect();
    });
  }
  changeValue() {
    const { value: e } = this.inputTarget;
    this.data.set("value", e), this.filterItems();
  }
  openSelect() {
    this.itemsTarget.classList.remove("hidden"), this.data.set("value", ""), this.filterItems();
  }
  closeSelect() {
    this.itemsTarget.classList.add("hidden"), this.inputTarget.value = "", this.data.set("value", "");
  }
  filterItems() {
    const e = this.data.get("value").toLowerCase().trim();
    this.itemTargets.forEach((t) => {
      const r = t.dataset.title.toLowerCase();
      t.classList.toggle("hidden", r.indexOf(e) === -1);
    });
  }
  selectItem(e) {
    const t = e.target;
    this.selectItemProcess(t), this.filterItems(), this.closeSelect();
  }
  selectItemProcess(e) {
    const t = this.templateTarget.cloneNode(!0), r = ["#006aff", "#25de5d", "#ff2322", "#8e9eb3", "#7ED321", "#9a71ff", "#00ccff", "#ff3a68", "#72D7A0", "#FFC63C"];
    t.setAttribute("data-bulk-imports-assign-target", "selectedItem"), t.classList.remove("hidden"), t.querySelector("p").innerHTML = e.dataset.title, t.firstElementChild.style.backgroundColor = r[Math.floor(Math.random() * r.length)], e.dataset.image_url && (t.firstElementChild.style.backgroundImage = `url(${e.dataset.image_url})`), t.setAttribute("data-id", e.dataset.id), t.setAttribute("data-title", e.dataset.title), t.setAttribute("data-image_url", e.dataset.image_url), t.querySelector("input").value = e.dataset.id, e.remove(), this.selectedItemsTarget.appendChild(t);
  }
  deselectItem(e) {
    const t = e.target.parentElement.parentElement;
    this.addToItems(t);
  }
  deselectAllItems() {
    this.selectedItemTargets.forEach((e) => {
      this.addToItems(e);
    });
  }
  addToItems(e) {
    const t = this.createTarget("item", {
      data: {
        id: e.dataset.id,
        title: e.dataset.title,
        image_url: e.dataset.image_url,
        action: "click->bulk-imports-assign#selectItem"
      },
      htmlOptions: { class: "py-2 cursor-pointer text-sm hover:bg-gray-100" },
      innerHTML: e.dataset.title
    });
    this.itemsTarget.append(t), e.remove();
  }
}
b(nt, "targets", ["input", "template", "item", "items", "selectedItem", "selectedItems"]);
var oi = function() {
  var s = document.getSelection();
  if (!s.rangeCount)
    return function() {
    };
  for (var e = document.activeElement, t = [], r = 0; r < s.rangeCount; r++)
    t.push(s.getRangeAt(r));
  switch (e.tagName.toUpperCase()) {
    case "INPUT":
    case "TEXTAREA":
      e.blur();
      break;
    default:
      e = null;
      break;
  }
  return s.removeAllRanges(), function() {
    s.type === "Caret" && s.removeAllRanges(), s.rangeCount || t.forEach(function(i) {
      s.addRange(i);
    }), e && e.focus();
  };
};
const ai = /* @__PURE__ */ ue(oi), ci = /mac os x/i.test(navigator.userAgent), li = `Copy to clipboard: ${ci ? "⌘" : "Ctrl"} +C, Enter`;
function hi(s) {
  const e = ai(), r = document.querySelector('ds-modal[aria-expanded="true"]') || document.body;
  let i, n, o;
  try {
    if (o = document.createRange(), i = document.getSelection(), n = document.createElement("span"), n.textContent = s, n.ariaHidden = "true", n.setAttribute("style", "position: fixed; transform: translate(-10000px, -10000px);"), r.appendChild(n), o.selectNodeContents(n), i.addRange(o), !document.execCommand("copy"))
      throw new Error("copy command was unsuccessful");
  } catch {
    try {
      window.clipboardData.setData("text", s);
    } catch {
      window.prompt(li, s);
    }
  } finally {
    i && (typeof i.removeRange == "function" ? i.removeRange(o) : i.removeAllRanges()), n && r.removeChild(n), e();
  }
}
class ot extends y {
  copy(e) {
    const { value: t } = e.target.parentElement.previousElementSibling;
    hi(t), this.buttonTarget.innerHTML = "Done", setTimeout(() => {
      this.buttonTarget.innerHTML = "Copy";
    }, 1e3);
  }
}
b(ot, "targets", ["button"]);
const ui = (s, e) => {
  Object.keys(e).forEach((t) => {
    s.searchParams.append(t, e[t]);
  });
};
class at extends y {
  onInput(e) {
    const { value: t } = e.target, r = this.taggedUrlTarget, i = /^https?:\/\//i.test(t) ? t : `https://${t}`;
    if (this.resetCopyField(), !$e(i)) {
      r.value = "";
      return;
    }
    try {
      const n = new URL(i), o = JSON.parse(r.dataset.utmParams);
      ui(n, o), r.value = n.toString();
    } catch {
      r.value = "";
    }
  }
  resetCopyField() {
    const e = this.taggedUrlTarget.parentElement.querySelector(".copy-link");
    e && (e.classList.remove("text-green-600"), e.classList.add("text-blue-600"), e.innerHTML = "Copy");
  }
}
b(at, "targets", ["taggedUrl"]);
class ct extends y {
  addFilename() {
    this.fileTarget.classList.remove("hidden"), this.fileNameTarget.textContent = this.inputTarget.files[0].name;
  }
}
b(ct, "targets", ["input", "file", "fileName"]);
class lt extends y {
  connect() {
    document.addEventListener("input", () => {
      this.showButtonPanel();
    });
  }
  showButtonPanel() {
    this.panelTarget.classList.remove("hidden");
  }
}
b(lt, "targets", ["panel"]);
class ht extends y {
  connect() {
    this.element.addEventListener("turbo:submit-start", () => {
      this.submitButtonTarget.querySelector("svg").classList.remove("hidden");
    }), this.element.addEventListener("turbo:submit-end", () => {
      this.submitButtonTarget.querySelector("svg").classList.add("hidden");
    });
  }
}
b(ht, "targets", ["submitButton", "cancelButton"]);
class di {
  constructor(e) {
    this.response = e;
  }
  get statusCode() {
    return this.response.status;
  }
  get redirected() {
    return this.response.redirected;
  }
  get ok() {
    return this.response.ok;
  }
  get unauthenticated() {
    return this.statusCode === 401;
  }
  get unprocessableEntity() {
    return this.statusCode === 422;
  }
  get authenticationURL() {
    return this.response.headers.get("WWW-Authenticate");
  }
  get contentType() {
    return (this.response.headers.get("Content-Type") || "").replace(/;.*$/, "");
  }
  get headers() {
    return this.response.headers;
  }
  get html() {
    return this.contentType.match(/^(application|text)\/(html|xhtml\+xml)$/) ? this.text : Promise.reject(new Error(`Expected an HTML response but got "${this.contentType}" instead`));
  }
  get json() {
    return this.contentType.match(/^application\/.*json$/) ? this.responseJson || (this.responseJson = this.response.json()) : Promise.reject(new Error(`Expected a JSON response but got "${this.contentType}" instead`));
  }
  get text() {
    return this.responseText || (this.responseText = this.response.text());
  }
  get isTurboStream() {
    return this.contentType.match(/^text\/vnd\.turbo-stream\.html/);
  }
  async renderTurboStream() {
    if (this.isTurboStream)
      window.Turbo ? await window.Turbo.renderStreamMessage(await this.text) : console.warn("You must set `window.Turbo = Turbo` to automatically process Turbo Stream events with request.js");
    else
      return Promise.reject(new Error(`Expected a Turbo Stream response but got "${this.contentType}" instead`));
  }
}
class pi {
  static register(e) {
    this.interceptor = e;
  }
  static get() {
    return this.interceptor;
  }
  static reset() {
    this.interceptor = void 0;
  }
}
function mi(s) {
  const e = document.cookie ? document.cookie.split("; ") : [], t = `${encodeURIComponent(s)}=`, r = e.find((i) => i.startsWith(t));
  if (r) {
    const i = r.split("=").slice(1).join("=");
    if (i)
      return decodeURIComponent(i);
  }
}
function fi(s) {
  const e = {};
  for (const t in s) {
    const r = s[t];
    r !== void 0 && (e[t] = r);
  }
  return e;
}
function Fe(s) {
  const e = document.head.querySelector(`meta[name="${s}"]`);
  return e && e.content;
}
function gi(s) {
  return [...s].reduce((e, [t, r]) => e.concat(typeof r == "string" ? [[t, r]] : []), []);
}
function vi(s, e) {
  for (const [t, r] of e)
    r instanceof window.File || (s.has(t) ? (s.delete(t), s.set(t, r)) : s.append(t, r));
}
class bi {
  constructor(e, t, r = {}) {
    this.method = e, this.options = r, this.originalUrl = t.toString();
  }
  async perform() {
    try {
      const t = pi.get();
      t && await t(this);
    } catch (t) {
      console.error(t);
    }
    const e = new di(await window.fetch(this.url, this.fetchOptions));
    return e.unauthenticated && e.authenticationURL ? Promise.reject(window.location.href = e.authenticationURL) : (e.ok && e.isTurboStream && await e.renderTurboStream(), e);
  }
  addHeader(e, t) {
    const r = this.additionalHeaders;
    r[e] = t, this.options.headers = r;
  }
  get fetchOptions() {
    return {
      method: this.method.toUpperCase(),
      headers: this.headers,
      body: this.formattedBody,
      signal: this.signal,
      credentials: "same-origin",
      redirect: this.redirect
    };
  }
  get headers() {
    return fi(
      Object.assign(
        {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": this.csrfToken,
          "Content-Type": this.contentType,
          Accept: this.accept
        },
        this.additionalHeaders
      )
    );
  }
  get csrfToken() {
    return mi(Fe("csrf-param")) || Fe("csrf-token");
  }
  get contentType() {
    return this.options.contentType ? this.options.contentType : this.body == null || this.body instanceof window.FormData ? void 0 : this.body instanceof window.File ? this.body.type : "application/json";
  }
  get accept() {
    switch (this.responseKind) {
      case "html":
        return "text/html, application/xhtml+xml";
      case "turbo-stream":
        return "text/vnd.turbo-stream.html, text/html, application/xhtml+xml";
      case "json":
        return "application/json, application/vnd.api+json";
      default:
        return "*/*";
    }
  }
  get body() {
    return this.options.body;
  }
  get query() {
    const e = (this.originalUrl.split("?")[1] || "").split("#")[0], t = new URLSearchParams(e);
    let r = this.options.query;
    r instanceof window.FormData ? r = gi(r) : r instanceof window.URLSearchParams ? r = r.entries() : r = Object.entries(r || {}), vi(t, r);
    const i = t.toString();
    return i.length > 0 ? `?${i}` : "";
  }
  get url() {
    return this.originalUrl.split("?")[0].split("#")[0] + this.query;
  }
  get responseKind() {
    return this.options.responseKind || "html";
  }
  get signal() {
    return this.options.signal;
  }
  get redirect() {
    return this.options.redirect || "follow";
  }
  get additionalHeaders() {
    return this.options.headers || {};
  }
  get formattedBody() {
    const e = Object.prototype.toString.call(this.body) === "[object String]";
    return this.headers["Content-Type"] === "application/json" && !e ? JSON.stringify(this.body) : this.body;
  }
}
async function ut(s, e) {
  return new bi("get", s, e).perform();
}
class he extends y {
  change() {
    const e = new URLSearchParams();
    e.append(this.paramValue, this.selectTarget.value), ut(`${this.urlValue}?${e}`, {
      responseKind: "turbo-stream"
    });
  }
}
b(he, "targets", ["select"]), b(he, "values", {
  url: String,
  param: String
});
class dt extends y {
  connect() {
    this.form = this.element.closest("form"), setTimeout(() => {
      this.previousDates = this.getDates(this.inputTarget);
    }, 10);
  }
  filter() {
    this.getDates(this.inputTarget) !== this.previousDates && this.form.submit();
  }
  getDates(e) {
    return e.start + "-" + e.end;
  }
}
b(dt, "targets", ["input"]);
class pt extends A {
  connect() {
    this.checkedSelectAll = !0, this.updateSelectedApps();
  }
  updateSelectedApps() {
    const e = this.appCheckboxTargets.filter((i) => i.checked), t = e.length;
    this.setSelectedAppsIds(e), this.selectedAppsCountTarget.innerHTML = `Count = ${t}`, this.submitButtonTarget.disabled = t === 0;
    const r = t === 1 ? "store" : "stores";
    this.submitButtonTarget.dataset.confirm = `Are you sure you want to update ${t} ${r}?`;
  }
  setSelectedAppsIds(e) {
    const t = e.map((r) => r.value);
    this.sortButtonTargets.forEach((r) => {
      const i = new URL(r.href);
      i.searchParams.set("app_ids", t), r.href = i.toString();
    });
  }
  toggleSelectAll(e) {
    e.preventDefault(), this.checkedSelectAll = !this.checkedSelectAll, e.target.innerHTML = this.checkedSelectAll ? "Unselect All" : "Select All", this.appCheckboxTargets.forEach((t) => {
      t.checked = this.checkedSelectAll;
    }), this.updateSelectedApps();
  }
}
b(pt, "targets", ["selectedAppsCount", "appCheckbox", "submitButton", "sortButton"]);
class mt extends A {
  connect() {
    this.checkedSelectAll = !0, this.updateSelectedStores();
  }
  updateSelectedStores() {
    const t = this.storeCheckboxTargets.filter((i) => i.checked).length;
    this.selectedStoresCountTarget.innerHTML = `Count = ${t}`, this.submitButtonTarget.disabled = t === 0;
    const r = t === 1 ? "store" : "stores";
    this.submitButtonTarget.dataset.confirm = `Are you sure want to request apple agreements for ${t} ${r}?`;
  }
  toggleSelectAll(e) {
    e.preventDefault(), this.checkedSelectAll = !this.checkedSelectAll, e.target.innerHTML = this.checkedSelectAll ? "Unselect All" : "Select All", this.storeCheckboxTargets.forEach((t) => {
      t.checked = this.checkedSelectAll;
    }), this.updateSelectedStores();
  }
}
b(mt, "targets", ["selectedStoresCount", "storeCheckbox", "submitButton"]);
class ft extends y {
  connect() {
    this.form = this.element.closest("form"), setTimeout(() => {
      this.previousValue = this.getValue(this.inputTarget);
    }, 10);
  }
  submit() {
    this.getValue(this.inputTarget) !== this.previousValue && this.form.submit();
  }
  getValue(e) {
    return e.value.sort().join(",");
  }
}
b(ft, "targets", ["input"]);
class yi extends y {
  submit() {
    const e = this.element.dataset.url, t = new URLSearchParams();
    t.append(this.element.name, this.element.value), ut(`${e}?${t}`, {
      responseKind: "turbo-stream"
    });
  }
}
class wi extends A {
  clear() {
    setTimeout(() => {
      this.element.closest("form").submit();
    }, 10);
  }
}
class Ei extends A {
  search(e) {
    const t = e.currentTarget;
    setTimeout(() => {
      t.closest("form").submit();
    }, 100);
  }
}
class gt extends A {
  setBranchParam() {
    const e = this.branchInputTarget.value;
    this.urlTargets.forEach((t) => {
      const r = new URL(t.href);
      e ? r.searchParams.set("branch", e) : r.searchParams.delete("branch"), t.href = r.toString();
    });
  }
  setBranchInput(e) {
    this.branchInputTarget.value = e.target.textContent, this.setBranchParam();
  }
}
b(gt, "targets", ["url", "branchInput"]);
class vt extends A {
  connect() {
    this.checkboxTarget.checked || this.toggleAppBody();
  }
  toggleAppBody() {
    console.log("this.hasAppBodyTarget", this.hasAppBodyTarget), this.hasAppBodyTarget && this.appBodyTarget.classList.toggle("hidden");
  }
}
b(vt, "targets", ["appBody", "checkbox"]);
var q, U;
class bt extends A {
  constructor() {
    super(...arguments);
    we(this, q);
  }
  connect() {
    this.checkOwnerRelatedRoles(), this.checkPublisherRelatedRoles(), this.checkContentManagerRelatedRoles();
  }
  checkOwnerRelatedRoles() {
    $(this, q, U).call(this, "user[roles][owner]").checked && this.roleCheckboxTargets.forEach((r) => {
      r.checked = !0, r.disabled = !0;
    });
  }
  checkPublisherRelatedRoles() {
    const t = $(this, q, U).call(this, "user[roles][publisher]");
    this.roleCheckboxTargets.forEach((r) => {
      [t.name, "user[roles][owner]"].includes(r.name) || (r.checked = t.checked || r.dataset.initialCheck === "true", r.disabled = t.checked);
    });
  }
  checkContentManagerRelatedRoles() {
    const t = $(this, q, U).call(this, "user[roles][content_manager]"), r = $(this, q, U).call(this, "user[roles][video_upload_manager]");
    r.checked = t.checked || r.dataset.initialCheck === "true", r.disabled = t.checked;
  }
}
q = new WeakSet(), U = function(t) {
  return this.roleCheckboxTargets.find((r) => r.name === t);
}, b(bt, "targets", ["roleCheckbox"]);
try {
  kt.start();
} catch (s) {
  console.log(s.message);
}
Mt.start();
class Si extends y {
  /* eslint-disable class-methods-use-this */
  export(e) {
    const t = e.target, r = t.options[t.selectedIndex].innerHTML;
    $e(t.value) && jr(t.value, r);
  }
}
class yt extends y {
  update_utm_media_select() {
    const e = this.utmCategoryTarget, r = JSON.parse(e.dataset.utmMediumValues)[e.value], i = document.getElementById("utm_tag_utm_medium");
    i.innerHTML = "", r.forEach((n) => {
      const o = document.createElement("option");
      o.text = n, o.value = n, i.add(o);
    });
  }
}
b(yt, "targets", ["utmCategory"]);
class wt extends y {
  initialize() {
    this.updatePreview = this.updatePreview.bind(this);
  }
  connect() {
    this.inputTarget.addEventListener("change", this.updatePreview);
  }
  updatePreview() {
    const e = new FileReader();
    this.fileRemoveTarget.value = "false", e.onload = (t) => {
      this.previewTargets.forEach((r) => {
        r.tagName !== "IMG" && (r.style.backgroundImage = `url(${t.target.result})`);
      });
    }, e.readAsDataURL(this.inputTarget.files[0]);
  }
  removePreview() {
    this.fileRemoveTarget.value = "true", this.inputTarget.value = "", this.previewTargets.forEach((e) => {
      e.style.backgroundImage = "none", e.tagName === "IMG" && (e.src = "", e.style.display = "none");
    });
  }
}
b(wt, "targets", ["input", "preview", "fileRemove"]);
class Et extends y {
  initialize() {
  }
  connect() {
    this.individualAccountSwitcherTarget.checked ? this.toggleDisplay(this.teamAccountContainerTarget) : this.toggleDisplay(this.individualAccountContainerTarget);
  }
  insertAppId(e) {
    e.preventDefault(), this.appInputTarget.value = e.target.dataset.id, e.target.dataset.iaps && (this.iapInputTarget.value = e.target.dataset.iaps), e.target.dataset.name && (this.nameInputTarget.value = e.target.dataset.name);
  }
  insertIapId(e) {
    e.preventDefault(), this.iapInputTarget.value = e.target.dataset.id;
  }
  // eslint-disable-next-line class-methods-use-this
  toggleDisplay(e) {
    e.style.display === "none" ? e.style.display = "block" : e.style.display = "none";
  }
  toggleAccountTypes() {
    this.toggleDisplay(this.teamAccountContainerTarget), this.toggleDisplay(this.individualAccountContainerTarget);
  }
}
b(Et, "targets", [
  "appList",
  "appInput",
  "iapList",
  "iapInput",
  "nameInput",
  "individualAccountSwitcher",
  "teamAccountContainer",
  "individualAccountContainer"
]);
window.Turbo.session.drive = !1;
const v = Ar.start();
v.register("export-csv", Si);
v.register("modal", tt);
v.register("password-input", rt);
v.register("bulk-imports-discount-amount", st);
v.register("bulk-imports-coupon-duration", it);
v.register("bulk-imports-assign", nt);
v.register("utm-link", at);
v.register("upload-button", ct);
v.register("button-panel", lt);
v.register("utm-tags", yt);
v.register("clipboard", ot);
v.register("store-form", ht);
v.register("image-preview", wt);
v.register("app-form", Et);
v.register("select", he);
v.register("alert", le);
v.register("bulk-update-form", pt);
v.register("bulk-apple-agreements-request-form", mt);
v.register("datepicker", dt);
v.register("multiselect", ft);
v.register("radio-button", yi);
v.register("users-search", wi);
v.register("newsfeed", Ei);
v.register("ott-builder-danger-zone", gt);
v.register("toggle-app-body", vt);
v.register("user-roles", bt);
